import { Pipe, PipeTransform } from '@angular/core';

import { LocaleService } from '../services/locale.service';

@Pipe({
  name: 'roundAndFormatNumber'
})
export class RoundAndFormatNumberPipe implements PipeTransform {
  constructor(private localeService: LocaleService) {}

  transform(value: number, accuracy = 2, showFullFraction = true): string {
    return this.localeService.formatAndRoundNumber(value, accuracy, showFullFraction);
  }
}
