<mat-menu class="read-items-menu-container" [class]="panelClass">
  <div *ngIf="!(items | isDefined)" class="row justify-center align-center no-items">
    No items yet
  </div>

  <button
    mat-menu-item
    *ngFor="let item of items; trackBy: trackById"
    class="item {{ item.customClass ?? '' }}"
    [class.unread]="!item.read"
    [class.desktop]="isDesktop"
    (click)="$event.stopPropagation(); onClick(item)"
    [disabled]="item.disableClick"
  >
    <div class="column menu-line">
      <div class="row title">
        <mat-icon *ngIf="item.iconName" [svgIcon]="item.iconName"></mat-icon>
        <span matLine class="title-text" #titleText [title]="titleText && titleText.clientHeight !== titleText.scrollHeight ? item.text : ''">
          {{ item.text }}
        </span>
      </div>
      <span matLine class="subtitle">{{ formatShortDate(item.date) }}</span>
    </div>
    <div
      class="read-button"
      [ngClass]="item.read ? 'read' : 'unread'"
      [title]="'Mark as ' + (item.read ? 'unread' : 'read')"
      (click)="$event.stopPropagation(); clickRead(item)"
    ></div>
  </button>
</mat-menu>
