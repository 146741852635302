import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { GroupsLayersManagerComponent, GroupsLayersManagerData } from './groups-layers-manager.component';

@Injectable({
  providedIn: 'root'
})
export class GroupsLayersManagerService {
  constructor(private dialog: MatDialog) {}

  openDialog(data: GroupsLayersManagerData) {
    this.dialog.open<GroupsLayersManagerComponent, GroupsLayersManagerData>(GroupsLayersManagerComponent, {
      autoFocus: false,
      restoreFocus: false,
      width: '700px',
      height: '600px',
      data
    });
  }
}
