// index.ts
import { featureCollection, polygon } from "@turf/helpers";
function tin(points, z) {
  let isPointZ = false;
  return featureCollection(triangulate(points.features.map(p => {
    const point = {
      x: p.geometry.coordinates[0],
      y: p.geometry.coordinates[1]
    };
    if (z) {
      point.z = p.properties[z];
    } else if (p.geometry.coordinates.length === 3) {
      isPointZ = true;
      point.z = p.geometry.coordinates[2];
    }
    return point;
  })).map(triangle => {
    const a = [triangle.a.x, triangle.a.y];
    const b = [triangle.b.x, triangle.b.y];
    const c = [triangle.c.x, triangle.c.y];
    let properties = {};
    if (isPointZ) {
      a.push(triangle.a.z);
      b.push(triangle.b.z);
      c.push(triangle.c.z);
    } else {
      properties = {
        a: triangle.a.z,
        b: triangle.b.z,
        c: triangle.c.z
      };
    }
    return polygon([[a, b, c, a]], properties);
  }));
}
var Triangle = class {
  constructor(a, b, c) {
    this.a = a;
    this.b = b;
    this.c = c;
    const A = b.x - a.x;
    const B = b.y - a.y;
    const C = c.x - a.x;
    const D = c.y - a.y;
    const E = A * (a.x + b.x) + B * (a.y + b.y);
    const F = C * (a.x + c.x) + D * (a.y + c.y);
    const G = 2 * (A * (c.y - b.y) - B * (c.x - b.x));
    let dx;
    let dy;
    this.x = (D * E - B * F) / G;
    this.y = (A * F - C * E) / G;
    dx = this.x - a.x;
    dy = this.y - a.y;
    this.r = dx * dx + dy * dy;
  }
};
function byX(a, b) {
  return b.x - a.x;
}
function dedup(edges) {
  let j = edges.length;
  let a;
  let b;
  let i;
  let m;
  let n;
  outer: while (j) {
    b = edges[--j];
    a = edges[--j];
    i = j;
    while (i) {
      n = edges[--i];
      m = edges[--i];
      if (a === m && b === n || a === n && b === m) {
        edges.splice(j, 2);
        edges.splice(i, 2);
        j -= 2;
        continue outer;
      }
    }
  }
}
function triangulate(vertices) {
  if (vertices.length < 3) {
    return [];
  }
  vertices.sort(byX);
  let i = vertices.length - 1;
  const xmin = vertices[i].x;
  const xmax = vertices[0].x;
  let ymin = vertices[i].y;
  let ymax = ymin;
  const epsilon = 1e-12;
  let a;
  let b;
  let c;
  let A;
  let B;
  let G;
  while (i--) {
    if (vertices[i].y < ymin) {
      ymin = vertices[i].y;
    }
    if (vertices[i].y > ymax) {
      ymax = vertices[i].y;
    }
  }
  let dx = xmax - xmin;
  let dy = ymax - ymin;
  const dmax = dx > dy ? dx : dy;
  const xmid = (xmax + xmin) * 0.5;
  const ymid = (ymax + ymin) * 0.5;
  const open = [new Triangle({
    __sentinel: true,
    x: xmid - 20 * dmax,
    y: ymid - dmax
  }, {
    __sentinel: true,
    x: xmid,
    y: ymid + 20 * dmax
  }, {
    __sentinel: true,
    x: xmid + 20 * dmax,
    y: ymid - dmax
  })];
  const closed = [];
  const edges = [];
  let j;
  i = vertices.length;
  while (i--) {
    edges.length = 0;
    j = open.length;
    while (j--) {
      dx = vertices[i].x - open[j].x;
      if (dx > 0 && dx * dx > open[j].r) {
        closed.push(open[j]);
        open.splice(j, 1);
        continue;
      }
      dy = vertices[i].y - open[j].y;
      if (dx * dx + dy * dy > open[j].r) {
        continue;
      }
      edges.push(open[j].a, open[j].b, open[j].b, open[j].c, open[j].c, open[j].a);
      open.splice(j, 1);
    }
    dedup(edges);
    j = edges.length;
    while (j) {
      b = edges[--j];
      a = edges[--j];
      c = vertices[i];
      A = b.x - a.x;
      B = b.y - a.y;
      G = 2 * (A * (c.y - b.y) - B * (c.x - b.x));
      if (Math.abs(G) > epsilon) {
        open.push(new Triangle(a, b, c));
      }
    }
  }
  Array.prototype.push.apply(closed, open);
  i = closed.length;
  while (i--) {
    if (closed[i].a.__sentinel || closed[i].b.__sentinel || closed[i].c.__sentinel) {
      closed.splice(i, 1);
    }
  }
  return closed;
}
var turf_tin_default = tin;
export { turf_tin_default as default, tin };
