<div
  class="box-container"
  [ngClass]="{
    'arrow-right': direction === 'right',
    'arrow-top': direction === 'top',
    'arrow-top-left': direction === 'top-left',
    'dark-mode': colorMode === 'dark',
    'light-mode': colorMode === 'light'
  }">
  <div class="container column" [ngClass]="{ 'dark-mode': colorMode === 'dark', 'light-mode': colorMode === 'light' }">
    <ng-content></ng-content>
  </div>
</div>
