// index.ts
import { segmentEach } from "@turf/meta";
import { getGeom } from "@turf/invariant";
import { lineOverlap } from "@turf/line-overlap";
import { lineIntersect } from "@turf/line-intersect";
import { geojsonEquality } from "geojson-equality-ts";
function booleanOverlap(feature1, feature2) {
  const geom1 = getGeom(feature1);
  const geom2 = getGeom(feature2);
  const type1 = geom1.type;
  const type2 = geom2.type;
  if (type1 === "MultiPoint" && type2 !== "MultiPoint" || (type1 === "LineString" || type1 === "MultiLineString") && type2 !== "LineString" && type2 !== "MultiLineString" || (type1 === "Polygon" || type1 === "MultiPolygon") && type2 !== "Polygon" && type2 !== "MultiPolygon") {
    throw new Error("features must be of the same type");
  }
  if (type1 === "Point") throw new Error("Point geometry not supported");
  if (geojsonEquality(feature1, feature2, {
    precision: 6
  })) return false;
  let overlap = 0;
  switch (type1) {
    case "MultiPoint":
      for (var i = 0; i < geom1.coordinates.length; i++) {
        for (var j = 0; j < geom2.coordinates.length; j++) {
          var coord1 = geom1.coordinates[i];
          var coord2 = geom2.coordinates[j];
          if (coord1[0] === coord2[0] && coord1[1] === coord2[1]) {
            return true;
          }
        }
      }
      return false;
    case "LineString":
    case "MultiLineString":
      segmentEach(feature1, segment1 => {
        segmentEach(feature2, segment2 => {
          if (lineOverlap(segment1, segment2).features.length) overlap++;
        });
      });
      break;
    case "Polygon":
    case "MultiPolygon":
      segmentEach(feature1, segment1 => {
        segmentEach(feature2, segment2 => {
          if (lineIntersect(segment1, segment2).features.length) overlap++;
        });
      });
      break;
  }
  return overlap > 0;
}
var turf_boolean_overlap_default = booleanOverlap;
export { booleanOverlap, turf_boolean_overlap_default as default };
