<dialog-layout (closeDialog)="close(false)" [header]="data.title">
  <div class="text-container" *ngIf="data.topText">
    {{ data.topText }}
  </div>

  <div class="column issues-container">
    <issue-banner
      *ngFor="let issue of issues"
      [type]="issue.type === IssueType.ERROR ? 'error' : 'warning'"
      [caption]="issue.description"
    ></issue-banner>
  </div>

  <div class="text-container" *ngIf="data.bottomText">
    {{ data.bottomText }}
  </div>

  <div class="row bottom-row">
    <button mat-button class="light-btn log-button" (click)="downloadLog()">
      <mat-icon svgIcon="export-csv"></mat-icon>
      <ng-container i18n="@@shared.issuesDialog.downloadLog">Download log</ng-container>
    </button>

    <button mat-button class="light-btn" *ngIf="data?.noCaption" (click)="close(false)">{{ data.noCaption }}</button>
    <button mat-flat-button class="dark-btn" (click)="close(true)">
      <ng-container *ngIf="data?.yesCaption; else okCaption">{{ data?.yesCaption }}</ng-container>
      <ng-template #okCaption i18n="@@general.actionButtons.OK">OK</ng-template>
    </button>
  </div>
</dialog-layout>