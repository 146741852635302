// index.js
import { center } from "@turf/center";
import jsts from "@turf/jsts";
import { geomEach, featureEach } from "@turf/meta";
import { geoAzimuthalEquidistant } from "d3-geo";
import { feature, featureCollection, radiansToLength, lengthToRadians, earthRadius } from "@turf/helpers";
var {
  BufferOp,
  GeoJSONReader,
  GeoJSONWriter
} = jsts;
function buffer(geojson, radius, options) {
  options = options || {};
  var units = options.units || "kilometers";
  var steps = options.steps || 8;
  if (!geojson) throw new Error("geojson is required");
  if (typeof options !== "object") throw new Error("options must be an object");
  if (typeof steps !== "number") throw new Error("steps must be an number");
  if (radius === void 0) throw new Error("radius is required");
  if (steps <= 0) throw new Error("steps must be greater than 0");
  var results = [];
  switch (geojson.type) {
    case "GeometryCollection":
      geomEach(geojson, function (geometry) {
        var buffered = bufferFeature(geometry, radius, units, steps);
        if (buffered) results.push(buffered);
      });
      return featureCollection(results);
    case "FeatureCollection":
      featureEach(geojson, function (feature2) {
        var multiBuffered = bufferFeature(feature2, radius, units, steps);
        if (multiBuffered) {
          featureEach(multiBuffered, function (buffered) {
            if (buffered) results.push(buffered);
          });
        }
      });
      return featureCollection(results);
  }
  return bufferFeature(geojson, radius, units, steps);
}
function bufferFeature(geojson, radius, units, steps) {
  var properties = geojson.properties || {};
  var geometry = geojson.type === "Feature" ? geojson.geometry : geojson;
  if (geometry.type === "GeometryCollection") {
    var results = [];
    geomEach(geojson, function (geometry2) {
      var buffered2 = bufferFeature(geometry2, radius, units, steps);
      if (buffered2) results.push(buffered2);
    });
    return featureCollection(results);
  }
  var projection = defineProjection(geometry);
  var projected = {
    type: geometry.type,
    coordinates: projectCoords(geometry.coordinates, projection)
  };
  var reader = new GeoJSONReader();
  var geom = reader.read(projected);
  var distance = radiansToLength(lengthToRadians(radius, units), "meters");
  var buffered = BufferOp.bufferOp(geom, distance, steps);
  var writer = new GeoJSONWriter();
  buffered = writer.write(buffered);
  if (coordsIsNaN(buffered.coordinates)) return void 0;
  var result = {
    type: buffered.type,
    coordinates: unprojectCoords(buffered.coordinates, projection)
  };
  return feature(result, properties);
}
function coordsIsNaN(coords) {
  if (Array.isArray(coords[0])) return coordsIsNaN(coords[0]);
  return isNaN(coords[0]);
}
function projectCoords(coords, proj) {
  if (typeof coords[0] !== "object") return proj(coords);
  return coords.map(function (coord) {
    return projectCoords(coord, proj);
  });
}
function unprojectCoords(coords, proj) {
  if (typeof coords[0] !== "object") return proj.invert(coords);
  return coords.map(function (coord) {
    return unprojectCoords(coord, proj);
  });
}
function defineProjection(geojson) {
  var coords = center(geojson).geometry.coordinates;
  var rotation = [-coords[0], -coords[1]];
  return geoAzimuthalEquidistant().rotate(rotation).scale(earthRadius);
}
var turf_buffer_default = buffer;
export { buffer, turf_buffer_default as default };
