// index.ts
import { booleanWithin as within } from "@turf/boolean-within";
import { distance } from "@turf/distance";
import { point, featureCollection } from "@turf/helpers";
function pointGrid(bbox, cellSide, options = {}) {
  if (options.mask && !options.units) options.units = "kilometers";
  var results = [];
  var west = bbox[0];
  var south = bbox[1];
  var east = bbox[2];
  var north = bbox[3];
  var xFraction = cellSide / distance([west, south], [east, south], options);
  var cellWidth = xFraction * (east - west);
  var yFraction = cellSide / distance([west, south], [west, north], options);
  var cellHeight = yFraction * (north - south);
  var bboxWidth = east - west;
  var bboxHeight = north - south;
  var columns = Math.floor(bboxWidth / cellWidth);
  var rows = Math.floor(bboxHeight / cellHeight);
  var deltaX = (bboxWidth - columns * cellWidth) / 2;
  var deltaY = (bboxHeight - rows * cellHeight) / 2;
  var currentX = west + deltaX;
  while (currentX <= east) {
    var currentY = south + deltaY;
    while (currentY <= north) {
      var cellPt = point([currentX, currentY], options.properties);
      if (options.mask) {
        if (within(cellPt, options.mask)) results.push(cellPt);
      } else {
        results.push(cellPt);
      }
      currentY += cellHeight;
    }
    currentX += cellWidth;
  }
  return featureCollection(results);
}
var turf_point_grid_default = pointGrid;
export { turf_point_grid_default as default, pointGrid };
