import { Chart, ChartEvent, Scale } from 'chart.js';

import { CategoryScaleInternal, LabelClickEvent, LabelsXCloseClickOptions, ScaleLabels } from './measurment-chart.model';

const findLabel = (scaleLabelsFound: ScaleLabels[], event: ChartEvent): LabelClickEvent => {
  for (let scaleLabelFoundIdx = 0; scaleLabelFoundIdx < scaleLabelsFound.length; scaleLabelFoundIdx++) {
    const scaleLabelFound = scaleLabelsFound[scaleLabelFoundIdx];
    for (let labelIndex = 0; labelIndex < scaleLabelFound.labels.length; labelIndex++) {
      const label = scaleLabelFound.labels[labelIndex];
      if (event.x > label.x && event.x < label.x2 && event.y > label.y && event.y < label.y2) {
        return {
          label: label.label,
          labelIndex
        };
      }
    }
  }
  return null;
};

const getLabelHitboxes = (scales: { x: Scale }): ScaleLabels[] => {
  return Object.values(scales).map((s: CategoryScaleInternal) => ({
    scaleId: s.id,
    labels: s._labelItems?.map((e, i) => ({
      x: e.translation[0] + s._labelSizes.widths[i] / 2 - 16,
      x2: e.translation[0] + s._labelSizes.widths[i] / 2 + 16,
      y: e.translation[1] - s._labelSizes.heights[i],
      y2: e.translation[1] + s._labelSizes.heights[i],
      label: e.label,
      index: i
    }))
  }));
};

export const labelsXClickPlugin = {
  id: 'labelsXClickPlugin',

  afterEvent: (chart: Chart, event: { event: ChartEvent }, opts: LabelsXCloseClickOptions) => {
    const chartEvent = event.event;
    if (chartEvent.type !== 'click') {
      return;
    }
    const labelHitboxes = getLabelHitboxes({ x: chart.scales.x });

    if (labelHitboxes[0]?.labels?.length > 1) {
      const labelInfo = findLabel(labelHitboxes, chartEvent);
      if (labelInfo) {
        opts.chartLabelClicked.emit(labelInfo);
      }
    }
  }
};
