import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'spinner-progress',
  templateUrl: './spinner-progress.component.html',
  styleUrls: ['./spinner-progress.component.scss']
})
export class SpinnerProgressComponent implements OnInit {
  @Input() progressValue: number;
  @Input() spinnerSize = 38;
  @Input() strokeWidth = 2;

  fontSize = 12;
  constructor() {}

  ngOnInit() {
    this.fontSize = Math.ceil((this.spinnerSize / 100) * 25);
    this.strokeWidth = Math.ceil((this.spinnerSize / 100) * 10);
  }
}
