import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { GetUserAnnouncementResponse } from '../../../../../generated/nms/model/getUserAnnouncementResponse';

export type Announcement = GetUserAnnouncementResponse;

export interface AnnouncementsState extends EntityState<Announcement> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'announcements' })
export class AnnouncementsStore extends EntityStore<AnnouncementsState> {
  constructor() {
    super({ firstLaunch: true });
  }
}
