import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'hint-tag',
  template: `
    <div class="hint-tag" [ngStyle]="{ '--width': width + 'px', '--height': height + 'px' }">
      {{ label }}
    </div>
  `,
  styleUrls: ['./hint-tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HintTagComponent {
  @Input() label!: string;
  @Input() width = 100;
  @Input() height = 40;
}
