/**
 * Notification Management Service
 * API's for mile stone I
 *
 * OpenAPI spec version: 1.4.9
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface GetAnnouncementResponse {
  buttonLink?: string;
  buttonText?: string;
  creationTime?: Date;
  description?: string;
  id?: string;
  mediaLink?: string;
  mediaType?: GetAnnouncementResponse.MediaTypeEnum;
  subtitle?: string;
  title?: string;
  videoPlaceholderLink?: string;
}
export namespace GetAnnouncementResponse {
  export type MediaTypeEnum = 'VIDEO' | 'IMAGE';
  export const MediaTypeEnum = {
    VIDEO: 'VIDEO' as MediaTypeEnum,
    IMAGE: 'IMAGE' as MediaTypeEnum
  };
}
