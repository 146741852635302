<dialog-layout (closeDialog)="close()" header="processing history" i18n-header="@@shared.processingHistory.dialogHeader">
  <div class="row space-between subheader">
    <div class="row full-container filter">
      <label for="site" i18n="@@shared.processingHistory.siteFilterFieldLabel">Site</label>
      <ng-select
        class="custom field-select-control"
        [items]="sites"
        [selectOnTab]="true"
        [disabled]="loading"
        [(ngModel)]="tableFilters.siteId"
        i18n-placeholder="@@shared.processingHistory.siteSelectInputPlaceholder"
        placeholder="All"
        bindValue="id"
        bindLabel="name"
        labelForId="site"
        [appendTo]="isDesktop ? 'body' : ''"
        (change)="onChangeSite($event?.id)"
      >
        <ng-template ng-option-tmp let-item="item">
          <div [title]="item.name">{{ item.name }}</div>
        </ng-template>
      </ng-select>
    </div>

    <div class="row full-container filter">
      <label for="task" i18n="@@shared.processingHistory.taskFilterFieldLabel">Mapping instance</label>
      <ng-select
        class="custom field-select-control"
        [items]="siteTasks"
        [selectOnTab]="true"
        [disabled]="loading || !tableFilters.siteId"
        [title]="!tableFilters.siteId ? taskSelectTitleNoSite : ''"
        [(ngModel)]="tableFilters.taskId"
        i18n-placeholder="@@shared.processingHistory.taskSelectInputPlaceholder"
        placeholder="All"
        bindValue="id"
        bindLabel="label"
        labelForId="task"
        [appendTo]="isDesktop ? 'body' : ''"
        (change)="onChangeTask($event?.id)"
      >
        <ng-template ng-option-tmp let-item="item">
          <div [title]="item.label">{{ item.label }}</div>
        </ng-template>
      </ng-select>
    </div>

    <div class="row full-container filter">
      <label for="event" i18n="@@shared.processingHistory.eventFilterFieldLabel">Event</label>
      <ng-select
        class="custom field-select-control"
        [items]="events"
        [selectOnTab]="true"
        [disabled]="loading"
        [(ngModel)]="tableFilters.eventType"
        i18n-placeholder="@@shared.processingHistory.eventSelectInputPlaceholder"
        placeholder="All"
        bindValue="id"
        bindLabel="label"
        labelForId="event"
        [appendTo]="isDesktop ? 'body' : ''"
        (change)="onChangeEvent($event?.id)"
      >
        <ng-template ng-option-tmp let-item="item">
          <div [title]="item.label">{{ item.label }}</div>
        </ng-template>
      </ng-select>
    </div>

    <button mat-icon-button class="csv-button" [title]="isTableFiltered ? csvBtnCaptions.filteredData: csvBtnCaptions.allData" (click)="onDownloadCSV()" [disabled]="loading || !(logsDataSource.data | isDefined)">
      <mat-icon [svgIcon]="isTableFiltered ? 'export-csv-filter' : 'export-csv'"></mat-icon>
    </button>
  </div>

  <mat-progress-bar [mode]="'query'" [value]="20" [class.transparent-element]="!loading"></mat-progress-bar>

  <div class="full-container table-container" [attr.items-per-page]="itemsPerPage">
    <mat-table [class.hidden]="!(logsDataSource.data | isDefined)" [dataSource]="logsDataSource" matSort [trackBy]="trackByFunc" (matSortChange)="onTableSortChange($event)">
      <ng-container matColumnDef="SiteName">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@shared.processingHistory.siteTableCellHeader">Site</mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.siteName}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="MissionFlightDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@shared.processingHistory.taskTableCellHeader">Mapping Instance</mat-header-cell>
        <mat-cell *matCellDef="let element">{{formatDate(element.missionFlightDate)}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="EventType">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="event-column" i18n="@@shared.processingHistory.eventTableCellHeader">Event</mat-header-cell>
        <mat-cell *matCellDef="let element" class="event-column">{{formatEvent(element.eventType)}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="EventDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@shared.processingHistory.dateTableCellHeader">Date</mat-header-cell>
        <mat-cell *matCellDef="let element">{{formatShortDate(element.eventDate)}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="ImagesCost">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          <span i18n="@@shared.processingHistory.imageCostTableCellHeader">Image consumption</span>
          <table-header-info-tooltip i18n-text="@@shared.processingHistory.imageCostTableCellInfoTooltip" text="
                Image consumption is calculated based on the images resolution (in megapixels) divided by a predetermined baseline resolution (in megapixels),
                multiplied by the number of flight images.

                In formula form:
                (Images Resolution / Baseline Resolution) &times; No. of Images = Image Cost.
                "></table-header-info-tooltip>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <div class="full-container" [title]="getImageCostFormulaString(element)" *ngIf="element.billed">
            {{isNaN(element.imagesCost) ? '' : element.imagesCost}}
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="tasksCols; sticky: true"></mat-header-row>
      <mat-row
        i18n-title="@@shared.processingHistory.tableRowTitle"
        *matRowDef="let taskRow; columns: tasksCols;"
        (click)="selectRow(taskRow)"
        title="Go to the {{taskRow.siteName}} site"
      ></mat-row>
    </mat-table>
    <div class="container-message row justify-center align-center" *ngIf="!(logsDataSource.data | isDefined)">
      <ng-container *ngIf="!loading; else loadingText">
        <span i18n="@@shared.processingHistory.noDataPlaceholder">No processing history</span>
      </ng-container>
      <ng-template #loadingText>
        <span i18n="@@general.loadingText">Loading...</span>
      </ng-template>
    </div>
    <mat-paginator #paginator [pageSize]="itemsPerPage" [disabled]="loading" [pageSizeOptions]="[5,8,10]" [length]="pageData?.totalItems" *ngIf="(logsDataSource.data | isDefined)" (page)="pageChanged($event)" showFirstLastButtons></mat-paginator>
  </div>
</dialog-layout>
