import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { GetCoordinateSystemResponse } from '../../../generated/tenant/model/getCoordinateSystemResponse';
import { DeviceService } from '../services/device.service';

type CoordinateSystem = GetCoordinateSystemResponse;

@Component({
  selector: 'coordinate-system-select',
  template: `
    <ng-select
      class="custom field-select-control"
      [items]="coordinateSystems"
      [selectOnTab]="true"
      [clearable]="clearable"
      [virtualScroll]="true"
      [trackByFn]="trackById"
      [searchFn]="searchFn"
      [readonly]="disabled"
      [loading]="loading"
      [placeholder]="loading ? 'Loading...' : placeholder"
      [(ngModel)]="coordinateSystem"
      [groupBy]="groupByFn"
      [appendTo]="device.isDesktop() ? 'body' : ''"
      [dropdownPosition]="device.isDesktop() ? 'auto' : 'top'"
    >
      <ng-template ng-label-tmp let-item="item"> {{ item.name }} {{ item.code ? '(' + item.code + ')' : '' }} </ng-template>
      <ng-template ng-option-tmp let-item="item">
        {{ item.name }}<br />
        <small *ngIf="item.code">{{ item.code }}</small>
      </ng-template>
    </ng-select>
  `,
  styleUrls: ['./coordinate-system-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CoordinateSystemSelectComponent),
      multi: true
    }
  ]
})
export class CoordinateSystemSelectComponent implements OnInit, ControlValueAccessor {
  @Input() coordinateSystems: CoordinateSystem[];
  @Input() loading: boolean;
  @Input() disabled: boolean;
  @Input() clearable: boolean;
  @Input() placeholder = 'Select coordinate system';
  @Input() groupByFn: (coordinateSystem: CoordinateSystem) => string;

  value: CoordinateSystem;
  get coordinateSystem() {
    return this.value;
  }
  @Input() set coordinateSystem(value: CoordinateSystem) {
    this.value = value;
    this.onChange(value);
    this.onTouched();
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  constructor(public device: DeviceService) {}

  ngOnInit() {}

  writeValue(value: CoordinateSystem) {
    this.coordinateSystem = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  searchFn(term: string, item: CoordinateSystem) {
    term = term.toLowerCase();
    return item.name.toLowerCase().indexOf(term) > -1 || item.code?.toLowerCase().indexOf(term) > -1;
  }

  trackById(index: number, item: CoordinateSystem) {
    return item ? item.id : null;
  }
}
