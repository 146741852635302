import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { AcMapComponent, MapsManagerService, PlonterService } from '@datumate/angular-cesium';

import { LineType, PointType, PolygonType } from '../../detailed-site/state/detailed-site-entities/detailed-site-entities.model';
import { screenToPositionSimple } from '../utils/cesium-common';

@Component({
  selector: 'map-plonter',
  template: `
    <ac-html
      *ngIf="showPlonter"
      (clickOutside)="onChooseEntity(null)"
      [clickOutsideEvents]="'click,contextmenu'"
      [delayClickOutsideInit]="true"
      [props]="{
        position: plonterPosition
      }"
    >
      <div class="plonter-context-menu">
        <div
          *ngFor="let entity of plonterService.entitesToPlonter"
          class="row plonter-item"
          (click)="onChooseEntity(entity)"
          (mouseenter)="onHoverEntity(entity)"
          (mouseleave)="onHoverEntity(null)"
        >
          <mat-icon class="type-icon" *ngIf="getTypeIcon(entity)" [svgIcon]="getTypeIcon(entity)"></mat-icon>
          <div class="column space-around full-height">
            <span>
              <span>{{ entity?.name || entity?.id }}</span>
              <span *ngIf="isDesignEntity(entity)"> #{{ entity?.id }}</span>
            </span>

            <span *ngIf="isDesignEntity(entity)" class="design-name">{{ entity.designName }}</span>
          </div>
        </div>
      </div>
    </ac-html>
  `,
  styleUrls: ['./map-plonter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapPlonterComponent implements OnInit, OnDestroy {
  @Output() hoverEntity = new EventEmitter();
  @Input() mapId: string;

  private map: AcMapComponent;

  constructor(
    public plonterService: PlonterService,
    private cd: ChangeDetectorRef,
    private mapsManager: MapsManagerService,
    private zone: NgZone,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit() {
    this.map = this.mapsManager.getMap(this.mapId);
    this.document.addEventListener('keydown', this.onKeyDown);

    this.plonterService.plonterChangeNotifier.subscribe(() => {
      this.cd.detectChanges();
    });
  }

  ngOnDestroy() {
    this.document.removeEventListener('keydown', this.onKeyDown);
  }

  private onKeyDown = (event: KeyboardEvent) => {
    if (!this.showPlonter) {
      return;
    }

    if (event.key === 'Escape') {
      this.onChooseEntity(null);
    }
  };

  get plonterPosition() {
    if (this.plonterService.plonterShown) {
      const screenPos = this.plonterService.plonterClickPosition.endPosition;
      return screenToPositionSimple(this.map, screenPos);
    }
  }

  get showPlonter() {
    return this.plonterService.plonterShown;
  }

  onChooseEntity(entity: any) {
    this.zone.run(() => {
      this.plonterService.resolvePlonter(entity);
    });
  }

  onHoverEntity(entity: any) {
    this.zone.run(() => {
      this.hoverEntity.emit(entity);
    });
  }

  getTypeIcon(entity: any) {
    if (entity?.type) {
      if (entity.type in PolygonType || entity.type === 'Polygon') {
        return 'area';
      } else if (entity.type in LineType || entity.type === 'LineString') {
        return 'line';
      } else if (entity.type in PointType || entity.type === 'Point') {
        return 'pin';
      }
    }
  }

  isDesignEntity(entity: any) {
    return !!entity?.designName;
  }
}
