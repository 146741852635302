<div *ngIf="isOpen && hasIssues" class="issues-summary-box" [@translate]>
  <div class="issues-summary-header">
    <span class="issues-summary-title" i18n="@@shared.issuesSummary.title">ISSUES</span>
    <button mat-button class="issues-summary-button" (click)="isOpen = false">
      <span class="row">
        <ng-container i18n="@@shared.issuesSummary.collapseButtonCaption">Collapse details</ng-container>
        <mat-icon class="collapse">double_arrow</mat-icon>
      </span>
    </button>
  </div>
  <div class="issues-messages">
    <issue-banner *ngFor="let issue of issues" [type]="issue.type" [caption]="issue.caption" [count]="issue.count"></issue-banner>
  </div>
</div>

<div *ngIf="!isOpen && hasIssues" class="issues-total-summary issues-summary-box">
  <div class="issues-summary">
    <mat-icon *ngIf="hasErrors" class="issue-icon error" svgIcon="error-colored-mark"></mat-icon>
    <mat-icon *ngIf="!hasErrors" class="issue-icon warning" svgIcon="warning-colored-mark"></mat-icon>
    <button mat-button class="issues-summary-button" (click)="isOpen = true" matTooltip="Expand details">
      <span class="row">
        <span class="issues-summary-text">{{ summaryIssuesText }}</span>
        <mat-icon class="expand">double_arrow</mat-icon>
      </span>
    </button>
  </div>
</div>
