import { Component, Injectable, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UntilDestroy } from '@ngneat/until-destroy';
import moment from 'moment';
import { Papa } from 'ngx-papaparse';
import { Subject } from 'rxjs';

import { CreateTaskLogPaginationRequest } from '../../../../generated/fms/model/createTaskLogPaginationRequest';
import { GetAllTasksLogsPaginationResponse } from '../../../../generated/fms/model/getAllTasksLogsPaginationResponse';
import { GetAllTasksResponse } from '../../../../generated/fms/model/getAllTasksResponse';
import { GetTaskLogsResponse } from '../../../../generated/fms/model/getTaskLogsResponse';
import { PageTokenModel } from '../../../../generated/fms/model/pageTokenModel';
import { TableSortModel } from '../../../../generated/fms/model/tableSortModel';
import { AuthQuery } from '../../../auth/state/auth.query';
import { TenantQuery } from '../../../tenant/tenant.query';
import { DeviceService } from '../../services/device.service';
import { LocaleService } from '../../services/locale.service';
import { SnackBarService } from '../../services/snackbar.service';
import { saveCSV } from '../../utils/file-utils';
import { capitalize } from '../../utils/formatting';
import { isDefined } from '../../utils/general';
import { ProcessingHistoryService } from './processing-history.service';

import TaskLogEvent = GetTaskLogsResponse.EventTypeEnum;
import TaskLog = GetTaskLogsResponse;

const EventTypeEnum = CreateTaskLogPaginationRequest.EventTypeEnum;
const TableSortFieldNameEnum = TableSortModel.FieldNameEnum;
const TableSortOrderEnum = TableSortModel.OrderEnum;

@Injectable()
export class CustomPaginatorIntl implements MatPaginatorIntl {
  changes = new Subject<void>();

  itemsPerPageLabel = $localize`:@@shared.matPaginator.itemsPerPageLabel:Items per page:`;
  firstPageLabel = $localize`:@@shared.matPaginator.firstPageLabel:First page`;
  lastPageLabel = $localize`:@@shared.matPaginator.lastPageLabel:Last page`;
  nextPageLabel = $localize`:@@shared.matPaginator.nextPageLabel:Next page`;
  previousPageLabel = $localize`:@@shared.matPaginator.previousPageLabel:Previous page`;

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return $localize`:@@shared.matPaginator.rangeLabelEmpty:Page 0 of 0`;
    }
    const amountPages = Math.ceil(length / pageSize);
    return $localize`:@@shared.matPaginator.rangeLabel:Page ${page + 1} of ${amountPages}`;
  }
}

@UntilDestroy()
@Component({
  selector: 'processing-history',
  templateUrl: './processing-history.component.html',
  styleUrls: ['./processing-history.component.scss'],
  providers: [{ provide: MatPaginatorIntl, useClass: CustomPaginatorIntl }]
})
export class ProcessingHistoryComponent implements OnInit {
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  isNaN = isNaN;

  sites: { id: string; name: string }[];
  siteTasks: { id: string; date: Date; label: string }[];
  events: { id: TaskLogEvent; label: string }[];

  logsDataSource = new MatTableDataSource<TaskLog>();
  tasksCols = ['SiteName', 'MissionFlightDate', 'EventType', 'EventDate', 'ImagesCost'];

  loading: boolean;
  itemsPerPage = 8;
  pageData: PageTokenModel;
  tableFilters = {
    siteId: null,
    taskId: null,
    eventType: null
  };

  csvBtnCaptions = {
    filteredData: $localize`:@@shared.processingHistory.downloadFilteredDataCSVBtnCaption:Download filtered data as CSV`,
    allData: $localize`:@@shared.processingHistory.downloadDataWithoutFiltersCSVBtnCaption:Download data as CSV`
  };
  taskSelectTitleNoSite = $localize`:@@shared.processingHistory.taskSelectTitleNoSite:Please select a site first`;

  constructor(
    private localeService: LocaleService,
    private tasksService: ProcessingHistoryService,
    private authQuery: AuthQuery,
    private dialogRef: MatDialogRef<ProcessingHistoryComponent>,
    private device: DeviceService,
    private papa: Papa,
    private tenantQuery: TenantQuery,
    private snackbar: SnackBarService
  ) {}

  ngOnInit() {
    this.logsDataSource.sort = this.sort;
    this.logsDataSource.sort.sort({ start: 'desc', id: TableSortFieldNameEnum.EventDate, disableClear: false });
    this.logsDataSource.paginator = this.paginator;

    this.sites = this.tenantQuery
      .getAllSites()
      .map(s => ({ id: s.id, name: s.name }))
      .sort((site1, site2) => site1.name.localeCompare(site2.name));

    this.events = Object.values(TaskLogEvent)
      .map(event => (event !== EventTypeEnum.NONE ? { id: event, label: this.formatEvent(event) } : null))
      .filter(e => e);

    const pageDetails = this.generatePageToken(0, this.itemsPerPage);
    this.fetchPage(pageDetails);
  }

  getImageCostFormulaString(taskLog: TaskLog) {
    if (taskLog.imagesCount === 0 || taskLog.imageResolutionMP === 0 || taskLog.baselineResolutionMP === 0) {
      return undefined;
    }

    if (taskLog.imageResolutionMP <= taskLog.baselineResolutionMP) {
      return $localize`:@@shared.processingHistory.imagesCount:${taskLog.imagesCount} images`;
    }

    return $localize`:@@shared.processingHistory.imagesCountWithFormula:(${taskLog.imageResolutionMP}mpx resolution / ${taskLog.baselineResolutionMP}mpx baseline) \u00d7 ${taskLog.imagesCount} images = ${taskLog.imagesCost}`;
  }

  close() {
    this.dialogRef.close();
  }

  selectRow(taskLog: TaskLog) {
    this.dialogRef.close();

    // Open site page with task selected if finished
    const tenantId = this.authQuery.getActiveTenantId();
    if (taskLog.eventType === TaskLogEvent.FINISHEDGENERATINGMODEL) {
      window.open(`/${tenantId}/sites/${taskLog.siteId}/tasks/${taskLog.taskId}`, '_self');
    } else {
      window.open(`/${tenantId}/sites/${taskLog.siteId}`, '_self');
    }
  }

  onChangeSite(siteId: string) {
    this.tableFilters.siteId = siteId;

    if (isDefined(siteId)) {
      this.loading = true;
      this.tasksService.fetchSiteTasks(siteId).subscribe({
        next: (res: GetAllTasksResponse) => {
          if (isDefined(res.tasks)) {
            const tasksData = res.tasks.map(t => ({ id: t.id, date: t.missionFlightDate, label: this.formatDate(t.missionFlightDate) }));
            this.siteTasks = tasksData;
            this.loading = false;
          } else {
            this.siteTasks = [];
          }
        },
        error: error => {
          this.snackbar.openError($localize`:@@shared.processingHistory.failedFetchSites:Failed to fetch all sites`, error);
        }
      });
    } else {
      this.tableFilters.taskId = null;
      this.siteTasks = [];
    }
    const pageDetails = this.generatePageToken(0, this.itemsPerPage);
    this.fetchPage(pageDetails);
  }

  onChangeTask(taskId: string) {
    this.tableFilters.taskId = taskId;
    const pageDetails = this.generatePageToken(0, this.itemsPerPage);
    this.fetchPage(pageDetails);
  }

  onChangeEvent(eventType: TaskLogEvent) {
    this.tableFilters.eventType = eventType;
    const pageDetails = this.generatePageToken(0, this.itemsPerPage);
    this.fetchPage(pageDetails);
  }

  onDownloadCSV() {
    if (this.loading) {
      return;
    }
    const pageDetails = this.generatePageToken(0, -1);
    this.tasksService.fetchTaskLogs(pageDetails).subscribe({
      next: (result: GetAllTasksLogsPaginationResponse) => {
        this.loading = false;
        this.generateCSV(this.fixTasksLogsTimezone(result.tasksLogs));
      },
      error: error => {
        this.loading = false;

        this.snackbar.openError(
          $localize`:@@shared.processingHistory.failedGenerateCSV:Failed to generate CSV file`,
          `failed to fetch All tasks logs data:`,
          { pageDetails, error }
        );
      }
    });
  }

  generateCSV(data: TaskLog[]) {
    const tenantName = this.authQuery.getActiveTenantName();
    const sortedFilteredRows = data.sort((row1, row2) => {
      const compareSitesRes = row1.siteName.localeCompare(row2.siteName);
      if (compareSitesRes === 0) {
        return moment(row1.missionFlightDate).isBefore(row2.missionFlightDate, 'day') ? -1 : 1;
      }

      return compareSitesRes;
    });
    const exportData = sortedFilteredRows.map((row: TaskLog) => ({
      [$localize`:@@shared.processingHistory.csvFileCellHeaderAccount:Account`]: tenantName,
      [$localize`:@@shared.processingHistory.csvFileCellHeaderSite:Site`]: row.siteName,
      [$localize`:@@shared.processingHistory.csvFileCellHeaderAccountMappingInstance:Mapping Instance`]: this.formatDate(
        row.missionFlightDate
      ),
      [$localize`:@@shared.processingHistory.csvFileCellHeaderEvent:Event`]: this.formatEvent(row.eventType),
      [$localize`:@@shared.processingHistory.csvFileCellHeaderDate:Date`]: this.localeService.formatDateNumeral({
        date: row.eventDate,
        withTime: true
      }),
      [$localize`:@@shared.processingHistory.csvFileCellHeaderAccountImageConsumption:Image Consumption`]: row.imagesCost
    }));

    const csv = this.papa.unparse(exportData, { header: true });
    this.loading = false;
    saveCSV(csv, this.generateCSVName());
  }

  private generateCSVName() {
    const nameParts: string[] = [];

    const siteName = this.tableFilters.siteId ? this.sites.find(site => site.id === this.tableFilters.siteId)?.name : null;
    if (siteName) {
      nameParts.push(siteName);
    }

    const taskDate = this.tableFilters.taskId ? this.siteTasks.find(task => task.id === this.tableFilters.taskId)?.date : null;
    if (taskDate) {
      nameParts.push(moment(taskDate).format('YYYY_MM_DD'));
    }

    const selectedEventName = this.tableFilters.eventType
      ? this.formatEvent(this.tableFilters.eventType)
      : $localize`:@@shared.processingHistory.csvFileEventTypeAll:All`;

    nameParts.push($localize`:@@shared.processingHistory.dialogHeader:processing history`, selectedEventName);

    return nameParts.join(' - ') + '.csv';
  }

  trackByFunc(index: number, taskLog: TaskLog) {
    return taskLog ? taskLog.id : null;
  }

  formatEvent(event: TaskLogEvent) {
    switch (event) {
      case TaskLogEvent.FINISHEDPREPROCESSING:
        return $localize`:@@shared.processingHistory.taskLogEvent.preProcessingFinished:Pre-processing finished`;
      case TaskLogEvent.STARTGENERATINGMODEL:
        return $localize`:@@shared.processingHistory.taskLogEvent.generatingModelStarted:Generating model started`;
      case TaskLogEvent.FINISHEDGENERATINGMODEL:
        return $localize`:@@shared.processingHistory.taskLogEvent.generatingModelFinished:Generating model finished`;
      case TaskLogEvent.CREATEDBYOPERATOR:
        return $localize`:@@shared.processingHistory.taskLogEvent.createdByOperator:Created by operator`;
      case TaskLogEvent.APPROVEDBYUSER:
        return $localize`:@@shared.processingHistory.taskLogEvent.flightImagesApproved:Flight images approved`;
      case TaskLogEvent.IMPORTEDLAS:
        return $localize`:@@shared.processingHistory.taskLogEvent.importedOnlyLAS:Imported (LAS)`;
      case TaskLogEvent.IMPORTEDLASANDTIFF:
        return $localize`:@@shared.processingHistory.taskLogEvent.importedLASAndTIFF:Imported (LAS + TIFF)`;
      default:
        return capitalize(event.replace(/_/g, ' '));
    }
  }

  formatDate(date: Date) {
    return this.localeService.formatDateName({ date });
  }

  formatShortDate(date: Date) {
    return this.localeService.formatShortDate({ date });
  }

  get isDesktop() {
    return this.device.isDesktop();
  }

  pageChanged(event: PageEvent) {
    this.itemsPerPage = event.pageSize;
    const pageDetails = this.generatePageToken(event.pageIndex, this.itemsPerPage);
    this.fetchPage(pageDetails);
  }

  fetchPage(pageDetails: CreateTaskLogPaginationRequest) {
    this.loading = true;
    const pageIndex = pageDetails.pageToken.currentPageNumber;

    this.tasksService.fetchTaskLogs(pageDetails).subscribe({
      next: (result: GetAllTasksLogsPaginationResponse) => {
        this.pageData = result.pageToken;
        this.logsDataSource.data = this.fixTasksLogsTimezone(result.tasksLogs);

        if (pageIndex === 0 && this.paginator) {
          this.paginator.firstPage();
        }
        this.loading = false;
      },
      error: error => {
        this.loading = false;
        this.snackbar.openError(
          $localize`:@@shared.processingHistory.failedToLoadPageData:Failed to load page #${pageIndex + 1}`,
          `failed to fetch pageIndex ${pageIndex} data, pageDetails:`,
          { pageDetails, error }
        );
      }
    });
  }

  private fixTasksLogsTimezone(tasksLogs: TaskLog[]) {
    // Treat eventDate as UTC date
    return tasksLogs.map(l => ({ ...l, eventDate: moment.utc(l.eventDate).toDate() }));
  }

  onTableSortChange(event: Sort) {
    if (!isDefined(this.pageData)) {
      return;
    }

    this.pageData.tableSortModel = {
      fieldName: isDefined(event.direction) ? TableSortModel.FieldNameEnum[event.active] : TableSortModel.FieldNameEnum.EventDate,
      order: isDefined(event.direction) ? TableSortModel.OrderEnum[event.direction.toUpperCase()] : TableSortModel.OrderEnum.DESC
    };

    const pageDetails = this.generatePageToken(0, this.itemsPerPage);
    this.fetchPage(pageDetails);
  }

  get isTableFiltered() {
    return isDefined(this.tableFilters.siteId) || isDefined(this.tableFilters.taskId) || isDefined(this.tableFilters.eventType);
  }

  private generatePageToken(pageIndex: number, itemsPerPage: number) {
    const pageDetails = {
      pageToken: {
        currentPageNumber: pageIndex,
        lastPageNumber: 0,
        pageSize: itemsPerPage,
        tableSortModel: {
          fieldName: this.pageData?.tableSortModel.fieldName || TableSortFieldNameEnum.EventDate,
          order: this.pageData?.tableSortModel.order || TableSortOrderEnum.DESC
        },
        totalItems: 0
      },
      ...this.tableFilters
    };
    if (!isDefined(this.tableFilters.eventType)) {
      pageDetails.eventType = EventTypeEnum.NONE;
    }
    return pageDetails;
  }
}
