import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { CreateTaskLogPaginationRequest } from '../../../../generated/fms/model/createTaskLogPaginationRequest';
import { REQUIRED_ACCESS_LEVEL_HEADER } from '../../../auth/state/auth.utils';
import PERMISSIONS from '../../../auth/state/permissions';
import { getServiceUrl } from '../../utils/backend-services';

@Injectable({
  providedIn: 'root'
})
export class ProcessingHistoryService {
  constructor(private http: HttpClient) {}

  fetchTaskLogs(pageToken: CreateTaskLogPaginationRequest) {
    return this.http.post(`${getServiceUrl('fms')}/tasksLogs`, pageToken, {
      headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.flightLogs.read }
    });
  }

  fetchSiteTasks(siteId: string) {
    return this.http.get(`${getServiceUrl('fms')}/tasks?site=${siteId}`, {
      headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.flights.read }
    });
  }
}
