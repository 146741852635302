<div *ngIf="showLegend" class="column justify-center chart-legend">
  <div class="row series" *ngFor="let seriesData of lineChartData" [class]="seriesData.hidden ? hideInDownloadClass : ''">
    <mat-checkbox (change)="checkboxChange(seriesData)" [checked]="!seriesData.hidden" [class]="hideInDownloadClass"></mat-checkbox>
    <underline-tooltip
      [text]="seriesData.tooltip"
      (click)="checkboxChange(seriesData)"
      [style.border-bottom-color]="seriesData.borderColor"
      [style.border-bottom-style]="seriesData.borderDash ? 'dashed' : 'solid'"
      class="series-label"
    >
      {{ seriesData.label }}
    </underline-tooltip>
  </div>
</div>

<div
  class="column chart-container"
  [class.with-extra-data]="hasYAxisName || showExtraData || showDownloadButton"
  [class.with-x-axe-name]="hasXAxisName && !hideXAxes"
>
  <div class="extra-data row">
    <span *ngIf="hasYAxisName" class="unit-y">{{ unitsY }}</span>
    <ng-content></ng-content>
    <button
      mat-icon-button
      *ngIf="showDownloadButton"
      title="Download chart as image"
      i18n-title="@@shared.measurementChart.downloadButtonTitle"
      class="download-button"
      [class]="[hideInDownloadClass]"
      [disabled]="downloadingImage"
      (click)="downloadChartAsImage()"
    >
      <mat-icon>download</mat-icon>
    </button>

    <button
      mat-icon-button
      title="Reset chart zoom"
      i18n-title="@@shared.measurementChart.resetButtonTitle"
      class="reset-zoom-button"
      *ngIf="showResetZoomButton"
      (click)="onResetZoom()"
    >
      ↶
    </button>

    <button
      mat-icon-button
      title="Flip chart"
      i18n-title="@@shared.measurementChart.flipButtonTitle"
      class="reverse-button"
      *ngIf="(lineChartData | isDefined) && showReverseButton"
      (click)="onToggleReverseChart()"
    >
      <mat-icon svgIcon="flip"></mat-icon>
    </button>
  </div>

  <canvas
    baseChart
    [datasets]="lineChartData"
    [labels]="lineChartLabels"
    [options]="lineChartOptions"
    [type]="lineChartType"
    [plugins]="lineChartPlugins"
    (chartClick)="chartClicked.emit($event)"
    (mouseleave)="chartLeave.emit()"
  ></canvas>
  <span class="unit-x" *ngIf="!hideXAxes && hasXAxisName">{{ unitsX }}</span>
</div>
