import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Papa } from 'ngx-papaparse';

import { saveCSV } from '../utils/file-utils';

export type IssueTypeEnum = 'WARNING' | 'ERROR';
export const IssueTypeEnum = {
  WARNING: 'WARNING' as IssueTypeEnum,
  ERROR: 'ERROR' as IssueTypeEnum
};

export interface Issue {
  code?: string;
  description?: string;
  type?: IssueTypeEnum;
}

export interface IssuesDialogData {
  title: string;
  topText?: string;
  bottomText?: string;
  yesCaption?: string;
  noCaption?: string;
  issues: Issue[];
  logFileName?: string;
  disableClose?: boolean;
}

function sortIssues(result1: Issue, result2: Issue) {
  if (result1.type !== result2.type) {
    return result1.type === IssueTypeEnum.ERROR ? -1 : 1;
  }

  return +result1.code < +result2.code ? -1 : 1;
}

@Component({
  templateUrl: './issues-dialog.component.html',
  styleUrls: ['./issues-dialog.component.scss']
})
export class IssuesDialogComponent implements OnInit {
  IssueType = IssueTypeEnum;

  issues: Issue[];

  constructor(
    private dialogRef: MatDialogRef<IssuesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IssuesDialogData,
    private papa: Papa
  ) {}

  ngOnInit() {
    this.issues = [...this.data.issues].sort(sortIssues);
  }

  close(result = false) {
    this.dialogRef.close(result);
  }

  downloadLog() {
    const csv = this.papa.unparse(this.data.issues as Array<Record<string, string>>);
    saveCSV(csv, this.data.logFileName || 'issues-log.csv');
  }
}
