import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { MapsManagerService } from '@datumate/angular-cesium';
import { Viewer } from 'cesium';

import { isDefined } from '../utils/general';
import {
  convertFromMeters,
  DistanceUnitsEnum,
  FEET_PER_MILE,
  getUnitSign,
  METERS_PER_KILOMETER,
  YARDS_PER_MILE
} from '../utils/unit-conversion';

const SCALE_VALUES = [
  0.1, 0.2, 0.3, 0.4, 0.5, 1, 2, 3, 5, 10, 15, 20, 30, 50, 100, 150, 200, 300, 500, 1000, 1500, 2000, 3000, 5000, 10000, 20000, 50000
];

const DEFAULT_MAX_WIDTH = 100;
const SCREENSHOT_MAX_WIDTH = 200;

@Component({
  selector: 'map-scale-bar',
  templateUrl: './map-scale-bar.component.html',
  styleUrls: ['./map-scale-bar.component.scss']
})
export class MapScaleBarComponent implements OnInit {
  @Input() set mapId(value: string) {
    if (!isDefined(value)) {
      return;
    }

    this.viewer = this.mapsManager.getMap(this.mapId).getCesiumViewer();
    this.viewer.camera.moveEnd.addEventListener(this.onCameraMoveEnd);
  }
  @Input() set siteUnits(value) {
    this.units = value;
    this.unitsSign = getUnitSign(value);
  }

  @Input() set screenshotMode(value: boolean) {
    this.isScreenshotMode = value;

    if (value) {
      this.maxWidth = SCREENSHOT_MAX_WIDTH;
    } else {
      this.maxWidth = DEFAULT_MAX_WIDTH;
    }

    this.updateDistanceScale();
  }

  isScreenshotMode = false;
  maxWidth = DEFAULT_MAX_WIDTH;
  distanceScaleWidth: number;
  distanceScaleLabel: string;
  viewer: Viewer;
  private units: DistanceUnitsEnum;
  private unitsSign: string;

  constructor(private mapsManager: MapsManagerService, private cd: ChangeDetectorRef) {}

  ngOnInit() {}

  private onCameraMoveEnd = () => {
    this.updateDistanceScale();
  };

  updateDistanceScale() {
    if (!isDefined(this.viewer)) {
      this.viewer = this.mapsManager.getMap(this.mapId).getCesiumViewer();
    }

    const canvas = this.viewer.scene.canvas;
    const width = canvas.clientWidth;
    const height = canvas.clientHeight;

    const scene = this.viewer.scene;
    const maxScaleBarWidthPixels = this.maxWidth;

    // Calculate the real-world distance in meters corresponding to the maximum scale bar width in pixels
    const rayLeft = scene.camera.getPickRay(new Cesium.Cartesian2(width / 2 - maxScaleBarWidthPixels / 2, height / 2));
    const rayRight = scene.camera.getPickRay(new Cesium.Cartesian2(width / 2 + maxScaleBarWidthPixels / 2, height / 2));

    const globe = scene.globe;
    const leftPosition = globe.pick(rayLeft, scene);
    const rightPosition = globe.pick(rayRight, scene);

    if (!isDefined(leftPosition) || !isDefined(rightPosition)) {
      return;
    }

    const leftCartographic = globe.ellipsoid.cartesianToCartographic(leftPosition);
    const rightCartographic = globe.ellipsoid.cartesianToCartographic(rightPosition);

    const geodesic = new Cesium.EllipsoidGeodesic();
    geodesic.setEndPoints(leftCartographic, rightCartographic);

    // Convert the distance
    const distanceInMeters = geodesic.surfaceDistance;
    const distanceBySiteUnits = convertFromMeters(distanceInMeters, this.units);
    const optimalDistance = this.getOptimalDistance(distanceBySiteUnits, maxScaleBarWidthPixels);

    // Calculate the width in pixels that the scale should occupy
    const scaleBarWidth = (optimalDistance / distanceBySiteUnits) * maxScaleBarWidthPixels;

    // Format the distance scale label
    const distanceLabel = this.formatDistanceLabel(optimalDistance);

    // Update distance scale label and width
    this.distanceScaleLabel = distanceLabel;
    this.distanceScaleWidth = scaleBarWidth;

    this.cd.detectChanges();
  }

  private getOptimalDistance(distance: number, maxWidth: number) {
    let optimalDistance = distance;

    for (const scale of SCALE_VALUES) {
      if (distance <= scale) {
        optimalDistance = scale;
        break;
      }
    }

    // Ensure the bar width fits within the maximum width
    const calculatedWidth = (optimalDistance / distance) * maxWidth;

    if (calculatedWidth > maxWidth) {
      optimalDistance /= 2; // Halve the distance to fit the scale bar width
    }

    return optimalDistance;
  }

  private formatDistanceLabel(distance: number) {
    switch (this.units) {
      case DistanceUnitsEnum.USFOOT:
      case DistanceUnitsEnum.INTFOOT:
        return distance <= FEET_PER_MILE ? `${distance} ${this.unitsSign}` : `${distance / FEET_PER_MILE} mi`;
      case DistanceUnitsEnum.YARD:
        return distance <= YARDS_PER_MILE ? `${distance} ${this.unitsSign}` : `${distance / YARDS_PER_MILE} mi`;
      case DistanceUnitsEnum.METER:
        return distance <= METERS_PER_KILOMETER ? `${distance} ${this.unitsSign}` : `${distance / METERS_PER_KILOMETER} km`;
    }
  }
}
