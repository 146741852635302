// Was copied from https://github.com/geoblocks/cesium-helpers/blob/master/packages/cesium-compass/cesium-compass.js
import { AfterViewInit, ChangeDetectorRef, Component, Input, NgZone, OnDestroy } from '@angular/core';
import { AcMapComponent, MapsManagerService } from '@datumate/angular-cesium';
import { Scene } from 'cesium';

@Component({
  selector: 'map-compass',
  templateUrl: './map-compass.component.html',
  styleUrls: ['./map-compass.component.scss']
})
export class MapCompassComponent implements AfterViewInit, OnDestroy {
  @Input() mapId: string;
  @Input() isSmall: boolean;

  map: AcMapComponent;
  scene: Scene;

  angle: number;

  constructor(private mapsManager: MapsManagerService, private cd: ChangeDetectorRef, private zone: NgZone) {}

  ngAfterViewInit() {
    this.map = this.mapsManager.getMap(this.mapId);
    this.scene = this.map.getCesiumViewer().scene;
    this.scene.postRender.addEventListener(this.onPostRender, this);
  }

  ngOnDestroy(): void {
    this.scene.postRender.removeEventListener(this.onPostRender, this);
  }

  onPostRender() {
    const angle = Cesium.Math.toDegrees(this.scene.camera.heading);
    if (angle === this.angle) {
      return;
    }

    this.angle = angle;
    this.zone.run(() => this.cd.detectChanges());
  }
}
