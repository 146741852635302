import { Directive, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { interval, takeWhile } from 'rxjs';

import { isDefined } from '../utils/general';

@Directive({
  selector: '[typingAnimation]'
})
export class TypingAnimationDirective implements OnInit {
  @Input() textToType: string;
  @Input() disableAnimation = false;
  @Input() chunkSize = 5;
  @Input() intervalTime = 30;

  @Output() chunkAdded = new EventEmitter();

  private currentText = '';

  constructor(private el: ElementRef) {}

  ngOnInit() {
    if (!this.disableAnimation && isDefined(this.textToType)) {
      this.startAnimation();
    } else {
      this.el.nativeElement.textContent = this.textToType;
    }
  }

  startAnimation() {
    interval(this.intervalTime)
      .pipe(takeWhile(() => this.currentText.length < this.textToType.length))
      .subscribe(() => {
        const nextChunk = this.textToType.slice(this.currentText.length, this.currentText.length + this.chunkSize);
        this.currentText += nextChunk;
        this.el.nativeElement.textContent = this.currentText;
        const hasMoreChunks = this.currentText.length < this.textToType.length;
        if (hasMoreChunks) {
          this.chunkAdded.emit();
        }
      });
  }
}
