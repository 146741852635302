import { Injectable } from '@angular/core';
import moment from 'moment';

import { isDefined } from '../utils/general';
import { roundTo } from '../utils/math';

export const US_LOCALE = 'en-US';
const DEFAULT_LOCALE = 'en-GB';

const angularLocalesModules: { [key: string]: () => Promise<any> } = {
  en: () => import('@angular/common/locales/global/en'),
  'en-us': () => import('@angular/common/locales/global/en'),
  'en-gb': () => import('@angular/common/locales/global/en-GB'),
  'en-ca': () => import('@angular/common/locales/global/en-CA'),
  'en-au': () => import('@angular/common/locales/global/en-AU'),
  'en-in': () => import('@angular/common/locales/global/en-IN'),
  es: () => import('@angular/common/locales/global/es'),
  'es-es': () => import('@angular/common/locales/global/es'),
  'es-cl': () => import('@angular/common/locales/global/es-CL'),
  'es-us': () => import('@angular/common/locales/global/es-US'),
  'es-mx': () => import('@angular/common/locales/global/es-MX'),
  fr: () => import('@angular/common/locales/global/fr'),
  'fr-fr': () => import('@angular/common/locales/global/fr'),
  de: () => import('@angular/common/locales/global/de'),
  'de-de': () => import('@angular/common/locales/global/de'),
  it: () => import('@angular/common/locales/global/it'),
  'it-it': () => import('@angular/common/locales/global/it'),
  pt: () => import('@angular/common/locales/global/pt'),
  'pt-br': () => import('@angular/common/locales/global/pt'),
  'pt-pt': () => import('@angular/common/locales/global/pt-PT'),
  'pt-ao': () => import('@angular/common/locales/global/pt-AO'),
  ru: () => import('@angular/common/locales/global/ru'),
  'ru-ru': () => import('@angular/common/locales/global/ru'),
  zh: () => import('@angular/common/locales/global/zh'),
  'zh-hans': () => import('@angular/common/locales/global/zh-Hans'),
  'zh-hans-cn': () => import('@angular/common/locales/global/zh-Hans'),
  'zh-hans-hk': () => import('@angular/common/locales/global/zh-Hans-HK'),
  'zh-hans-mo': () => import('@angular/common/locales/global/zh-Hans-MO'),
  'zh-hans-sg': () => import('@angular/common/locales/global/zh-Hans-SG'),
  'zh-hant': () => import('@angular/common/locales/global/zh-Hant'),
  'zh-hant-mo': () => import('@angular/common/locales/global/zh-Hant-MO'),
  'zh-hant-hk': () => import('@angular/common/locales/global/zh-Hant-HK'),
  'zh-hant-tw': () => import('@angular/common/locales/global/zh-Hant'),
  ja: () => import('@angular/common/locales/global/ja'),
  'ja-jp': () => import('@angular/common/locales/global/ja'),
  ko: () => import('@angular/common/locales/global/ko'),
  'ko-kr': () => import('@angular/common/locales/global/ko'),
  ar: () => import('@angular/common/locales/global/ar'),
  'ar-ps': () => import('@angular/common/locales/global/ar-PS'),
  'ar-il': () => import('@angular/common/locales/global/ar-IL'),
  nl: () => import('@angular/common/locales/global/nl'),
  'nl-nl': () => import('@angular/common/locales/global/nl'),
  sv: () => import('@angular/common/locales/global/sv'),
  'sv-se': () => import('@angular/common/locales/global/sv'),
  pl: () => import('@angular/common/locales/global/pl'),
  'pl-pl': () => import('@angular/common/locales/global/pl'),
  da: () => import('@angular/common/locales/global/da'),
  'da-dk': () => import('@angular/common/locales/global/da'),
  fi: () => import('@angular/common/locales/global/fi'),
  'fi-fi': () => import('@angular/common/locales/global/fi'),
  tr: () => import('@angular/common/locales/global/tr'),
  'tr-tr': () => import('@angular/common/locales/global/tr'),
  vi: () => import('@angular/common/locales/global/vi'),
  'vi-vn': () => import('@angular/common/locales/global/vi'),
  th: () => import('@angular/common/locales/global/th'),
  'th-th': () => import('@angular/common/locales/global/th'),
  he: () => import('@angular/common/locales/global/he'),
  'he-il': () => import('@angular/common/locales/global/he'),
  lb: () => import('@angular/common/locales/global/lb'),
  'lb-lu': () => import('@angular/common/locales/global/lb'),
  'de-ch': () => import('@angular/common/locales/global/de-CH'),
  'fr-ch': () => import('@angular/common/locales/global/fr-CH'),
  'it-ch': () => import('@angular/common/locales/global/it-CH'),
  nb: () => import('@angular/common/locales/global/nb'),
  'nb-no': () => import('@angular/common/locales/global/nb'),
  'nb-sj': () => import('@angular/common/locales/global/nb-SJ'),
  nn: () => import('@angular/common/locales/global/nn'),
  'nn-no': () => import('@angular/common/locales/global/nn'),
  se: () => import('@angular/common/locales/global/se'),
  'se-no': () => import('@angular/common/locales/global/se'),
  cs: () => import('@angular/common/locales/global/cs'),
  'cs-cz': () => import('@angular/common/locales/global/cs'),
  bg: () => import('@angular/common/locales/global/bg'),
  'bg-bg': () => import('@angular/common/locales/global/bg'),
  'de-be': () => import('@angular/common/locales/global/de-BE'),
  'fr-be': () => import('@angular/common/locales/global/fr-BE'),
  'nl-be': () => import('@angular/common/locales/global/nl-BE'),
  af: () => import('@angular/common/locales/global/af'),
  'af-za': () => import('@angular/common/locales/global/af'),
  'af-na': () => import('@angular/common/locales/global/af-NA'),
  ro: () => import('@angular/common/locales/global/ro'),
  'ro-ro': () => import('@angular/common/locales/global/ro')
};

const momentLocalesModules: { [key: string]: () => Promise<any> } = {
  'en-gb': () => import('moment/locale/en-gb'),
  'en-ca': () => import('moment/locale/en-ca'),
  'en-au': () => import('moment/locale/en-au'),
  'en-in': () => import('moment/locale/en-in'),
  es: () => import('moment/locale/es'),
  'es-es': () => import('moment/locale/es'),
  'es-cl': () => import('moment/locale/es'),
  'es-us': () => import('moment/locale/es-us'),
  'es-mx': () => import('moment/locale/es-mx'),
  fr: () => import('moment/locale/fr'),
  'fr-fr': () => import('moment/locale/fr'),
  de: () => import('moment/locale/de'),
  'de-de': () => import('moment/locale/de'),
  it: () => import('moment/locale/it'),
  'it-it': () => import('moment/locale/it'),
  pt: () => import('moment/locale/pt-br'),
  'pt-br': () => import('moment/locale/pt-br'),
  'pt-pt': () => import('moment/locale/pt'),
  'pt-ao': () => import('moment/locale/pt'),
  ru: () => import('moment/locale/ru'),
  'ru-ru': () => import('moment/locale/ru'),
  zh: () => import('moment/locale/zh-cn'),
  'zh-hans': () => import('moment/locale/zh-cn'),
  'zh-hans-cn': () => import('moment/locale/zh-cn'),
  'zh-hans-hk': () => import('moment/locale/zh-hk'),
  'zh-hans-mo': () => import('moment/locale/zh-mo'),
  'zh-hans-sg': () => import('moment/locale/zh-hk'),
  'zh-hant': () => import('moment/locale/zh-cn'),
  'zh-hant-mo': () => import('moment/locale/zh-mo'),
  'zh-hant-hk': () => import('moment/locale/zh-hk'),
  'zh-hant-tw': () => import('moment/locale/zh-tw'),
  ja: () => import('moment/locale/ja'),
  'ja-jp': () => import('moment/locale/ja'),
  ko: () => import('moment/locale/ko'),
  'ko-kr': () => import('moment/locale/ko'),
  ar: () => import('moment/locale/ar'),
  'ar-ps': () => import('moment/locale/ar-ps'),
  'ar-il': () => import('moment/locale/ar-ps'),
  nl: () => import('moment/locale/nl'),
  'nl-nl': () => import('moment/locale/nl'),
  sv: () => import('moment/locale/sv'),
  'sv-se': () => import('moment/locale/sv'),
  pl: () => import('moment/locale/pl'),
  'pl-pl': () => import('moment/locale/pl'),
  da: () => import('moment/locale/da'),
  'da-dk': () => import('moment/locale/da'),
  fi: () => import('moment/locale/fi'),
  'fi-fi': () => import('moment/locale/fi'),
  tr: () => import('moment/locale/tr'),
  'tr-tr': () => import('moment/locale/tr'),
  vi: () => import('moment/locale/vi'),
  'vi-vn': () => import('moment/locale/vi'),
  th: () => import('moment/locale/th'),
  'th-th': () => import('moment/locale/th'),
  he: () => import('moment/locale/he'),
  'he-il': () => import('moment/locale/he'),
  lb: () => import('moment/locale/lb'),
  'lb-lu': () => import('moment/locale/lb'),
  'de-ch': () => import('moment/locale/de-ch'),
  'fr-ch': () => import('moment/locale/fr-ch'),
  'it-ch': () => import('moment/locale/it-ch'),
  nb: () => import('moment/locale/nb'),
  'nb-no': () => import('moment/locale/nb'),
  'nb-sj': () => import('moment/locale/nb'),
  nn: () => import('moment/locale/nn'),
  'nn-no': () => import('moment/locale/nn'),
  se: () => import('moment/locale/se'),
  'se-no': () => import('moment/locale/se'),
  cs: () => import('moment/locale/cs'),
  'cs-cz': () => import('moment/locale/cs'),
  bg: () => import('moment/locale/bg'),
  'bg-bg': () => import('moment/locale/bg'),
  'de-be': () => import('moment/locale/de'),
  'fr-be': () => import('moment/locale/fr'),
  'nl-be': () => import('moment/locale/nl-be'),
  af: () => import('moment/locale/af'),
  'af-za': () => import('moment/locale/af'),
  'af-na': () => import('moment/locale/af'),
  ro: () => import('moment/locale/ro'),
  'ro-ro': () => import('moment/locale/ro')
};

function getUserLocale() {
  let locale: string;
  const navigator = window.navigator as any;
  if (navigator.languages) {
    locale = navigator.languages[0];
  } else {
    locale = navigator.userLanguage || navigator.language;
  }

  return locale;
}

class LocaleNumberParser {
  private groupRegExp: RegExp;
  private decimalRegExp: RegExp;
  private numeralRegExp: RegExp;
  private numMapper: (d: string) => string;

  constructor(locale: string) {
    const format = new Intl.NumberFormat(locale);
    const parts = format.formatToParts(12345.6);
    const numerals = Array.from({ length: 10 }).map((_, i) => format.format(i));
    const index = new Map(numerals.map((d, i) => [d, i]));
    this.groupRegExp = new RegExp(`[${parts.find(d => d.type === 'group').value}]`, 'g');
    this.decimalRegExp = new RegExp(`[${parts.find(d => d.type === 'decimal').value}]`);
    this.numeralRegExp = new RegExp(`[${numerals.join('')}]`, 'g');
    this.numMapper = (d: string) => String(index.get(d));
  }

  parse(numberStr: string) {
    if (!numberStr || typeof numberStr !== 'string') {
      return NaN;
    }

    const parsedNumber = numberStr
      .trim()
      .replace(this.groupRegExp, '')
      .replace(this.decimalRegExp, '.')
      .replace(this.numeralRegExp, this.numMapper);

    return parsedNumber ? +parsedNumber : NaN;
  }
}

@Injectable({
  providedIn: 'root'
})
export class LocaleService {
  private userLocale: string;
  private numberParser: LocaleNumberParser;

  constructor() {
    this.userLocale = getUserLocale() ?? DEFAULT_LOCALE;
    this.numberParser = new LocaleNumberParser(this.userLocale);
  }

  registerLocale = async () => {
    try {
      await this.registerLocaleInAngular(this.userLocale);
    } catch (error) {
      console.error(`Error registering locale "${this.userLocale}"`, error);
      this.userLocale = DEFAULT_LOCALE;
    }

    await this.registerLocaleInMoment(this.userLocale);
  };

  private async registerLocaleInAngular(locale: string) {
    await angularLocalesModules[locale.toLowerCase()]?.();
  }

  private async registerLocaleInMoment(locale: string) {
    await momentLocalesModules[locale.toLowerCase()]?.();
    moment.locale(locale);

    // Fix moment issue with some locales
    const momentLocaleConfig = moment.localeData();
    momentLocaleConfig.longDateFormat('lll');
    momentLocaleConfig.longDateFormat('ll');
    momentLocaleConfig.longDateFormat('l');
  }

  get locale() {
    return this.userLocale;
  }

  formatNumber(n: number, options?: Intl.NumberFormatOptions) {
    if (!isDefined(n)) {
      return '';
    }

    return n.toLocaleString(this.locale, options);
  }

  parseNumber(numberStr: string) {
    return this.numberParser.parse(numberStr);
  }

  formatAndRoundNumber(n: number, accuracy = 2, showFullFraction = true) {
    if (!isDefined(n)) {
      return '';
    }

    return this.formatNumber(roundTo(n, accuracy), { minimumFractionDigits: showFullFraction ? accuracy : undefined });
  }

  getDateFormat() {
    return moment.localeData().longDateFormat('L');
  }

  formatDateName({ date, withTime }: { date: Date | string; withTime?: boolean }) {
    return moment(date).format(withTime ? 'lll' : 'll');
  }

  formatDateNumeral({ date, shortYear, withTime }: { date: Date | string; shortYear?: boolean; withTime?: boolean }) {
    if (!isDefined(date)) {
      return null;
    }
    const d = moment(date);
    const dateFormat = shortYear ? this.getDateFormat().replace('YYYY', 'YY') : 'L';
    const result = d.format(dateFormat);
    const time = moment.utc(date).local().format('LT');
    return withTime ? `${result} ${time}` : result;
  }

  formatShortDate({ date }: { date: Date | string }, isUTC = false) {
    const now = moment();
    const d = isUTC ? moment.utc(date).local() : moment(date);
    if (d.isAfter(now.subtract(2, 'days'))) {
      return d.calendar();
    } else if (d.isAfter(now.startOf('year'))) {
      const dateFormat = this.getDateFormat().replace('YYYY', '');
      let result = d.format(dateFormat);
      // after replace 'YYYY' to '' dateFormat contains one unnecessary delimeter symbol in the begin or in the end
      // should be deleted
      const firstSymbolToNum = parseInt(result[0]);
      const isFirstSymbolNum = !!firstSymbolToNum || firstSymbolToNum === 0;
      result = !isFirstSymbolNum ? result.substr(1, 5) : result.substr(0, 5);
      return `${result} ${d.format('LT')}`;
    } else {
      return `${d.format('L')} ${d.format('LT')}`;
    }
  }
}
