import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

export interface IconSnackbarData {
  iconName: string;
  text: string;
}

@Component({
  selector: 'icon-snackbar',
  template: `
    <div class="row">
      <mat-icon [svgIcon]="data.iconName"></mat-icon>
      <span class="text">{{ data.text }}</span>
    </div>
  `,
  styles: [
    `
      .text {
        font-size: 14px;
      }

      .mat-icon {
        margin-right: 15px;
        color: var(--primary-color);
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconSnackbarComponent implements OnInit {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: IconSnackbarData) {}

  ngOnInit() {}
}
