import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'filterHighlight'
})
export class FilterHighlightPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string, filterText: string): SafeHtml {
    if (!filterText) {
      return value;
    }
    const regex = new RegExp(`(${this.escapeRegExpChars(filterText)})`, 'gi');
    const highlighted = value.replace(regex, `<span class="highlight-filter-string">$1</span>`);
    return this.sanitizer.bypassSecurityTrustHtml(highlighted);
  }

  private escapeRegExpChars(text: string): string {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  }
}
