import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'compass',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './compass.component.html',
  styleUrls: ['./compass.component.scss']
})
export class CompassComponent {
  @Input() angle: number;
  @Input() arrowSize = 48;

  constructor() {}

  get rotateStyle() {
    return {
      transform: `rotate(-${this.angle}deg)`
    };
  }
}
