import { Injectable } from '@angular/core';
import { Order, Query, QueryEntity } from '@datorama/akita';
import moment from 'moment';
import { combineLatest, merge } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { BaseSurface } from '../../../generated/mms/model/baseSurface';
import { isLocalCS } from '../../shared/utils/backend-services';
import { convertCoordinateCSToPosition, convertCoordinateToSiteCS } from '../../shared/utils/cs-conversions';
import { Cartographic } from '../../shared/utils/geo';
import { roundTo } from '../../shared/utils/math';
import { convertFromMeters, DistanceUnitsEnum, getUnitSign } from '../../shared/utils/unit-conversion';
import { ArtifactEnum } from '../../tenant/tenant.model';
import {
  FlightSourceEnum,
  GEOREF_METHODS_WITH_GCP,
  MapTextureType,
  OverlaysEnum,
  TabEnum,
  Task,
  TaskStateEnum
} from './detailed-site.model';
import { DetailedSiteState, DetailedSiteStore } from './detailed-site.store';
import { DetailedSiteDesignsQuery } from './detailed-site-designs/detailed-site-designs.query';
import {
  BaseSurfaceOption,
  BaseSurfaceType,
  PolygonType,
  SourceModel,
  TaskOrDesignInfo,
  TaskOrDesignValues
} from './detailed-site-entities/detailed-site-entities.model';
import { DetailedSiteEntitiesQuery } from './detailed-site-entities/detailed-site-entities.query';

export const oldestToNewestTasksSorting = (t1: Task, t2: Task) => (moment(t1.missionFlightDate).isAfter(t2.missionFlightDate) ? 1 : -1);
export const newestToOldestTasksSorting = (t1: Task, t2: Task) => (moment(t1.missionFlightDate).isAfter(t2.missionFlightDate) ? -1 : 1);

export const finishedTasksFilter = (task: Task) => task.state === TaskStateEnum.SUCCESS;
export const taskHasGCP = (t: Task) =>
  t.state === TaskStateEnum.SUCCESS && t.flightSource !== FlightSourceEnum.IMPORTED && t.georefMethod in GEOREF_METHODS_WITH_GCP;

export function getSourceModelFromTexture(mapTexture: MapTextureType) {
  switch (mapTexture) {
    case MapTextureType.TERRAIN:
      return SourceModel.DTM;

    case MapTextureType.SURFACE:
    case MapTextureType.TILES_3D:
    case MapTextureType.POINT_CLOUD:
      return SourceModel.DSM;

    case MapTextureType.FLAT:
    default:
      return SourceModel.FLAT;
  }
}

@Injectable({ providedIn: 'root' })
export class DetailedSiteQuery extends Query<DetailedSiteState> {
  siteLoading$ = this.selectLoading();
  site$ = this.select(state => state.site);
  siteBounds$ = this.select(state => state.siteBounds);
  siteName$ = this.select(state => state.site && state.site.name);
  siteDescription$ = this.select(state => state.site && state.site.description);
  siteAssociations$ = this.select(state => state.site && state.site.associations);
  isLocalCS$ = this.select(state => isLocalCS(state.site?.coordinateSystem));
  mapDiffTask$ = this.select(state => state.ui.mapDiffTask);
  isMapFullScreen$ = this.select(state => state.ui.isMapFullScreen);
  siteImageryLayer$ = this.select(state => state.ui.siteImageryLayer);
  isSimpleView$ = this.select(state => state.ui.isSimpleView);
  isScreenshotGenerating$ = this.select(state => state.ui.isScreenshotGenerating);
  mapPositionPin$ = this.select(state => state.ui.mapPositionPin);
  myPositionPin$ = this.select(state => state.ui.myPositionPin);
  goToPin$ = this.select(state => state.ui.goToPin);

  cursorLocation$ = this.select(state => state.ui.cursorLocation);
  imageViewerToolShow$ = this.select(state => state.ui.imageViewerTool.show);
  imageViewerToolImages$ = this.select(state => state.ui.imageViewerTool.images);
  gcps$ = this.select(state => state.ui.overlays[OverlaysEnum.GCPS].gcps);
  showGCPs$ = this.select(state => state.ui.overlays[OverlaysEnum.GCPS].show);
  mapTextureType$ = this.select(state => state.ui.mapTexture);
  sourceModel$ = this.select(state => getSourceModelFromTexture(state.ui.mapTexture));
  isDisplayingTerrain$ = this.select(state =>
    [MapTextureType.TERRAIN, MapTextureType.SURFACE, MapTextureType.FLAT].includes(state.ui.mapTexture)
  );
  dsmAvailable$ = this.select(state => state.ui.dsmAvailable);
  viewerCredentials$ = this.select(state => state.viewerUrlsCredentials);
  mapLoading$ = this.select(state => state.ui.mapLoading).pipe(distinctUntilChanged());
  contourOverlay$ = this.select(state => state.ui.overlays[OverlaysEnum.CONTOUR]);
  elevationOverlay$ = this.select(state => state.ui.overlays[OverlaysEnum.ELEVATION]);

  tasksQuery = new QueryEntity(this.store.tasks, { sortBy: oldestToNewestTasksSorting, sortByOrder: Order.ASC });
  tasks$ = this.tasksQuery.selectAll({ filterBy: task => task.state !== TaskStateEnum.UPLOADING });
  tasksLoading$ = this.tasksQuery.selectLoading();
  finishedTasks$ = this.tasksQuery.selectAll({ filterBy: finishedTasksFilter });
  activeTask$ = this.tasksQuery.selectActive().pipe(distinctUntilChanged((x, y) => x && y && x.id === y.id));
  siteDisabled$ = combineLatest([this.tasksQuery.selectActive(), this.siteLoading$, this.mapLoading$]).pipe(
    map(([task, siteLoading, mapLoading]) => !task || siteLoading || mapLoading)
  );

  mapOpacity$ = this.select(state => state.mapOpacity);
  get mapOpacity() {
    return this.getValue().mapOpacity;
  }

  mapStyle$ = this.select(state => state.mapStyle);
  activeTab$ = this.select(state => state.ui.activeTab);
  get mapStyle() {
    return this.getValue().mapStyle;
  }

  constructor(
    protected store: DetailedSiteStore,
    private designsQuery: DetailedSiteDesignsQuery,
    private siteEntitiesQuery: DetailedSiteEntitiesQuery
  ) {
    super(store);
  }

  sortTaskAndDesignsCalcResults = (a: TaskOrDesignValues | TaskOrDesignInfo, b: TaskOrDesignValues | TaskOrDesignInfo) => {
    if (a.type !== b.type) {
      return a.type === 'TASK' ? -1 : 1;
    } else {
      if (a.type === 'TASK' && b.type === 'TASK') {
        const taskA = this.getTask(a.id);
        const taskB = this.getTask(b.id);
        if (!taskA || !taskB) {
          return 0;
        }

        return newestToOldestTasksSorting({ missionFlightDate: taskA.missionFlightDate }, { missionFlightDate: taskB.missionFlightDate });
      } else if (a.type === 'DESIGN' && b.type === 'DESIGN') {
        const designA = this.designsQuery.getDesign(a.id);
        const designB = this.designsQuery.getDesign(b.id);
        if (!designA || !designB) {
          return 0;
        }
        return designA.name.localeCompare(designB.name);
      }
    }
  };

  getTerrainHeightOffset() {
    return this.getValue().siteOffset ?? 0;
  }

  getMapPositionPin() {
    return this.getValue().ui.mapPositionPin;
  }

  getMyPositionPin() {
    return this.getValue().ui.myPositionPin;
  }

  isSiteDisabled() {
    return !this.getActiveTask();
  }

  getMapTexture() {
    return this.getValue().ui.mapTexture;
  }

  isDisplayingTerrain() {
    const currentTexture = this.getValue().ui.mapTexture;
    return [MapTextureType.FLAT, MapTextureType.TERRAIN, MapTextureType.SURFACE].includes(currentTexture);
  }

  getCurrentSourceModel() {
    const currentTexture = this.getValue().ui.mapTexture;
    return getSourceModelFromTexture(currentTexture);
  }

  getActiveTask() {
    return this.tasksQuery.getActive();
  }

  getActiveTaskId() {
    const activeTask = this.getActiveTask();
    return activeTask?.id;
  }

  getTaskIndex(taskId: string) {
    const allTasks = this.getAllTasks();
    return allTasks.findIndex(task => task.id === taskId);
  }

  getTask(taskId: string) {
    return this.tasksQuery.getEntity(taskId);
  }

  getTaskState(taskId: string) {
    return this.getTask(taskId)?.state;
  }

  selectTask(taskId: string) {
    return this.tasksQuery.selectEntity(taskId);
  }

  getTaskOrDesignName(id: string, type: 'DESIGN' | 'TASK' | 'ROAD_DESIGN') {
    switch (type) {
      case 'TASK': {
        const task = this.getTask(id);
        return task?.flightDateLabel;
      }
      case 'DESIGN': {
        const design = this.designsQuery.getDesign(id);
        return design?.name;
      }
      case 'ROAD_DESIGN': {
        const roadDesign = this.designsQuery.getRoadDesign(id);
        return roadDesign?.name;
      }
    }
  }

  getSite() {
    return this.getValue().site;
  }

  getIsLocalCS() {
    return isLocalCS(this.getSite()?.coordinateSystem);
  }

  getSiteUnits(): DistanceUnitsEnum {
    const site = this.getSite();
    if (!site) {
      return null;
    }

    return site.units;
  }

  getSiteVolumeUnits(): DistanceUnitsEnum {
    const site = this.getSite();
    if (!site) {
      return null;
    }

    return site.volumeUnits;
  }

  getSiteId() {
    const site = this.getSite();
    if (!site) {
      return null;
    }

    return site.id;
  }

  getSiteCoordinateSystem() {
    const site = this.getSite();
    if (!site) {
      return null;
    }

    return site.coordinateSystem;
  }

  getSiteLocation() {
    const site = this.getSite();
    if (!site) {
      return null;
    }

    return { longitude: site.longitude, latitude: site.latitude };
  }

  getSiteDescription() {
    const site = this.getSite();
    if (!site) {
      return null;
    }

    return site.description;
  }

  getSiteBounds() {
    return this.getValue().siteBounds;
  }

  getAllTasks() {
    return this.tasksQuery.getAll();
  }

  getAllFinishedTasks(sortAsc = true) {
    return this.tasksQuery.getAll({
      filterBy: finishedTasksFilter,
      sortBy: sortAsc ? oldestToNewestTasksSorting : newestToOldestTasksSorting
    });
  }

  getAllFinishedTasksAndDesigns() {
    return [...this.getAllFinishedTasks(), ...this.designsQuery.getDesignsWithTerrain()];
  }

  // if taskId is falsy, we go over all current tasks
  getPrevTaskHadGCP(taskId?: string) {
    const tasks = this.getAllTasks();
    for (const task of tasks) {
      if (taskId && taskId === task.id) {
        break;
      }

      if (taskHasGCP(task)) {
        return true;
      }
    }

    return false;
  }

  getPrevTask(taskId: string, onlyFinished = false) {
    let prevTask: Task;
    const tasks = this.getAllTasks();
    for (const task of tasks) {
      if (taskId === task.id) {
        break;
      }

      if (!onlyFinished || finishedTasksFilter(task)) {
        prevTask = task;
      }
    }

    return prevTask;
  }

  getAllTasksWithGCP() {
    return this.tasksQuery.getAll({ filterBy: taskHasGCP, sortBy: newestToOldestTasksSorting });
  }

  canUpdateSiteSettings() {
    const tasksCount = this.getAllTasksCount();
    const designsCount = this.designsQuery.getAllDesignTypesCount();
    const entitiesCount = this.siteEntitiesQuery.getAllEntitiesCount();

    return tasksCount + designsCount + entitiesCount === 0;
  }

  private getAnalyticEntitiesObservable() {
    return combineLatest([this.siteEntitiesQuery.analyticsQuery.selectAll(), this.activeTask$]).pipe(map(([entities]) => entities));
  }

  getMapMarkersNotifications$() {
    return merge(
      this.siteEntitiesQuery.createMapNotificationsObserver(this.getAnalyticEntitiesObservable(), 'point'),
      this.siteEntitiesQuery.createMapNotificationsObserver(this.siteEntitiesQuery.measurementsQuery.selectAll(), 'point'),
      this.siteEntitiesQuery.createMapNotificationsObserver(this.siteEntitiesQuery.annotationsQuery.selectAll(), 'point')
    );
  }

  getMapPolygonsNotifications$() {
    return merge(
      this.siteEntitiesQuery.createMapNotificationsObserver(this.getAnalyticEntitiesObservable(), 'polygon'),
      this.siteEntitiesQuery.createMapNotificationsObserver(this.siteEntitiesQuery.measurementsQuery.selectAll(), 'polygon')
    );
  }

  getMapLineNotifications$() {
    return merge(
      this.siteEntitiesQuery.createMapNotificationsObserver(this.getAnalyticEntitiesObservable(), 'line'),
      this.siteEntitiesQuery.createMapNotificationsObserver(this.siteEntitiesQuery.measurementsQuery.selectAll(), 'line')
    );
  }

  getMapModelEditNotifications$() {
    return this.siteEntitiesQuery.createMapNotificationsObserver(this.siteEntitiesQuery.modelEditsQuery.selectAll(), 'modelEdit');
  }

  getTasksLoading() {
    return this.tasksQuery.getValue().loading;
  }

  getAllTasksCount() {
    return this.tasksQuery.getCount();
  }

  getFinishedTasksCount() {
    return this.tasksQuery.getCount(finishedTasksFilter);
  }

  getMapDiffTask() {
    return this.getValue().ui.mapDiffTask;
  }

  getSiteImageryLayer() {
    return this.getValue().ui.siteImageryLayer;
  }

  getOverlays() {
    return this.getValue().ui.overlays;
  }

  getOverlay(overlay: OverlaysEnum) {
    return this.getValue().ui.overlays[overlay];
  }

  getViewerCredentials() {
    return this.getValue().viewerUrlsCredentials;
  }

  getElevationOptions() {
    return this.getValue().ui.overlays[OverlaysEnum.ELEVATION].options;
  }

  getContourOptions() {
    return this.getValue().ui.overlays[OverlaysEnum.CONTOUR].options;
  }

  convertCoordinateToSiteCS({ longitude, latitude, height = 0 }: Cartographic) {
    const cs = this.getValue().site?.coordinateSystem;
    return convertCoordinateToSiteCS({ longitude, latitude, height }, cs);
  }

  convertCoordinateCSToPosition({ x, y, z = 0 }: { x: number; y: number; z?: number }) {
    const cs = this.getValue().site?.coordinateSystem;
    return convertCoordinateCSToPosition({ x, y, z }, cs);
  }

  getBaseSurfaceName(baseSurface: BaseSurface, addValueToCustomElevation = true) {
    if (!baseSurface || baseSurface.type === BaseSurfaceType.INTERPOLATED) {
      return 'Interpolated';
    } else if (baseSurface.type === BaseSurfaceType.MINELEVATION) {
      return 'Minimum Elevation';
    } else if (baseSurface.type === BaseSurfaceType.CUSTOMELEVATION) {
      if (addValueToCustomElevation) {
        const siteUnits = this.getSiteUnits();
        const unitsSign = getUnitSign(siteUnits);
        const elevation = roundTo(convertFromMeters(baseSurface.elevation, siteUnits));
        return `Custom Elevation (${elevation} ${unitsSign})`;
      } else {
        return 'Custom Elevation';
      }
    }

    if (baseSurface.type === BaseSurfaceType.TASK) {
      const task = this.getTask(baseSurface.id);
      if (task) {
        return task.flightDateLabel;
      }
    } else if (baseSurface.type === BaseSurfaceType.DESIGN) {
      const design = this.designsQuery.getDesign(baseSurface.id);
      if (design) {
        return design.name;
      }
    }

    return baseSurface.type;
  }

  isBaseSurfaceExist(baseSurface: BaseSurface) {
    if (!baseSurface) {
      return false;
    }
    if (baseSurface.type === 'DESIGN') {
      // Design must exist and have terrain
      const design = this.designsQuery.getDesign(baseSurface.id);
      return design && design.terrainReady;
    } else if (baseSurface.type === 'TASK') {
      // Task must exist and be in state success
      const task = this.getTask(baseSurface.id);
      if (!task || task.state !== TaskStateEnum.SUCCESS) {
        return false;
      }
    }

    return true;
  }

  getFlightWizardDialogId() {
    return this.getValue().ui.flightWizardDialogId;
  }

  getAllAvailableCalcModels(withActiveTask = true): TaskOrDesignInfo[] {
    let remainingTaskIds = this.getAllFinishedTasks().map(t => ({ id: t.id, missionFlightDate: t.missionFlightDate }));
    if (!withActiveTask) {
      const activeTaskId = this.getActiveTaskId();
      remainingTaskIds = remainingTaskIds.filter(task => task.id !== activeTaskId);
    }
    const tasksData: { id: string; type: 'TASK'; missionFlightDate: Date }[] = remainingTaskIds.map(task => ({ ...task, type: 'TASK' }));

    const designIds = this.designsQuery.getDesignsWithTerrain().map(design => design.id);
    const designsData: { id: string; type: 'DESIGN' }[] = designIds.map(id => ({ id, type: 'DESIGN' }));

    return [...tasksData, ...designsData];
  }

  getBaseSurfacesOptions(entityType: PolygonType) {
    const designs = this.designsQuery.getDesignsWithTerrain();

    let tasks = this.getAllFinishedTasks(false);

    const activeTaskId = this.getActiveTaskId();
    if (entityType === PolygonType.VOLUME) {
      // Remove current task from list
      tasks = tasks.filter(t => t.id !== activeTaskId);
    }

    let baseSurfaceOptions: BaseSurfaceOption[] = [
      { id: 'CUSTOM_ELEVATION', name: 'Custom Elevation', type: BaseSurfaceType.CUSTOMELEVATION },
      ...tasks.map(t => ({
        id: t.id,
        name: t.flightDateLabel,
        type: BaseSurfaceType.TASK
      })),
      ...designs.map(d => ({
        id: d.id,
        name: d.name,
        type: BaseSurfaceType.DESIGN
      }))
    ];

    if (entityType === PolygonType.VOLUME || entityType === PolygonType.POLYGON_DELTA_VOLUME) {
      baseSurfaceOptions = [
        { id: 'INTERPOLATED', name: 'Interpolated', type: BaseSurfaceType.INTERPOLATED },
        { id: 'MIN_ELEVATION', name: 'Minimum Elevation', type: BaseSurfaceType.MINELEVATION },
        ...baseSurfaceOptions
      ];
    }
    return baseSurfaceOptions;
  }

  getTabExpandedGroups(tab: TabEnum) {
    return this.getValue().ui.tabsExpandedGroups[tab];
  }

  getActiveTab() {
    return this.getValue().ui.activeTab;
  }

  isGroupExpanded(tab: TabEnum, groupId: string) {
    const expanded = this.getTabExpandedGroups(tab);
    return expanded?.has(groupId);
  }

  getAssociationByArtifact(artifact: ArtifactEnum) {
    return this.getSite().associations?.find(association => association.artifact === artifact);
  }
}
