import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Group, Layer } from '../../../detailed-site/state/detailed-site-entities/detailed-site-entities.model';
import { DetailedSiteEntitiesQuery } from '../../../detailed-site/state/detailed-site-entities/detailed-site-entities.query';
import { DEFAULT_COLOR } from '../../color-picker/color-picker.component';
import { duplicateNameValidator, getFieldErrorMessage, getFieldErrors } from '../../utils/form-utils';

export interface GroupLayerDetailsData {
  type: 'group' | 'layer';
  values?: Group | Layer;
  onSave: (value: Group | Layer) => void;
}

interface GroupLayerForm {
  name: FormControl<string>;
  description: FormControl<string>;
  color: FormControl<string>;
}

@Component({
  selector: 'group-layer-details',
  templateUrl: './group-layer-details.component.html',
  styleUrls: ['./group-layer-details.component.scss']
})
export class GroupLayerDetailsComponent implements OnInit {
  getFieldErrors = getFieldErrors;
  getFieldErrorMessage = getFieldErrorMessage;

  detailsForm: FormGroup<GroupLayerForm>;
  dialogHeader: string;

  constructor(
    private dialogRef: MatDialogRef<GroupLayerDetailsComponent>,
    private entitiesQuery: DetailedSiteEntitiesQuery,
    @Inject(MAT_DIALOG_DATA) public data: GroupLayerDetailsData,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    if (this.data.type === 'layer') {
      this.dialogHeader = this.isEditMode
        ? $localize`:@@shared.groupLayerDetails.dialogTitleEditLayer:Edit layer`
        : $localize`:@@shared.groupLayerDetails.dialogTitleNewLayer:New layer`;

      const layer = this.data.values as Layer;
      const layers = this.entitiesQuery
        .getAllLayers()
        .filter(l => l.id !== layer?.id)
        .map(l => l.name);
      this.detailsForm = this.fb.group({
        name: [layer ? layer.name : undefined, [Validators.required, duplicateNameValidator(layers)]],
        description: [layer ? layer.description : undefined],
        color: [layer ? layer.color : DEFAULT_COLOR, Validators.required]
      });
    } else {
      this.dialogHeader = this.isEditMode
        ? $localize`:@@shared.groupLayerDetails.dialogTitleEditGroup:Edit group`
        : $localize`:@@shared.groupLayerDetails.dialogTitleNewGroup:New group`;

      const group = this.data.values as Group;
      const groups = this.entitiesQuery
        .getAllGroups()
        .filter(g => g.id !== group?.id)
        .map(g => g.name);
      this.detailsForm = this.fb.group({
        name: [group ? group.name : undefined, [Validators.required, duplicateNameValidator(groups)]],
        description: [group ? group.description : undefined],
        color: [undefined]
      });
    }
  }

  submit() {
    if (this.detailsForm.valid && this.data.onSave) {
      const formValue = {
        ...this.data.values,
        ...this.detailsForm.value,
        name: this.detailsForm.value.name?.trim() || '',
        id: this.data.values ? this.data.values.id : null
      };
      this.data.onSave(formValue);
    }
    this.close();
  }

  get isEditMode() {
    return !!this.data.values?.id;
  }

  close() {
    this.dialogRef.close();
  }
}
