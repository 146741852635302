<dialog-layout (closeDialog)="close()" [header]="dialogHeader">
  <table [class.desktop]="isDesktop">
    <thead>
      <tr>
        <th i18n="@@shared.groupsLayersManager.nameColumnLabel">Name</th>
        <th i18n="@@shared.groupsLayersManager.descriptionColumnLabel">Description</th>
        <th class="layer-column" *ngIf="data.type === 'layer'" i18n="@@shared.groupsLayersManager.colorColumnLabel">Color</th>
        <th class="menu-column">
          <button mat-button class="light-btn" (click)="create()">+ <ng-container i18n="@@general.actionButtons.add">Add</ng-container></button>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let value of data.values$ | async">
        <td>{{ value.name }}</td>
        <td>{{ value.description }}</td>
        <td class="layer-column" *ngIf="data.type === 'layer'">
          <color-picker [disabled]="true" [color]="value.color"></color-picker>
        </td>
        <td class="menu-column">
          <button *ngIf="value.id" mat-icon-button [matMenuTriggerFor]="menu" (menuOpened)="menuOpened(value)" (menuClosed)="menuClosed()">
            <mat-icon>more_vert</mat-icon>
          </button>
        </td>
      </tr>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="edit()">
          <mat-icon>edit</mat-icon>
          <span i18n="@@general.actionButtons.edit">Edit</span>
        </button>
        <button mat-menu-item (click)="delete()">
          <mat-icon>delete</mat-icon>
          <span i18n="@@general.actionButtons.remove">Remove</span>
        </button>
      </mat-menu>
    </tbody>
  </table>
</dialog-layout>
