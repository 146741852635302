import { AccessLevelEnum } from './auth.utils';

interface ResourcePermission {
  create: AccessLevelEnum;
  read: AccessLevelEnum;
  update: AccessLevelEnum;
  delete: AccessLevelEnum;
}

function createResourcePermission(resourcePermissions: Partial<ResourcePermission> = {}) {
  const permissions: ResourcePermission = {
    create: AccessLevelEnum.VIEWONLY,
    read: AccessLevelEnum.VIEWONLY,
    update: AccessLevelEnum.VIEWONLY,
    delete: AccessLevelEnum.VIEWONLY,
    ...resourcePermissions
  };
  return Object.freeze(permissions);
}

export interface ReportPermissions extends ResourcePermission {
  download: AccessLevelEnum;
}

const defaultReportPermissions = {
  read: AccessLevelEnum.BASICUSER,
  create: AccessLevelEnum.ANALYTICSUSER,
  update: AccessLevelEnum.ANALYTICSUSER,
  delete: AccessLevelEnum.ANALYTICSUSER,
  download: AccessLevelEnum.ANALYTICSUSER
};

function createReportPermisssions(overridedReportPermissions: Partial<ReportPermissions> = {}) {
  const permissions: ReportPermissions = {
    ...defaultReportPermissions,
    ...overridedReportPermissions
  };

  return Object.freeze(permissions);
}

const PERMISSIONS = Object.freeze({
  configRead: AccessLevelEnum.VIEWONLY,
  viewerCredentialsRead: AccessLevelEnum.VIEWONLY,
  sites: createResourcePermission({
    create: AccessLevelEnum.TENANTADMIN,
    update: AccessLevelEnum.TENANTADMIN,
    delete: AccessLevelEnum.TENANTADMIN
  }),
  sitesUpdateSimple: AccessLevelEnum.BASICUSER, // For updating site center or CS on first flight
  siteGroups: createResourcePermission({
    create: AccessLevelEnum.TENANTADMIN,
    update: AccessLevelEnum.TENANTADMIN,
    delete: AccessLevelEnum.TENANTADMIN
  }),
  siteCustomProperties: createResourcePermission({
    create: AccessLevelEnum.TENANTADMIN,
    update: AccessLevelEnum.TENANTADMIN,
    delete: AccessLevelEnum.TENANTADMIN
  }),
  flights: createResourcePermission({
    create: AccessLevelEnum.BASICUSER,
    update: AccessLevelEnum.BASICUSER,
    delete: AccessLevelEnum.TENANTADMIN
  }),
  importModels: AccessLevelEnum.BASICUSER,
  flightLogs: createResourcePermission({
    create: null, // Not used,
    update: null, // Not used,
    delete: null // Not used
  }),

  // Reports
  crossSectionReports: createReportPermisssions(),
  gradeCheckingReports: createReportPermisssions(),
  gridHeatmapReports: createReportPermisssions(),
  waterFlowReports: createReportPermisssions(),

  images: createResourcePermission({
    create: AccessLevelEnum.BASICUSER,
    update: null, // Doesn't exist
    delete: AccessLevelEnum.BASICUSER
  }),
  approveOperatorImages: AccessLevelEnum.BASICUSER,
  gcps: createResourcePermission({
    create: AccessLevelEnum.BASICUSER,
    update: AccessLevelEnum.BASICUSER,
    delete: AccessLevelEnum.BASICUSER
  }),
  hints: createResourcePermission({
    create: AccessLevelEnum.BASICUSER,
    update: AccessLevelEnum.BASICUSER,
    delete: AccessLevelEnum.BASICUSER
  }),
  runAIGCPMarking: AccessLevelEnum.BASICUSER,
  checkAccuracy: AccessLevelEnum.BASICUSER,
  autoImprove: AccessLevelEnum.BASICUSER,
  generateModel: AccessLevelEnum.BASICUSER,
  measurements: createResourcePermission({
    create: AccessLevelEnum.BASICUSER,
    update: AccessLevelEnum.BASICUSER,
    delete: AccessLevelEnum.BASICUSER
  }),
  analytics: createResourcePermission({
    create: AccessLevelEnum.ANALYTICSUSER,
    update: AccessLevelEnum.ANALYTICSUSER,
    delete: AccessLevelEnum.ANALYTICSUSER
  }),
  modelEdits: createResourcePermission({
    create: AccessLevelEnum.BASICUSER,
    update: AccessLevelEnum.BASICUSER,
    delete: AccessLevelEnum.BASICUSER
  }),
  annotations: createResourcePermission({
    create: AccessLevelEnum.ANALYTICSUSER,
    update: AccessLevelEnum.ANALYTICSUSER,
    delete: AccessLevelEnum.ANALYTICSUSER
  }),
  annotationNotes: createResourcePermission({
    create: AccessLevelEnum.ANALYTICSUSER,
    update: null, // Doesn't exist
    delete: null // Doesn't exist
  }),
  annotationFiles: createResourcePermission({
    create: AccessLevelEnum.ANALYTICSUSER,
    update: AccessLevelEnum.ANALYTICSUSER,
    delete: AccessLevelEnum.ANALYTICSUSER
  }),
  drawings: createResourcePermission({
    create: AccessLevelEnum.ANALYTICSUSER,
    update: AccessLevelEnum.ANALYTICSUSER,
    delete: AccessLevelEnum.ANALYTICSUSER
  }),
  annotationData: createResourcePermission({
    create: AccessLevelEnum.ANALYTICSUSER,
    update: AccessLevelEnum.ANALYTICSUSER,
    delete: AccessLevelEnum.ANALYTICSUSER
  }),
  groups: createResourcePermission({
    create: AccessLevelEnum.BASICUSER,
    update: AccessLevelEnum.BASICUSER,
    delete: AccessLevelEnum.BASICUSER
  }),
  layers: createResourcePermission({
    create: AccessLevelEnum.BASICUSER,
    update: AccessLevelEnum.BASICUSER,
    delete: AccessLevelEnum.BASICUSER
  }),
  designs: createResourcePermission({
    create: AccessLevelEnum.ANALYTICSUSER,
    update: AccessLevelEnum.ANALYTICSUSER,
    delete: AccessLevelEnum.ANALYTICSUSER
  }),
  designLayerProperties: createResourcePermission({
    create: AccessLevelEnum.ANALYTICSUSER,
    update: AccessLevelEnum.ANALYTICSUSER,
    delete: AccessLevelEnum.ANALYTICSUSER
  }),
  roadDesigns: createResourcePermission({
    create: AccessLevelEnum.ANALYTICSUSER,
    update: AccessLevelEnum.ANALYTICSUSER,
    delete: AccessLevelEnum.ANALYTICSUSER
  }),
  designCategories: createResourcePermission({
    create: AccessLevelEnum.ANALYTICSUSER,
    update: AccessLevelEnum.ANALYTICSUSER,
    delete: AccessLevelEnum.ANALYTICSUSER
  }),
  designAIChat: createResourcePermission({
    create: AccessLevelEnum.ANALYTICSUSER,
    update: AccessLevelEnum.ANALYTICSUSER,
    delete: AccessLevelEnum.ANALYTICSUSER
  }),
  users: createResourcePermission({
    create: AccessLevelEnum.TENANTADMIN,
    read: AccessLevelEnum.TENANTADMIN,
    update: AccessLevelEnum.TENANTADMIN,
    delete: AccessLevelEnum.TENANTADMIN
  }),
  usersReadSimple: AccessLevelEnum.VIEWONLY, // For displaying tenant users list
  teams: createResourcePermission({
    create: AccessLevelEnum.TENANTADMIN,
    update: AccessLevelEnum.TENANTADMIN,
    delete: AccessLevelEnum.TENANTADMIN
  }),
  downloads: AccessLevelEnum.BASICUSER,
  notifications: createResourcePermission({
    create: null, // Not used
    delete: null // Not used
  }),
  announcements: createResourcePermission({
    create: null, // Not used
    delete: null // Not used
  }),
  projectPlans: createResourcePermission({
    read: AccessLevelEnum.ANALYTICSUSER,
    create: AccessLevelEnum.ANALYTICSUSER,
    update: AccessLevelEnum.ANALYTICSUSER,
    delete: AccessLevelEnum.TENANTADMIN
  }),
  activities: createResourcePermission({
    read: AccessLevelEnum.ANALYTICSUSER,
    create: AccessLevelEnum.ANALYTICSUSER, // Standalone
    update: AccessLevelEnum.ANALYTICSUSER,
    delete: AccessLevelEnum.ANALYTICSUSER // Standalone
  }),
  activityMeasurements: createResourcePermission({
    read: AccessLevelEnum.ANALYTICSUSER,
    create: AccessLevelEnum.ANALYTICSUSER,
    update: AccessLevelEnum.ANALYTICSUSER,
    delete: AccessLevelEnum.ANALYTICSUSER
  }),
  resourceLinks: createResourcePermission({
    read: AccessLevelEnum.VIEWONLY,
    create: null, // Not used (using update instead)
    update: AccessLevelEnum.BASICUSER,
    delete: AccessLevelEnum.BASICUSER
  }),
  designIntegration: AccessLevelEnum.TENANTADMIN,
  synchronizedDesigns: createResourcePermission({
    read: AccessLevelEnum.VIEWONLY,
    create: null, // Not used (using designIntegration permission for sync instead)
    update: AccessLevelEnum.ANALYTICSUSER,
    delete: AccessLevelEnum.ANALYTICSUSER
  })
});

export default PERMISSIONS;
