<dialog-layout [header]="team ? 'Edit team' : 'Create new team'" (closeDialog)="close()">
  <div class="column full-container dialog-content">
    <form [formGroup]="teamForm" class="column team-form">
      <div class="row field-row">
        <label class="field-label">Name *</label>
        <input formControlName="name" class="field-input-control" />
      </div>
      <div class="row field-row">
        <label class="field-label">Description</label>
        <textarea formControlName="description" class="field-input-control"></textarea>
      </div>
      <name-list
        class="full-container users-container"
        title="Users"
        subtitle="Users in this team"
        [addNewButtonCaption]="allowAddUser ? 'Create & associate new user' : ''"
        associateButtonCaption="Associate user"
        placeholder="Select users to associate"
        formControlName="users"
        [items]="teamsQuery.users$ | async"
        (addNewClick)="addNewUser()"
      ></name-list>
    </form>
  
    <div class="row align-end bottom-row">
      <button mat-button type="button" class="light-btn" (click)="close()">{{team ? 'Cancel' : 'Discard'}}</button>
      <button mat-flat-button type="submit" [disabled]="!teamForm.valid" (click)="submit()" class="dark-btn">{{team ? 'Save' : 'Create'}}</button>
    </div>
  </div>
</dialog-layout>