export const ZOOM_PLUGIN_DEFAULT_OPTIONS = Object.freeze({
  pan: {
    enabled: true,
    mode: 'xy' as any,
    modifierKey: 'ctrl' as const
  },
  zoom: {
    mode: 'xy' as const,
    wheel: {
      enabled: true
    },
    pinch: {
      enabled: true
    },
    drag: {
      enabled: true,
      borderColor: 'rgb(54, 162, 235)',
      borderWidth: 1,
      backgroundColor: 'rgba(54, 162, 235, 0.3)'
    }
  }
});
