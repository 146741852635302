<dialog-layout (closeDialog)="close()" [header]="dialogHeader">
  <div class="container column full-container">
    <label class="space-below" *ngIf="data.openedFrom === 'GCP_RESIDUALS'; else notResidualsSubtitle" i18n="@@shared.coordinateSystemModifier.subtitleGCPResiduals">
      The residuals of the horizontal coordinates are too high. Here are some suggestions that might fix the problem:
    </label>
    <ng-template #notResidualsSubtitle>
      <ng-container *ngIf="!siteCoordinateSystem; else hasSiteCS">
        <label class="space-below" i18n="@@shared.coordinateSystemModifier.subtitleNoSiteCS">
          It looks like this site doesn't have a coordinate system, which is required for the model generation process.
        </label>
      </ng-container>
      <ng-template #hasSiteCS [ngSwitch]="data.openedFrom">
        <label class="space-below" *ngSwitchCase="'IMAGES'" i18n="@@shared.coordinateSystemModifier.subtitleImages">
          It looks like this site's coordinate system doesn't fit the location of the images you just uploaded.
        </label>
        <label class="space-below" *ngSwitchCase="'GCP'" i18n="@@shared.coordinateSystemModifier.subtitleGCP">
          It looks like this site's coordinate system doesn't fit the location of the GCPs you just uploaded.
        </label>
        <label class="space-below" *ngSwitchCase="'IMPORT_MODEL'" i18n="@@shared.coordinateSystemModifier.subtitleImportModel">
          It looks like this site's coordinate system doesn't allow to import a model.
        </label>
      </ng-template>
      <label class="space-below" i18n="@@shared.coordinateSystemModifier.subtitleSelectOption">You can select one of the following options:</label>
    </ng-template>

    <mat-radio-group class="column" (change)="selectedOption = $event.value" [value]="selectedOption">
      <div *ngFor="let optionCategory of optionCategories" class="column space-below category-container">
        <div class="category-title" *ngIf="optionCategories.length > 1">{{ optionCategory.name }}</div>
        <mat-radio-button color="primary" *ngFor="let option of optionCategory.options; let i = index" [value]="option" [class.space-below]="optionCategory.options.length > 1 && i !== optionCategory.options.length - 1">
          <ng-container [ngSwitch]="option">
            <div class="column" *ngSwitchCase="CoordinateSystemDialogOption.RECOMMENDED">
              <ng-container *ngIf="recommendedCount === 1">
                <ng-container i18n="@@shared.coordinateSystemModifier.coordinateSystemDialogRecommendedSingle">Select this recommended coordinate system</ng-container>
                <label class="single-coordinate-system" *ngIf="selectedOption === CoordinateSystemDialogOption.RECOMMENDED">
                  {{ selectedRecommended.value.name }} ({{ selectedRecommended.value.code }})
                </label>
              </ng-container>
  
              <ng-container *ngIf="recommendedCount > 1">
                <ng-container i18n="@@shared.coordinateSystemModifier.coordinateSystemDialogRecommendedMultiple">Select one of the following recommended coordinate systems</ng-container>
                <coordinate-system-select
                  [class.hidden]="selectedOption !== CoordinateSystemDialogOption.RECOMMENDED"
                  class="system-select"
                  [coordinateSystems]="data.recommendedCoordinateSystems"
                  [formControl]="selectedRecommended"
                ></coordinate-system-select>
              </ng-container>
            </div>
  
            <ng-container class="column" *ngSwitchCase="CoordinateSystemDialogOption.SUPPORT" i18n="@@shared.coordinateSystemModifier.coordinateSystemDialogSupport">
              Write an email to our support to help you find the correct coordinate system for your site
            </ng-container>
  
            <ng-container class="column" *ngSwitchCase="CoordinateSystemDialogOption.LOCAL" i18n="@@shared.coordinateSystemModifier.coordinateSystemDialogLocalCS">Use a local coordinate system</ng-container>
  
            <ng-container class="column" *ngSwitchCase="CoordinateSystemDialogOption.DELETE_GCPS">
              <ng-container i18n="@@shared.coordinateSystemModifier.coordinateSystemDialogDeleteGCPs">Delete all existing GCPs</ng-container>
              (<ng-container i18n="@@shared.coordinateSystemModifier.coordinateSystemDialogDeleteGCPsCannotBeUndone">This cannot be undone</ng-container>)
            </ng-container>
  
            <div class="column" *ngSwitchCase="CoordinateSystemDialogOption.MANUAL">
              <ng-container i18n="@@shared.coordinateSystemModifier.coordinateSystemDialogManual">Select a different coordinate system manually</ng-container>
              <coordinate-system-select
                [class.hidden]="selectedOption !== CoordinateSystemDialogOption.MANUAL"
                class="system-select"
                [coordinateSystems]="coordinateSystems"
                [loading]="coordinateSystemsLoading"
                [formControl]="selectedManual"
                [groupByFn]="groupByFn"
              ></coordinate-system-select>
            </div>
          </ng-container>
        </mat-radio-button>
      </div>
    </mat-radio-group>
  </div>

  <div class="bottom-row row justify-end">
    <button mat-button class="light-btn" (click)="close()" i18n="@@general.actionButtons.cancel">Cancel</button>
    <button mat-flat-button class="dark-btn" (click)="continue()" [disabled]="continueDisabled" i18n="@@general.actionButtons.continue">Continue</button>
  </div>
</dialog-layout>
