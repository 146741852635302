/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */
/* Geodesy tools for an ellipsoidal earth model                       (c) Chris Veness 2005-2016  */
/*                                                                                   MIT Licence  */
/* www.movable-type.co.uk/scripts/latlong-convert-coords.html                                     */
/* www.movable-type.co.uk/scripts/geodesy/docs/module-latlon-ellipsoidal.html                     */
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */

'use strict';

if (typeof module != 'undefined' && module.exports) var Vector3d = require('./vector3d.js'); // ≡ import Vector3d from 'vector3d.js'
if (typeof module != 'undefined' && module.exports) var Dms = require('./dms.js'); // ≡ import Dms from 'dms.js'

/**
 * Library of geodesy functions for operations on an ellipsoidal earth model.
 *
 * Includes ellipsoid parameters and datums for different coordinate systems, and methods for
 * converting between them and to cartesian coordinates.
 *
 * q.v. Ordnance Survey ‘A guide to coordinate systems in Great Britain’ Section 6
 * www.ordnancesurvey.co.uk/docs/support/guide-coordinate-systems-great-britain.pdf.
 *
 * @module   latlon-ellipsoidal
 * @requires dms
 */

/**
 * Creates lat/lon (polar) point with latitude & longitude values, on a specified datum.
 *
 * @constructor
 * @param {number}       lat - Geodetic latitude in degrees.
 * @param {number}       lon - Longitude in degrees.
 * @param {LatLon.datum} [datum=WGS84] - Datum this point is defined within.
 *
 * @example
 *     var p1 = new LatLon(51.4778, -0.0016, LatLon.datum.WGS84);
 */
function LatLon(lat, lon, datum) {
  // allow instantiation without 'new'
  if (!(this instanceof LatLon)) return new LatLon(lat, lon, datum);
  if (datum === undefined) datum = LatLon.datum.WGS84;
  this.lat = Number(lat);
  this.lon = Number(lon);
  this.datum = datum;
}

/**
 * Ellipsoid parameters; major axis (a), minor axis (b), and flattening (f) for each ellipsoid.
 */
LatLon.ellipsoid = {
  WGS84: {
    a: 6378137,
    b: 6356752.314245,
    f: 1 / 298.257223563
  },
  Airy1830: {
    a: 6377563.396,
    b: 6356256.909,
    f: 1 / 299.3249646
  },
  AiryModified: {
    a: 6377340.189,
    b: 6356034.448,
    f: 1 / 299.3249646
  },
  Bessel1841: {
    a: 6377397.155,
    b: 6356078.962818,
    f: 1 / 299.1528128
  },
  Clarke1866: {
    a: 6378206.4,
    b: 6356583.8,
    f: 1 / 294.978698214
  },
  Clarke1880IGN: {
    a: 6378249.2,
    b: 6356515.0,
    f: 1 / 293.466021294
  },
  GRS80: {
    a: 6378137,
    b: 6356752.314140,
    f: 1 / 298.257222101
  },
  Intl1924: {
    a: 6378388,
    b: 6356911.946,
    f: 1 / 297
  },
  // aka Hayford
  WGS72: {
    a: 6378135,
    b: 6356750.5,
    f: 1 / 298.26
  }
};

/**
 * Datums; with associated ellipsoid, and Helmert transform parameters to convert from WGS 84 into
 * given datum.
 *
 * Note that precision of various datums will vary, and WGS-84 (original) is not defined to be
 * accurate to better than ±1 metre. No transformation should be assumed to be accurate to better
 * than a meter; for many datums somewhat less.
 */
LatLon.datum = {
  // transforms: t in metres, s in ppm, r in arcseconds                    tx       ty        tz       s        rx       ry       rz
  ED50: {
    ellipsoid: LatLon.ellipsoid.Intl1924,
    transform: [89.5, 93.8, 123.1, -1.2, 0.0, 0.0, 0.156]
  },
  Irl1975: {
    ellipsoid: LatLon.ellipsoid.AiryModified,
    transform: [-482.530, 130.596, -564.557, -8.150, -1.042, -0.214, -0.631]
  },
  NAD27: {
    ellipsoid: LatLon.ellipsoid.Clarke1866,
    transform: [8, -160, -176, 0, 0, 0, 0]
  },
  NAD83: {
    ellipsoid: LatLon.ellipsoid.GRS80,
    transform: [1.004, -1.910, -0.515, -0.0015, 0.0267, 0.00034, 0.011]
  },
  NTF: {
    ellipsoid: LatLon.ellipsoid.Clarke1880IGN,
    transform: [168, 60, -320, 0, 0, 0, 0]
  },
  OSGB36: {
    ellipsoid: LatLon.ellipsoid.Airy1830,
    transform: [-446.448, 125.157, -542.060, 20.4894, -0.1502, -0.2470, -0.8421]
  },
  Potsdam: {
    ellipsoid: LatLon.ellipsoid.Bessel1841,
    transform: [-582, -105, -414, -8.3, 1.04, 0.35, -3.08]
  },
  TokyoJapan: {
    ellipsoid: LatLon.ellipsoid.Bessel1841,
    transform: [148, -507, -685, 0, 0, 0, 0]
  },
  WGS72: {
    ellipsoid: LatLon.ellipsoid.WGS72,
    transform: [0, 0, -4.5, -0.22, 0, 0, 0.554]
  },
  WGS84: {
    ellipsoid: LatLon.ellipsoid.WGS84,
    transform: [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0]
  }
};
/* sources:
 * - ED50:          www.gov.uk/guidance/oil-and-gas-petroleum-operations-notices#pon-4
 * - Irl1975:       www.osi.ie/wp-content/uploads/2015/05/transformations_booklet.pdf
 *   ... note: many sources have opposite sign to rotations - to be checked!
 * - NAD27:         en.wikipedia.org/wiki/Helmert_transformation
 * - NAD83: (2009); www.uvm.edu/giv/resources/WGS84_NAD83.pdf
 *   ... note: functionally ≡ WGS84 - if you *really* need to convert WGS84<->NAD83, you need more knowledge than this!
 * - NTF:           Nouvelle Triangulation Francaise geodesie.ign.fr/contenu/fichiers/Changement_systeme_geodesique.pdf
 * - OSGB36:        www.ordnancesurvey.co.uk/docs/support/guide-coordinate-systems-great-britain.pdf
 * - Potsdam:       kartoweb.itc.nl/geometrics/Coordinate%20transformations/coordtrans.html
 * - TokyoJapan:    www.geocachingtoolbox.com?page=datumEllipsoidDetails
 * - WGS72:         www.icao.int/safety/pbn/documentation/eurocontrol/eurocontrol wgs 84 implementation manual.pdf
 *
 * more transform parameters are available from earth-info.nga.mil/GandG/coordsys/datums/NATO_DT.pdf,
 * www.fieldenmaps.info/cconv/web/cconv_params.js
 */

/**
 * Converts ‘this’ lat/lon coordinate to new coordinate system.
 *
 * @param   {LatLon.datum} toDatum - Datum this coordinate is to be converted to.
 * @returns {LatLon} This point converted to new datum.
 *
 * @example
 *     var pWGS84 = new LatLon(51.4778, -0.0016, LatLon.datum.WGS84);
 *     var pOSGB = pWGS84.convertDatum(LatLon.datum.OSGB36); // 51.4773°N, 000.0000°E
 */
LatLon.prototype.convertDatum = function (toDatum) {
  var oldLatLon = this;
  var transform = null;
  if (oldLatLon.datum == LatLon.datum.WGS84) {
    // converting from WGS 84
    transform = toDatum.transform;
  }
  if (toDatum == LatLon.datum.WGS84) {
    // converting to WGS 84; use inverse transform (don't overwrite original!)
    transform = [];
    for (var p = 0; p < 7; p++) transform[p] = -oldLatLon.datum.transform[p];
  }
  if (transform == null) {
    // neither this.datum nor toDatum are WGS84: convert this to WGS84 first
    oldLatLon = this.convertDatum(LatLon.datum.WGS84);
    transform = toDatum.transform;
  }
  var oldCartesian = oldLatLon.toCartesian(); // convert polar to cartesian...
  var newCartesian = oldCartesian.applyTransform(transform); // ...apply transform...
  var newLatLon = newCartesian.toLatLonE(toDatum); // ...and convert cartesian to polar

  return newLatLon;
};

/**
 * Converts ‘this’ point from (geodetic) latitude/longitude coordinates to (geocentric) cartesian
 * (x/y/z) coordinates.
 *
 * @returns {Vector3d} Vector pointing to lat/lon point, with x, y, z in metres from earth centre.
 */
LatLon.prototype.toCartesian = function () {
  var φ = this.lat.toRadians(),
    λ = this.lon.toRadians();
  var h = 0; // height above ellipsoid - not currently used
  var a = this.datum.ellipsoid.a,
    f = this.datum.ellipsoid.f;
  var sinφ = Math.sin(φ),
    cosφ = Math.cos(φ);
  var sinλ = Math.sin(λ),
    cosλ = Math.cos(λ);
  var eSq = 2 * f - f * f; // 1st eccentricity squared ≡ (a²-b²)/a²
  var ν = a / Math.sqrt(1 - eSq * sinφ * sinφ); // radius of curvature in prime vertical

  var x = (ν + h) * cosφ * cosλ;
  var y = (ν + h) * cosφ * sinλ;
  var z = (ν * (1 - eSq) + h) * sinφ;
  var point = new Vector3d(x, y, z);
  return point;
};

/**
 * Converts ‘this’ (geocentric) cartesian (x/y/z) point to (ellipsoidal geodetic) latitude/longitude
 * coordinates on specified datum.
 *
 * Uses Bowring’s (1985) formulation for μm precision in concise form.
 *
 * @param {LatLon.datum.transform} datum - Datum to use when converting point.
 */
Vector3d.prototype.toLatLonE = function (datum) {
  var x = this.x,
    y = this.y,
    z = this.z;
  var a = datum.ellipsoid.a,
    b = datum.ellipsoid.b,
    f = datum.ellipsoid.f;
  var e2 = 2 * f - f * f; // 1st eccentricity squared ≡ (a²-b²)/a²
  var ε2 = e2 / (1 - e2); // 2nd eccentricity squared ≡ (a²-b²)/b²
  var p = Math.sqrt(x * x + y * y); // distance from minor axis
  var R = Math.sqrt(p * p + z * z); // polar radius

  // parametric latitude (Bowring eqn 17, replacing tanβ = z·a / p·b)
  var tanβ = b * z / (a * p) * (1 + ε2 * b / R);
  var sinβ = tanβ / Math.sqrt(1 + tanβ * tanβ);
  var cosβ = sinβ / tanβ;

  // geodetic latitude (Bowring eqn 18: tanφ = z+ε²bsin³β / p−e²cos³β)
  var φ = isNaN(cosβ) ? 0 : Math.atan2(z + ε2 * b * sinβ * sinβ * sinβ, p - e2 * a * cosβ * cosβ * cosβ);

  // longitude
  var λ = Math.atan2(y, x);

  // height above ellipsoid (Bowring eqn 7) [not currently used]
  var sinφ = Math.sin(φ),
    cosφ = Math.cos(φ);
  var ν = a / Math.sqrt(1 - e2 * sinφ * sinφ); // length of the normal terminated by the minor axis
  var h = p * cosφ + z * sinφ - a * a / ν;
  var point = new LatLon(φ.toDegrees(), λ.toDegrees(), datum);
  return point;
};

/**
 * Applies Helmert transform to ‘this’ point using transform parameters t.
 *
 * @private
 * @param   {number[]} t - Transform to apply to this point.
 * @returns {Vector3} Transformed point.
 */
Vector3d.prototype.applyTransform = function (t) {
  // this point
  var x1 = this.x,
    y1 = this.y,
    z1 = this.z;

  // transform parameters
  var tx = t[0]; // x-shift
  var ty = t[1]; // y-shift
  var tz = t[2]; // z-shift
  var s1 = t[3] / 1e6 + 1; // scale: normalise parts-per-million to (s+1)
  var rx = (t[4] / 3600).toRadians(); // x-rotation: normalise arcseconds to radians
  var ry = (t[5] / 3600).toRadians(); // y-rotation: normalise arcseconds to radians
  var rz = (t[6] / 3600).toRadians(); // z-rotation: normalise arcseconds to radians

  // apply transform
  var x2 = tx + x1 * s1 - y1 * rz + z1 * ry;
  var y2 = ty + x1 * rz + y1 * s1 - z1 * rx;
  var z2 = tz - x1 * ry + y1 * rx + z1 * s1;
  return new Vector3d(x2, y2, z2);
};

/**
 * Returns a string representation of ‘this’ point, formatted as degrees, degrees+minutes, or
 * degrees+minutes+seconds.
 *
 * @param   {string} [format=dms] - Format point as 'd', 'dm', 'dms'.
 * @param   {number} [dp=0|2|4] - Number of decimal places to use - default 0 for dms, 2 for dm, 4 for d.
 * @returns {string} Comma-separated latitude/longitude.
 */
LatLon.prototype.toString = function (format, dp) {
  return Dms.toLat(this.lat, format, dp) + ', ' + Dms.toLon(this.lon, format, dp);
};

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */

/** Extend Number object with method to convert numeric degrees to radians */
if (Number.prototype.toRadians === undefined) {
  Number.prototype.toRadians = function () {
    return this * Math.PI / 180;
  };
}

/** Extend Number object with method to convert radians to numeric (signed) degrees */
if (Number.prototype.toDegrees === undefined) {
  Number.prototype.toDegrees = function () {
    return this * 180 / Math.PI;
  };
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */
if (typeof module != 'undefined' && module.exports) module.exports = LatLon, module.exports.Vector3d = Vector3d; // ≡ export { LatLon as default, Vector3d }