<div class="navbar-wrap">
  <feature-hint [place]="FeatureHintPlace.MainMenu" [dotOffsetX]="-9" [dotOffsetY]="9">
    <button
      mat-icon-button
      *ngIf="!isSimpleMode"
      class="icon-button"
      [matMenuTriggerFor]="mainMenu"
      (menuOpened)="menuOpen = true"
      (menuClosed)="menuOpen = false"
    >
      <mat-icon svgIcon="menu"></mat-icon>
    </button>
  </feature-hint>
  <mat-menu #mainMenu="matMenu" class="dark-menu main-menu">
    <ng-container *ngFor="let item of menuItems">
      <ng-container *ngIf="!item.hidden">
        <button
          mat-menu-item
          *ngIf="[MenuItemType.BUTTON, MenuItemType.SUBMENU].includes(item.type)"
          (click)="!item.disabled && menuItemClick(item)"
          [disabled]="item.disabled"
          [matMenuTriggerFor]="item.type === MenuItemType.SUBMENU ? submenu : null"
          #ref="ref"
          withElementRef
        >
          <mat-icon [svgIcon]="item.icon"></mat-icon>
          <feature-hint
            [place]="MENU_ITEMS_FEATURE_HINT_PLACES[item.key]"
            [hintParentElement]="ref.elementRef.nativeElement"
            [dotOffsetX]="5"
            [dotOffsetY]="1"
          >
            <span>{{ item.caption }}</span>
          </feature-hint>
        </button>

        <mat-menu #submenu="matMenu" class="dark-menu sub-menu">
          <button
            mat-menu-item
            *ngFor="let subitem of item.subitems"
            (click)="submenuItemClick(item, subitem)"
            [class.selected]="subitem.selected"
            [disabled]="subitem.disabled"
            [title]="subitem.title"
          >
            <span class="row space-between">
              <span>{{ subitem.caption }}</span>
              <mat-icon class="selected-icon" *ngIf="subitem.selected">checked</mat-icon>
            </span>
          </button>
        </mat-menu>

        <a
          mat-menu-item
          *ngIf="[MenuItemType.LINK, MenuItemType.EXTERNAL_LINK].includes(item.type) && item.url"
          [target]="item.type === MenuItemType.EXTERNAL_LINK ? '_blank' : ''"
          [rel]="item.type === MenuItemType.EXTERNAL_LINK ? 'noopener noreferrer' : ''"
          [href]="item.url"
          [disabled]="item.disabled"
        >
          <mat-icon [svgIcon]="item.icon"></mat-icon>
          <span>{{ item.caption }}</span>
          <mat-icon *ngIf="item.type === MenuItemType.EXTERNAL_LINK" class="external" title="External link" i18n-title="@@shared.navbar.externalLinkTitle">open_in_new</mat-icon>
        </a>
        <mat-divider *ngIf="item.type === MenuItemType.DIVIDER"></mat-divider>
      </ng-container>
    </ng-container>
  </mat-menu>

  <a class="row logo-container link" title="Home" i18n-title="@@shared.navbar.logoTitle" href="/">
    <img [src]="navbarLogo" alt="logo" class="logo" />
  </a>

  <div class="divider"></div>

  <div class="row full-container">
    <button mat-icon-button class="icon-button" title="Back" i18n-title="@@shared.navbar.backTitle" type="button" (click)="backButtonClicked.emit()" *ngIf="showBackButton">
      <mat-icon svgIcon="back"></mat-icon>
    </button>
    <label class="full-container navbar-title" *ngIf="title">
      {{ title }}
    </label>
    <ng-content></ng-content>
  </div>

  <div *ngIf="!isSimpleMode && showNotificationDivider" class="divider"></div>
  <ng-container *ngIf="!isSimpleMode && userRole === UserRole.ACCOUNT_USER">
    <button
      *ngIf="announcementsQuery.announcements$ | async | isDefined"
      mat-icon-button
      [title]="menuButtonCountTitle(announcementsQuery.unseenCount$ | async, 'announcements')"
      class="icon-button"
      [matMenuTriggerFor]="announcementsMenu.menu"
      [disabled]="announcementsQuery.selectLoading() | async"
      (menuOpened)="announcementsMenuOpened()"
      (menuClosed)="announcementsOpen = false"
    >
      <mat-icon
        [svgIcon]="announcementsOpen ? 'announcement-full' : 'announcement'"
        [matBadge]="(announcementsQuery.unseenCount$ | async) || undefined"
        matBadgeColor="warn"
        aria-hidden="false"
      ></mat-icon>
    </button>
    <announcement-menu #announcementsMenu [announcements]="announcementsQuery.announcements$ | async"></announcement-menu>
  </ng-container>

  <button
    *ngIf="!isSimpleMode && userRole === UserRole.ACCOUNT_USER"
    mat-icon-button
    [title]="menuButtonCountTitle(notificationsQuery.unseenCount$ | async, 'notifications')"
    class="icon-button"
    [matMenuTriggerFor]="notificationsMenu.menu"
    [disabled]="notificationsQuery.selectLoading() | async"
    (menuOpened)="notificationsMenuOpened()"
    (menuClosed)="notificationsOpen = false"
  >
    <mat-icon
      [svgIcon]="notificationsOpen ? 'alarm-full' : 'alarm'"
      [matBadge]="(notificationsQuery.unseenCount$ | async) || undefined"
      matBadgeColor="warn"
      aria-hidden="false"
    ></mat-icon>
  </button>
  <notifications-menu #notificationsMenu [notifications]="notificationsQuery.notifications$ | async"></notifications-menu>

  <div class="divider"></div>
  <button mat-icon-button title="Sign out" i18n-title="@@shared.navbar.signOutLabel" class="icon-button" (click)="onLogoff()">
    <mat-icon svgIcon="logoff-empty"></mat-icon>
  </button>
</div>
