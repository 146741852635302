<div class="container row full-height" *ngIf="type === 'VOLUME'">
  <div class="column instruction left volume">
    <mat-icon class="surfaces-image" svgIcon="cut-fill-base-surface"></mat-icon>
    <label>Fill refers to what has <b>already</b> been filled.<br />Cut refers to what has <b>already</b> been cut.</label>
    <div class="row formula">
      Delta = <span class="dynamic-name">{{ flightName ?? 'Flight Surface' }}</span> &ndash; <span class="dynamic-name">{{ referenceSurfaceName ?? 'Surface' }}</span>as Base
    </div>
  </div>
  <div class="column instruction right volume">
    <mat-icon class="surfaces-image" svgIcon="cut-fill-target-surface"></mat-icon>
    <label>Cut refers to what <b>still needs</b> to be cut.<br />Fill refers to what <b>still needs</b> to be filled.</label>
    <div class="row formula">
      Delta = <span class="dynamic-name">{{ referenceSurfaceName ?? 'Surface' }}</span>as Target &ndash; <span class="dynamic-name">{{ flightName ?? 'Flight Surface' }}</span>
    </div>
  </div>
</div>
<div class="container row full-height" *ngIf="type === 'ELEVATION'">
  <div class="column instruction">
    <mat-icon class="surfaces-image" svgIcon="delta-elevation-base-surface"></mat-icon>
    <div class="row formula">
      Delta = <span class="dynamic-name">{{ flightName ?? 'Flight Surface' }}</span> &ndash; <span class="dynamic-name">{{ referenceSurfaceName ?? 'Surface' }}</span>as Base
    </div>
  </div>
</div>