import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { roundTo } from '../../../../utils/math';
import { getUnitName, UnitsEnum } from '../../../../utils/unit-conversion';

export interface MapModalSubtitleCustomBaseSurfaceDialogData {
  siteUnits: UnitsEnum;
  elevation?: number;
}

export interface MapModalSubtitleCustomBaseSurfaceDialogResult {
  elevation: number;
}

const ELEVATION_INPUT_STEP = 0.01;

const MIN_ELEVATION_METER = -1000;
const MAX_ELEVATION_METER = 10000;
const MIN_ELEVATION_FEET = -3000;
const MAX_ELEVATION_FEET = 30000;

@Component({
  template: `
    <dialog-layout (closeDialog)="close()">
      <div class="column full-container content">
        <div class="title">Specify the base surface custom elevation</div>

        <div class="column">
          <div class="row">
            <input
              class="field-input-control elevation-input"
              type="number"
              [step]="ELEVATION_INPUT_STEP"
              [min]="minElevation"
              [max]="maxElevation"
              [formControl]="elevationField"
              (keyup.enter)="submit()"
              cdkFocusInitial
              autofocus
            />
            <div class="units-label">{{ unitName }}</div>
          </div>
          <field-error *ngIf="elevationField.dirty && !isElevationValid" text="Elevation value is invalid"></field-error>
          <field-error
            *ngIf="elevationField.dirty && !isElevationInRange"
            [text]="'Elevation value must be between ' + minElevation + ' and ' + maxElevation"
          ></field-error>
        </div>

        <div class="row bottom-row">
          <button mat-button type="button" class="light-btn" (click)="close()">Cancel</button>
          <button mat-flat-button type="submit" [disabled]="!isElevationValid || !isElevationInRange" class="dark-btn" (click)="submit()">
            OK
          </button>
        </div>
      </div>
    </dialog-layout>
  `,
  styleUrls: ['./map-modal-subtitle-custom-base-surface-dialog.component.scss']
})
export class MapModalSubtitleCustomBaseSurfaceDialogComponent implements OnInit {
  ELEVATION_INPUT_STEP = ELEVATION_INPUT_STEP;

  elevationField = new UntypedFormControl();
  unitName: string;

  minElevation: number;
  maxElevation: number;

  constructor(
    private dialogRef: MatDialogRef<MapModalSubtitleCustomBaseSurfaceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: MapModalSubtitleCustomBaseSurfaceDialogData
  ) {
    const elevation = this.data.elevation;
    if (elevation !== undefined && elevation !== null && !isNaN(elevation)) {
      this.elevationField.patchValue(this.data.elevation, { emitEvent: false });
    }

    this.unitName = getUnitName(this.data.siteUnits);
    if (this.data.siteUnits === UnitsEnum.METER) {
      this.minElevation = MIN_ELEVATION_METER;
      this.maxElevation = MAX_ELEVATION_METER;
    } else {
      this.minElevation = MIN_ELEVATION_FEET;
      this.maxElevation = MAX_ELEVATION_FEET;
    }
  }

  ngOnInit() {}

  submit() {
    if (this.isElevationValid && this.isElevationInRange) {
      const elevation = roundTo(this.elevationField.value);
      const result: MapModalSubtitleCustomBaseSurfaceDialogResult = { elevation };
      this.dialogRef.close(result);
    }
  }

  close() {
    this.dialogRef.close();
  }

  get isElevationValid() {
    const elevation = this.elevationField.value;
    return elevation !== undefined && elevation !== null && !isNaN(elevation);
  }

  get isElevationInRange() {
    const elevation = this.elevationField.value;
    return elevation >= this.minElevation && elevation <= this.maxElevation;
  }
}
