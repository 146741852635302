import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { flatten } from 'lodash';
import moment from 'moment';
import { Papa } from 'ngx-papaparse';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { AuthQuery } from '../../auth/state/auth.query';
import { AccessLevelEnum, ACCOUNT_USER_ACCESS_LEVELS, getAccessLevelLabel, hasAccessLevel } from '../../auth/state/auth.utils';
import { Site } from '../../tenant/tenant.model';
import { TenantQuery } from '../../tenant/tenant.query';
import { ConfirmationDialogResultEnum } from '../confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { FeatureHintPlace } from '../feature-hint/state/feature-hints.store';
import { LimitReachedPopupService } from '../limit-reached-popup/limit-reached-popup.service';
import { DeviceService } from '../services/device.service';
import { LocaleService } from '../services/locale.service';
import { SnackBarService } from '../services/snackbar.service';
import { saveCSV } from '../utils/file-utils';
import { isDefined } from '../utils/general';
import { Team, User } from './state/users-and-teams.model';
import { UsersAndTeamsQuery } from './state/users-and-teams.query';
import { UsersAndTeamsService } from './state/users-and-teams.service';
import { TeamDialogService } from './team-dialog/team-dialog.service';
import { UpdateUsersDialogComponent } from './update-users-dialog/update-users-dialog.component';
import { UserDialogService } from './user-dialog/user-dialog.service';

enum Tab {
  USERS = 0,
  TEAMS = 1
}

@UntilDestroy()
@Component({
  templateUrl: './users-and-teams-dialog.component.html',
  styleUrls: ['./users-and-teams-dialog.component.scss']
})
export class UsersAndTeamsDialogComponent implements OnInit {
  Tab = Tab;
  ACCOUNT_USER_ACCESS_LEVELS = ACCOUNT_USER_ACCESS_LEVELS;
  AccessLevelEnum = AccessLevelEnum;
  getAccessLevelLabel = getAccessLevelLabel;
  hasAccessLevel = hasAccessLevel;
  FeatureHintPlace = FeatureHintPlace;

  @ViewChild('usersSort', { static: true }) usersSort: MatSort;
  @ViewChild('teamsSort', { static: true }) teamsSort: MatSort;

  loading: boolean;
  canAddUser: boolean;
  selectedTab = Tab.USERS;
  searchField = new UntypedFormControl();

  usersDataSource = new MatTableDataSource<User>();
  usersCols = [
    'name',
    'email',
    'accessLevel',
    // 'teams',
    'sites',
    'lastSuccessfulLoginTime',
    'edit',
    'delete'
  ];

  teamsDataSource = new MatTableDataSource<Team>();
  teamsCols = ['name', 'description', 'users', 'edit', 'delete'];

  constructor(
    private dialogRef: MatDialogRef<UsersAndTeamsDialogComponent>,
    private dialog: MatDialog,
    private authQuery: AuthQuery,
    private service: UsersAndTeamsService,
    private query: UsersAndTeamsQuery,
    private tenantQuery: TenantQuery,
    private confirmationDialog: ConfirmationDialogService,
    private userDialog: UserDialogService,
    private teamDialog: TeamDialogService,
    private snackbar: SnackBarService,
    private limitReachedPopup: LimitReachedPopupService,
    private papa: Papa,
    private device: DeviceService,
    private localeService: LocaleService
  ) {}

  ngOnInit() {
    this.usersDataSource.sort = this.usersSort;
    this.usersDataSource.sortingDataAccessor = this.usersSortFunc;
    this.teamsDataSource.sort = this.teamsSort;
    this.teamsDataSource.sortingDataAccessor = this.teamsSortFunc;
    this.usersDataSource.filterPredicate = this.usersFilterFn;
    this.teamsDataSource.filterPredicate = this.teamsFilterFn;

    this.searchField.valueChanges.subscribe(this.onSearch);

    this.setLoading(true);
    this.service
      .init()
      .pipe(untilDestroyed(this))
      .subscribe({
        error: error => {
          this.snackbar.openError('Error initializing users and teams', error);
        },
        complete: () => this.setLoading(false)
      });

    this.query.users$.pipe(untilDestroyed(this)).subscribe(users => (this.usersDataSource.data = users));
    this.query.teams$.pipe(untilDestroyed(this)).subscribe(teams => (this.teamsDataSource.data = teams));

    this.tenantQuery.canAddUser$.pipe(untilDestroyed(this)).subscribe(canAddUser => (this.canAddUser = canAddUser));
  }

  private usersSortFunc = (user: User, sortHeaderId: string) => {
    if (['name', 'email'].includes(sortHeaderId)) {
      return user[sortHeaderId]?.toLocaleLowerCase();
    } else if (['accessLevel'].includes(sortHeaderId)) {
      return ACCOUNT_USER_ACCESS_LEVELS.findIndex(accessLevel => accessLevel === user.accessLevel);
    } else {
      return user[sortHeaderId];
    }
  };

  private teamsSortFunc = (team: Team, sortHeaderId: string) => {
    if (['name', 'description'].includes(sortHeaderId)) {
      return team[sortHeaderId]?.toLocaleLowerCase();
    } else {
      return team[sortHeaderId];
    }
  };

  setLoading(loading: boolean) {
    this.loading = loading;

    this.loading ? this.searchField.disable() : this.searchField.enable();
  }

  downloadUsersCSV() {
    if (this.loading || !isDefined(this.usersDataSource.filteredData)) {
      return;
    }

    const tenantName = this.authQuery.getActiveTenantName();
    let exportData: any[] = [];
    const usersData = this.usersDataSource.filteredData
      .sort((user1: User, user2: User) => user1.name.localeCompare(user2.name))
      .map((user: User) => {
        const userData = {
          Account: tenantName,
          'First Name': user.firstName,
          'Last Name': user.lastName,
          Email: user.email,
          'Employee Title': user.employeeTitle?.title,
          'Employee Number': user.employeeNumber,
          'Permission Level': getAccessLevelLabel(user.accessLevel),
          Site: '',
          'Last Login': this.formatDateWithTime(user.lastSuccessfulLoginTime),
          'Created By': `${user.createdByEmail ? user.createdByEmail + (user.createdByIsActive ? '' : ' (deleted)') : ''}`,
          'Creation Time': this.formatDateWithTime(user.creationTime)
        };
        const userSiteNames = user.sites.map(site => site.name);
        return userSiteNames.length ? userSiteNames.map(siteName => ({ ...userData, Site: siteName })) : userData;
      });
    exportData = flatten(usersData);

    const csv = this.papa.unparse(exportData, { header: true });
    const downloadDate = moment().format('YYYY_MM_DD');
    saveCSV(csv, downloadDate + ' - ' + (this.selectedTab === Tab.USERS ? 'users.csv' : 'teams.csv'));
  }

  updateUsersClick() {
    this.dialog.open(UpdateUsersDialogComponent, {
      autoFocus: false,
      restoreFocus: false,
      width: '450px',
      height: '270px'
    });
  }

  private formatDateWithTime(date: Date | string) {
    if (!isDefined(date)) {
      return null;
    }

    return this.localeService.formatDateNumeral({ date, shortYear: true, withTime: true });
  }

  private addUserFromTeam = () => {
    if (this.canAddUser) {
      return this.userDialog.openDialog();
    } else {
      const reachedMsg = `You have reached the allowed number of users for your account.
      To add additional users, you will need to upgrade your account.`;
      this.snackbar.open(reachedMsg);
      return null;
    }
  };

  addUser(event?: MouseEvent) {
    if (this.canAddUser) {
      const dialogRef = this.userDialog.openDialog({ openAddTeamDialog: this.addTeamFromUser });
      dialogRef
        .afterClosed()
        .pipe(switchMap(userData => (userData ? this.service.createUser(userData) : of(null))))
        .subscribe({
          error: error => {
            let message = 'Error creating user';
            if (error instanceof HttpErrorResponse && error.error.code === 'USER_ALREADY_EXIST') {
              message = error.error.message;
            }
            this.snackbar.openError(message, error);
          }
        });
    } else {
      const boundingRect = (event.target as HTMLElement).getBoundingClientRect();
      this.limitReachedPopup.open({
        position: {
          top: boundingRect.top + 30 + 'px',
          left: boundingRect.left - 20 + 'px'
        },
        title: 'Upgrade to remove user limit',
        content: `You have reached the allowed number of users for your account.

        To add additional users, you will need to upgrade your account.`
      });
    }
  }

  editUser(user: User) {
    const dialogRef = this.userDialog.openDialog({ openAddTeamDialog: this.addTeamFromUser, user });
    dialogRef
      .afterClosed()
      .pipe(switchMap(userData => (userData ? this.service.updateUser(userData) : of(null))))
      .subscribe({
        error: error => {
          this.snackbar.openError('Error updating user', error);
        }
      });
  }

  isCurrentUser(user: User) {
    return this.authQuery.getUserId() === user.id;
  }

  deleteUser(user: User) {
    if (this.isCurrentUser(user)) {
      return;
    }

    const dialogData = {
      title: 'Delete User',
      content: `Are you sure you want to delete the user "${user.name}"?

      This cannot be undone.`,
      yesCaption: 'Delete',
      noCaption: 'Cancel'
    };
    this.confirmationDialog
      .openDialog(dialogData)
      .afterClosed()
      .subscribe(result => {
        if (result === ConfirmationDialogResultEnum.YES) {
          this.service.deleteUser(user).subscribe({
            error: error => {
              this.snackbar.openError('Error deleting user', error);
            }
          });
        }
      });
  }

  private addTeamFromUser = () => {
    return this.teamDialog.openDialog();
  };

  addTeam() {
    return this.teamDialog.openDialog({ openAddUserDialog: this.addUserFromTeam });
  }

  editTeam(team: Team) {
    this.teamDialog.openDialog({ openAddUserDialog: this.addUserFromTeam, team });
  }

  deleteTeam(team: Team) {
    const dialogData = {
      title: 'Delete Team',
      content: `Are you sure you want to delete the team "${team.name}"?

      This cannot be undone.`,
      yesCaption: 'Delete',
      noCaption: 'Cancel'
    };
    this.confirmationDialog
      .openDialog(dialogData)
      .afterClosed()
      .subscribe(isYes => {
        if (isYes) {
          this.service.deleteTeam(team.id).subscribe({
            error: error => {
              this.snackbar.openError('Error deleting team', error);
            }
          });
        }
      });
  }

  searchFieldKeyEvent = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      // Don't close modal
      event.stopPropagation();

      // Blur search input
      (event.target as HTMLElement).blur();

      // Clear search input
      this.searchField.patchValue('', { emitEvent: true });
    }
  };

  onSearch = (searchStr: string) => {
    const filterValue = searchStr ? searchStr.trim().toLowerCase() : '';

    if (this.selectedTab === Tab.USERS) {
      this.usersDataSource.filter = filterValue;
    } else {
      this.teamsDataSource.filter = filterValue;
    }
  };

  usersFilterFn = (user: User, searchStr: string) => {
    if (user.name && user.name.toLowerCase().includes(searchStr)) {
      return true;
    }

    if (user.email && user.email.toLowerCase().includes(searchStr)) {
      return true;
    }

    return false;
  };

  teamsFilterFn = (team: Team, searchStr: string) => {
    if (team.name && team.name.toLowerCase().includes(searchStr)) {
      return true;
    }

    if (team.description && team.description.toLowerCase().includes(searchStr)) {
      return true;
    }

    return false;
  };

  tabChange(tab: Tab) {
    this.selectedTab = tab;

    this.searchField.patchValue('', { emitEvent: true });
  }

  close() {
    this.dialogRef.close();
  }

  listTitle(list: Array<Team | Site>) {
    if (!list || list.length === 0) {
      return '';
    }

    return list.map(el => el.name).join(', ');
  }

  formatList(list: Array<Team | Site>) {
    if (!list || list.length === 0) {
      return '';
    }

    const MAX_LIST_TEXT_CHARS = 20;
    const namesList = list.map(el => el.name).filter(name => name.length <= MAX_LIST_TEXT_CHARS);
    if (namesList.length === 0) {
      return list[0].name.slice(0, MAX_LIST_TEXT_CHARS - 3) + '...';
    }

    const resultNames = [];
    let charCount = 0;
    let moreCount = list.length;

    for (const name of namesList) {
      if (charCount + name.length > MAX_LIST_TEXT_CHARS) {
        break;
      }

      resultNames.push(name);
      charCount += name.length;
      moreCount--;
    }

    const namesString = resultNames.join(', ');
    if (moreCount) {
      return `${namesString}... +${moreCount} more`;
    } else {
      return namesString;
    }
  }

  get isDesktop() {
    return this.device.isDesktop();
  }
}
