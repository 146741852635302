/**
 * Notification Management Service
 * API's for mile stone I
 *
 * OpenAPI spec version: 1.4.9
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface GetNFIHintResponse {
  hintName?: string;
  id?: string;
  parentPlace?: string;
  place?: string;
  tag?: string;
  type?: GetNFIHintResponse.TypeEnum;
}
export namespace GetNFIHintResponse {
  export type TypeEnum = 'PATH' | 'FEATURE';
  export const TypeEnum = {
    PATH: 'PATH' as TypeEnum,
    FEATURE: 'FEATURE' as TypeEnum
  };
}
