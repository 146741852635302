/*! grapick - 0.1.13 */
!function (e, t) {
  "object" == typeof exports && "object" == typeof module ? module.exports = t() : "function" == typeof define && define.amd ? define([], t) : "object" == typeof exports ? exports.Grapick = t() : e.Grapick = t();
}("undefined" != typeof self ? self : this, function () {
  return function (e) {
    function t(i) {
      if (n[i]) return n[i].exports;
      var r = n[i] = {
        i: i,
        l: !1,
        exports: {}
      };
      return e[i].call(r.exports, r, r.exports, t), r.l = !0, r.exports;
    }
    var n = {};
    return t.m = e, t.c = n, t.d = function (e, n, i) {
      t.o(e, n) || Object.defineProperty(e, n, {
        configurable: !1,
        enumerable: !0,
        get: i
      });
    }, t.n = function (e) {
      var n = e && e.__esModule ? function () {
        return e.default;
      } : function () {
        return e;
      };
      return t.d(n, "a", n), n;
    }, t.o = function (e, t) {
      return Object.prototype.hasOwnProperty.call(e, t);
    }, t.p = "", t(t.s = 1);
  }([function (e, t, n) {
    "use strict";

    function i(e, t, n) {
      t = t.split(/\s+/);
      for (var i = 0; i < t.length; ++i) e.addEventListener(t[i], n);
    }
    function r(e, t, n) {
      t = t.split(/\s+/);
      for (var i = 0; i < t.length; ++i) e.removeEventListener(t[i], n);
    }
    Object.defineProperty(t, "__esModule", {
      value: !0
    }), t.on = i, t.off = r;
    t.isFunction = function (e) {
      return "function" == typeof e;
    }, t.isDef = function (e) {
      return void 0 !== e;
    }, t.getPointerEvent = function (e) {
      return e.touches && e.touches[0] || e;
    };
  }, function (e, t, n) {
    "use strict";

    var i = n(2),
      r = function (e) {
        return e && e.__esModule ? e : {
          default: e
        };
      }(i);
    e.exports = function (e) {
      return new r.default(e);
    };
  }, function (e, t, n) {
    "use strict";

    function i(e) {
      return e && e.__esModule ? e : {
        default: e
      };
    }
    function r(e) {
      if (Array.isArray(e)) {
        for (var t = 0, n = Array(e.length); t < e.length; t++) n[t] = e[t];
        return n;
      }
      return Array.from(e);
    }
    function o(e, t) {
      if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
    }
    function a(e, t) {
      if (!e) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
      return !t || "object" != typeof t && "function" != typeof t ? e : t;
    }
    function l(e, t) {
      if ("function" != typeof t && null !== t) throw new TypeError("Super expression must either be null or a function, not " + typeof t);
      e.prototype = Object.create(t && t.prototype, {
        constructor: {
          value: e,
          enumerable: !1,
          writable: !0,
          configurable: !0
        }
      }), t && (Object.setPrototypeOf ? Object.setPrototypeOf(e, t) : e.__proto__ = t);
    }
    Object.defineProperty(t, "__esModule", {
      value: !0
    });
    var u = function () {
        function e(e, t) {
          for (var n = 0; n < t.length; n++) {
            var i = t[n];
            i.enumerable = i.enumerable || !1, i.configurable = !0, "value" in i && (i.writable = !0), Object.defineProperty(e, i.key, i);
          }
        }
        return function (t, n, i) {
          return n && e(t.prototype, n), i && e(t, i), t;
        };
      }(),
      s = n(3),
      c = i(s),
      h = n(4),
      f = i(h),
      d = n(0),
      v = function (e, t) {
        return e.position - t.position;
      },
      p = function (e) {
        return e + "-gradient(";
      },
      g = function (e) {
        function t() {
          var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {};
          o(this, t);
          var n = a(this, (t.__proto__ || Object.getPrototypeOf(t)).call(this));
          e = Object.assign({}, e);
          var i = {
            pfx: "grp",
            el: ".grp",
            colorEl: "",
            min: 0,
            max: 100,
            direction: "90deg",
            type: "linear",
            height: "30px",
            width: "100%",
            emptyColor: "#000",
            onValuePos: function (e) {
              return parseInt(e);
            }
          };
          for (var r in i) r in e || (e[r] = i[r]);
          var l = e.el;
          if (!((l = "string" == typeof l ? document.querySelector(l) : l) instanceof HTMLElement)) throw "Element not found, given " + l;
          return n.el = l, n.handlers = [], n.options = e, n.on("handler:color:change", function (e, t) {
            return n.change(t);
          }), n.on("handler:position:change", function (e, t) {
            return n.change(t);
          }), n.on("handler:remove", function (e) {
            return n.change(1);
          }), n.on("handler:add", function (e) {
            return n.change(1);
          }), n.render(), n;
        }
        return l(t, e), u(t, [{
          key: "destroy",
          value: function () {
            var e = this;
            this.clear(), this.e = {}, ["el", "handlers", "options", "colorPicker"].forEach(function (t) {
              return e[t] = 0;
            }), ["previewEl", "wrapperEl", "sandEl"].forEach(function (t) {
              var n = e[t];
              n && n.parentNode && n.parentNode.removeChild(n), delete e[t];
            });
          }
        }, {
          key: "setColorPicker",
          value: function (e) {
            this.colorPicker = e;
          }
        }, {
          key: "getValue",
          value: function (e, t) {
            var n = this.getColorValue(),
              i = e || this.getType(),
              r = ["top", "left", "bottom", "right", "center"],
              o = t || this.getDirection();
            return ["linear", "repeating-linear"].indexOf(i) >= 0 && r.indexOf(o) >= 0 && (o = "center" === o ? "to right" : "to " + o), ["radial", "repeating-radial"].indexOf(i) >= 0 && r.indexOf(o) >= 0 && (o = "circle at " + o), n ? i + "-gradient(" + o + ", " + n + ")" : "";
          }
        }, {
          key: "getSafeValue",
          value: function (e, t) {
            var n = this.previewEl,
              i = this.getValue(e, t);
            if (!this.sandEl && (this.sandEl = document.createElement("div")), !n || !i) return "";
            for (var o = this.sandEl.style, a = [i].concat(r(this.getPrefixedValues(e, t))), l = void 0, u = 0; u < a.length && (l = a[u], o.backgroundImage = l, o.backgroundImage != l); u++);
            return o.backgroundImage;
          }
        }, {
          key: "setValue",
          value: function () {
            var e = this,
              t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : "",
              n = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {},
              i = this.type,
              r = this.direction,
              o = t.indexOf("(") + 1,
              a = t.lastIndexOf(")"),
              l = t.substring(o, a),
              u = l.split(/,(?![^(]*\)) /);
            if (this.clear(n), !l) return void this.updatePreview();
            u.length > 2 && (r = u.shift());
            var s = void 0;
            ["repeating-linear", "repeating-radial", "linear", "radial"].forEach(function (e) {
              t.indexOf(p(e)) > -1 && !s && (s = 1, i = e);
            }), this.setDirection(r, n), this.setType(i, n), u.forEach(function (t) {
              var i = t.split(" "),
                r = parseFloat(i.pop()),
                o = i.join("");
              e.addHandler(r, o, 0, n);
            }), this.updatePreview();
          }
        }, {
          key: "getColorValue",
          value: function () {
            var e = this.handlers;
            return e.sort(v), e = 1 == e.length ? [e[0], e[0]] : e, e.map(function (e) {
              return e.getValue();
            }).join(", ");
          }
        }, {
          key: "getPrefixedValues",
          value: function (e, t) {
            var n = this.getValue(e, t);
            return ["-moz-", "-webkit-", "-o-", "-ms-"].map(function (e) {
              return "" + e + n;
            });
          }
        }, {
          key: "change",
          value: function () {
            var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : 1,
              t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
            this.updatePreview(), !t.silent && this.emit("change", e);
          }
        }, {
          key: "setDirection",
          value: function (e) {
            var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
            this.options.direction = e;
            var n = t.complete,
              i = void 0 === n ? 1 : n;
            this.change(i, t);
          }
        }, {
          key: "getDirection",
          value: function () {
            return this.options.direction;
          }
        }, {
          key: "setType",
          value: function (e) {
            var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
            this.options.type = e;
            var n = t.complete,
              i = void 0 === n ? 1 : n;
            this.change(i, t);
          }
        }, {
          key: "getType",
          value: function () {
            return this.options.type;
          }
        }, {
          key: "addHandler",
          value: function (e, t) {
            var n = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : 1,
              i = arguments.length > 3 && void 0 !== arguments[3] ? arguments[3] : {},
              r = new f.default(this, e, t, n, i);
            return !i.silent && this.emit("handler:add", r), r;
          }
        }, {
          key: "getHandler",
          value: function (e) {
            return this.handlers[e];
          }
        }, {
          key: "getHandlers",
          value: function () {
            return this.handlers;
          }
        }, {
          key: "clear",
          value: function () {
            for (var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {}, t = this.handlers, n = t.length - 1; n >= 0; n--) t[n].remove(e);
          }
        }, {
          key: "getSelected",
          value: function () {
            for (var e = this.getHandlers(), t = 0; t < e.length; t++) {
              var n = e[t];
              if (n.isSelected()) return n;
            }
            return null;
          }
        }, {
          key: "updatePreview",
          value: function () {
            var e = this.previewEl;
            e && (e.style.backgroundImage = this.getValue("linear", "to right"));
          }
        }, {
          key: "initEvents",
          value: function () {
            var e = this,
              t = this.previewEl;
            t && (0, d.on)(t, "click", function (n) {
              var i = e.options,
                r = i.min,
                o = i.max,
                a = {
                  w: t.clientWidth,
                  h: t.clientHeight
                },
                l = n.offsetX - t.clientLeft,
                u = n.offsetY - t.clientTop,
                s = l / a.w * 100;
              if (!(s > o || s < r || u > a.h || u < 0)) {
                var c = document.createElement("canvas"),
                  h = c.getContext("2d");
                c.width = a.w, c.height = a.h;
                var f = h.createLinearGradient(0, 0, a.w, a.h);
                e.getHandlers().forEach(function (e) {
                  return f.addColorStop(e.position / 100, e.color);
                }), h.fillStyle = f, h.fillRect(0, 0, c.width, c.height), c.style.background = "black";
                var d = c.getContext("2d").getImageData(l, u, 1, 1).data,
                  v = "rgba(" + d[0] + ", " + d[1] + ", " + d[2] + ", " + d[3] + ")",
                  p = "rgba(0, 0, 0, 0)" == v ? i.emptyColor : v;
                e.addHandler(s, p);
              }
            });
          }
        }, {
          key: "render",
          value: function () {
            var e = this.options,
              t = this.el,
              n = e.pfx,
              i = e.height,
              r = e.width;
            if (t) {
              var o = n + "-wrapper",
                a = n + "-preview";
              t.innerHTML = '\n      <div class="' + o + '">\n        <div class="' + a + '"></div>\n      </div>\n    ';
              var l = t.querySelector("." + o),
                u = t.querySelector("." + a),
                s = l.style;
              s.position = "relative", this.wrapperEl = l, this.previewEl = u, i && (s.height = i), r && (s.width = r), this.initEvents(), this.updatePreview();
            }
          }
        }]), t;
      }(c.default);
    t.default = g;
  }, function (e, t, n) {
    "use strict";

    function i(e, t) {
      if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
    }
    Object.defineProperty(t, "__esModule", {
      value: !0
    });
    var r = function () {
        function e(e, t) {
          for (var n = 0; n < t.length; n++) {
            var i = t[n];
            i.enumerable = i.enumerable || !1, i.configurable = !0, "value" in i && (i.writable = !0), Object.defineProperty(e, i.key, i);
          }
        }
        return function (t, n, i) {
          return n && e(t.prototype, n), i && e(t, i), t;
        };
      }(),
      o = function () {
        function e() {
          i(this, e);
        }
        return r(e, [{
          key: "on",
          value: function (e, t, n) {
            var i = this.e || (this.e = {});
            return (i[e] || (i[e] = [])).push({
              fn: t,
              ctx: n
            }), this;
          }
        }, {
          key: "once",
          value: function (e, t, n) {
            function i() {
              r.off(e, i), t.apply(n, arguments);
            }
            var r = this;
            return i._ = t, this.on(e, i, n);
          }
        }, {
          key: "emit",
          value: function (e) {
            var t = [].slice.call(arguments, 1),
              n = ((this.e || (this.e = {}))[e] || []).slice(),
              i = 0,
              r = n.length;
            for (i; i < r; i++) n[i].fn.apply(n[i].ctx, t);
            return this;
          }
        }, {
          key: "off",
          value: function (e, t) {
            var n = this.e || (this.e = {}),
              i = n[e],
              r = [];
            if (i && t) for (var o = 0, a = i.length; o < a; o++) i[o].fn !== t && i[o].fn._ !== t && r.push(i[o]);
            return r.length ? n[e] = r : delete n[e], this;
          }
        }]), e;
      }();
    t.default = o;
  }, function (e, t, n) {
    "use strict";

    function i(e, t) {
      if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
    }
    Object.defineProperty(t, "__esModule", {
      value: !0
    });
    var r = function () {
        function e(e, t) {
          for (var n = 0; n < t.length; n++) {
            var i = t[n];
            i.enumerable = i.enumerable || !1, i.configurable = !0, "value" in i && (i.writable = !0), Object.defineProperty(e, i.key, i);
          }
        }
        return function (t, n, i) {
          return n && e(t.prototype, n), i && e(t, i), t;
        };
      }(),
      o = n(0),
      a = function () {
        function e(t) {
          var n = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 0,
            r = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : "black",
            o = arguments.length > 3 && void 0 !== arguments[3] ? arguments[3] : 1,
            a = arguments.length > 4 && void 0 !== arguments[4] ? arguments[4] : {};
          i(this, e), t.getHandlers().push(this), this.gp = t, this.position = n, this.color = r, this.selected = 0, this.render(), o && this.select(a);
        }
        return r(e, [{
          key: "toJSON",
          value: function () {
            return {
              position: this.position,
              selected: this.selected,
              color: this.color
            };
          }
        }, {
          key: "setColor",
          value: function (e) {
            var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 1;
            this.color = e, this.emit("handler:color:change", this, t);
          }
        }, {
          key: "setPosition",
          value: function (e) {
            var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 1,
              n = this.getEl();
            this.position = e, n && (n.style.left = e + "%"), this.emit("handler:position:change", this, t);
          }
        }, {
          key: "getColor",
          value: function () {
            return this.color;
          }
        }, {
          key: "getPosition",
          value: function () {
            var e = this.position,
              t = this.gp,
              n = t.options.onValuePos;
            return (0, o.isFunction)(n) ? n(e) : e;
          }
        }, {
          key: "isSelected",
          value: function () {
            return !!this.selected;
          }
        }, {
          key: "getValue",
          value: function () {
            return this.getColor() + " " + this.getPosition() + "%";
          }
        }, {
          key: "select",
          value: function () {
            var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {},
              t = this.getEl(),
              n = this.gp.getHandlers();
            !e.keepSelect && n.forEach(function (e) {
              return e.deselect();
            }), this.selected = 1;
            var i = this.getSelectedCls();
            t && (t.className += " " + i), this.emit("handler:select", this);
          }
        }, {
          key: "deselect",
          value: function () {
            var e = this.getEl();
            this.selected = 0;
            var t = this.getSelectedCls();
            e && (e.className = e.className.replace(t, "").trim()), this.emit("handler:deselect", this);
          }
        }, {
          key: "getSelectedCls",
          value: function () {
            return this.gp.options.pfx + "-handler-selected";
          }
        }, {
          key: "remove",
          value: function () {
            var e = this,
              t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {},
              n = this.cpFn,
              i = this.getEl(),
              r = this.gp.getHandlers(),
              a = r.splice(r.indexOf(this), 1)[0];
            return i && i.parentNode.removeChild(i), !t.silent && this.emit("handler:remove", a), (0, o.isFunction)(n) && n(this), ["el", "gp"].forEach(function (t) {
              return e[t] = 0;
            }), a;
          }
        }, {
          key: "getEl",
          value: function () {
            return this.el;
          }
        }, {
          key: "initEvents",
          value: function () {
            var e = this,
              t = this.getEl(),
              n = this.gp.previewEl,
              i = this.gp.options,
              r = i.min,
              a = i.max,
              l = t.querySelector("[data-toggle=handler-close]"),
              u = t.querySelector("[data-toggle=handler-color-c]"),
              s = t.querySelector("[data-toggle=handler-color-wrap]"),
              c = t.querySelector("[data-toggle=handler-color]"),
              h = t.querySelector("[data-toggle=handler-drag]"),
              f = function (t) {
                var n = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 1,
                  i = t.target.value;
                e.setColor(i, n), s && (s.style.backgroundColor = i);
              };
            if (u && (0, o.on)(u, "click", function (e) {
              return e.stopPropagation();
            }), l && (0, o.on)(l, "click", function (t) {
              t.stopPropagation(), e.remove();
            }), c && ((0, o.on)(c, "change", f), (0, o.on)(c, "input", function (e) {
              return f(e, 0);
            })), h) {
              var d = 0,
                v = 0,
                p = 0,
                g = {},
                y = {},
                m = {},
                k = function (t) {
                  var n = (0, o.getPointerEvent)(t);
                  p = 1, m.x = n.clientX - y.x, m.y = n.clientY - y.y, d = 100 * m.x, d /= g.w, d = v + d, d = d < r ? r : d, d = d > a ? a : d, e.setPosition(d, 0), e.emit("handler:drag", e, d), (0, o.isDef)(t.button) && 0 === t.which && b(t);
                },
                b = function t(n) {
                  (0, o.off)(document, "touchmove mousemove", k), (0, o.off)(document, "touchend mouseup", t), p && (p = 0, e.setPosition(d), e.emit("handler:drag:end", e, d));
                },
                w = function (t) {
                  if (!(0, o.isDef)(t.button) || 0 === t.button) {
                    e.select();
                    var i = (0, o.getPointerEvent)(t);
                    v = e.position, g.w = n.clientWidth, g.h = n.clientHeight, y.x = i.clientX, y.y = i.clientY, (0, o.on)(document, "touchmove mousemove", k), (0, o.on)(document, "touchend mouseup", b), e.emit("handler:drag:start", e);
                  }
                };
              (0, o.on)(h, "touchstart mousedown", w), (0, o.on)(h, "click", function (e) {
                return e.stopPropagation();
              });
            }
          }
        }, {
          key: "emit",
          value: function () {
            var e;
            (e = this.gp).emit.apply(e, arguments);
          }
        }, {
          key: "render",
          value: function () {
            var e = this.gp,
              t = e.options,
              n = e.previewEl,
              i = e.colorPicker,
              r = t.pfx,
              o = t.colorEl,
              a = this.getColor();
            if (n) {
              var l = document.createElement("div"),
                u = l.style,
                s = r + "-handler";
              return l.className = s, l.innerHTML = '\n      <div class="' + s + '-close-c">\n        <div class="' + s + '-close" data-toggle="handler-close">&Cross;</div>\n      </div>\n      <div class="' + s + '-drag" data-toggle="handler-drag"></div>\n      <div class="' + s + '-cp-c" data-toggle="handler-color-c">\n        ' + (o || '\n          <div class="' + s + '-cp-wrap" data-toggle="handler-color-wrap" style="background-color: ' + a + '">\n            <input type="color" data-toggle="handler-color" value="' + a + '">\n          </div>') + "\n      </div>\n    ", u.position = "absolute", u.top = 0, u.left = this.position + "%", n.appendChild(l), this.el = l, this.initEvents(), this.cpFn = i && i(this), l;
            }
          }
        }]), e;
      }();
    t.default = a;
  }]);
});