import { Component, Host, Input, OnInit } from '@angular/core';

import { ToggleButtonGroupComponent } from '../toggle-button-group.component';

@Component({
  selector: 'toggle-button',
  template: `
    <div
      class="toggle-item"
      matRipple
      [matRippleDisabled]="parent.disabled"
      [class.checked]="parent.value === value"
      [class.disabled]="parent.disabled"
      (click)="toggle()"
    >
      <ng-content></ng-content>
    </div>
  `,
  styleUrls: ['./toggle-button.component.scss']
})
export class ToggleButtonComponent implements OnInit {
  @Input() value: any;

  constructor(@Host() public parent: ToggleButtonGroupComponent) {}

  toggle() {
    if (!this.parent.disabled) {
      this.parent.value = this.value;
    }
  }

  ngOnInit() {}
}
