import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'arrow-popup-layout',
  templateUrl: './arrow-popup-layout.component.html',
  styleUrls: ['./arrow-popup-layout.component.scss']
})
export class ArrowPopupLayoutComponent implements OnInit {
  @Input() direction: 'right' | 'top' | 'top-left' = 'right';
  @Input() colorMode: 'light' | 'dark' = 'dark';

  constructor() {}

  ngOnInit() {}
}
