import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Team } from '../state/users-and-teams.model';
import { TeamDialogComponent, TeamDialogData } from './team-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class TeamDialogService {
  constructor(private dialog: MatDialog) {}

  openDialog(data: TeamDialogData = {}) {
    return this.dialog.open<TeamDialogComponent, TeamDialogData, Team>(TeamDialogComponent, {
      autoFocus: false,
      restoreFocus: false,
      height: '60%',
      minWidth: '700px',
      minHeight: '500px',
      data
    });
  }
}
