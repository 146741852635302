import { Pipe, PipeTransform } from '@angular/core';

import { DrawingType } from '../../detailed-site/state/detailed-site-entities/detailed-site-entities.model';
import { isDefined } from '../utils/general';

const DRAWING_TYPE_ICON = {
  [DrawingType.ARROW]: 'arrow-right-alt',
  [DrawingType.ELLIPSE]: 'circle',
  [DrawingType.POLYGON]: 'hexagone',
  [DrawingType.POLYLINE]: 'polyline',
  [DrawingType.RECTANGLE]: 'rectangle',
  [DrawingType.TEXT]: 'format-color-text'
};

@Pipe({
  name: 'drawingIcon'
})
export class DrawingIconPipe implements PipeTransform {
  transform(type: DrawingType) {
    if (!isDefined(type)) {
      return;
    }
    return DRAWING_TYPE_ICON[type];
  }
}
