<div *ngIf="isBoxExpanded" class="column expanded-box"
  [@fadeInOut]
  [@fadeExpandCollapse]="isBoxExpanded ? 'visible' : 'hidden'"
  [class.hidden]="isHidden">

  <div class="control expanded">
    <ng-content select="[boxControlButtons]"></ng-content>
    <button mat-icon-button class="arrow-btn collapse-btn" (click)="toggleExpanded()" disableRipple>
      <mat-icon svgIcon="down-arrow"></mat-icon>
    </button>
  </div>

  <div class="column body">
    <div class="row header-container">
      <ng-content select="[boxHeader]"></ng-content>
    </div>
    <div class="row subheader-container">
      <ng-content select="[boxSubheader]"></ng-content>
    </div>
    <div class="column content-container">
      <ng-content select="[boxContent]"></ng-content>
    </div>
  </div>
</div>

<div *ngIf="!isBoxExpanded" class="control collapsed row space-between" [class.disabled]="disableExpanding" (click)="toggleExpanded()" [matTooltip]="disableExpanding ? 'Calculation results will be available once edit is done' : ''">
  <ng-content select="[boxControlTitle]"></ng-content>
  <button mat-icon-button class="arrow-btn" disableRipple [disabled]="disableExpanding">
    <mat-icon svgIcon="down-arrow"></mat-icon>
  </button>
</div>