import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { GroupLayerDetailsComponent, GroupLayerDetailsData } from './group-layer-details.component';

@Injectable({
  providedIn: 'root'
})
export class GroupLayerDetailsService {
  constructor(private dialog: MatDialog) {}

  openDialog(data?: GroupLayerDetailsData) {
    this.dialog.open<GroupLayerDetailsComponent, GroupLayerDetailsData>(GroupLayerDetailsComponent, {
      autoFocus: false,
      restoreFocus: false,
      width: '500px',
      height: '400px',
      data
    });
  }
}
