/**
 * Flow Management Service
 * APIs document (version: 2.6.9)
 *
 * OpenAPI spec version: 2.6.9
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface TableSortModel {
  fieldName?: TableSortModel.FieldNameEnum;
  order?: TableSortModel.OrderEnum;
}
export namespace TableSortModel {
  export type FieldNameEnum = 'eventDate' | 'siteName' | 'missionFlightDate' | 'eventType' | 'imagesCost';
  export const FieldNameEnum = {
    EventDate: 'eventDate' as FieldNameEnum,
    SiteName: 'siteName' as FieldNameEnum,
    MissionFlightDate: 'missionFlightDate' as FieldNameEnum,
    EventType: 'eventType' as FieldNameEnum,
    ImagesCost: 'imagesCost' as FieldNameEnum
  };
  export type OrderEnum = 'DESC' | 'ASC';
  export const OrderEnum = {
    DESC: 'DESC' as OrderEnum,
    ASC: 'ASC' as OrderEnum
  };
}
