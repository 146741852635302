import { Pipe, PipeTransform } from '@angular/core';

import { Activity, ActivityType } from '../../detailed-site/state/detailed-site-activities/detailed-site-activities.model';
import { isDefined } from '../utils/general';

const MEASURE_TYPE_ICONS = {
  [ActivityType.AREA]: 'measure-area',
  [ActivityType.COUNT]: 'measure-point',
  [ActivityType.LENGTH]: 'line-horizontal',
  [ActivityType.VOLUME]: 'volume',
  [ActivityType.WEIGHT]: 'weight'
};

@Pipe({
  name: 'activityMeasureIcon'
})
export class ActivityMeasureIconPipe implements PipeTransform {
  transform(activity: Activity) {
    if (!isDefined(activity?.activityType)) {
      return 'activity';
    }
    return MEASURE_TYPE_ICONS[activity.activityType];
  }
}
