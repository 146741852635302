// index.ts
import { centroid } from "@turf/centroid";
import { rhumbBearing } from "@turf/rhumb-bearing";
import { rhumbDistance } from "@turf/rhumb-distance";
import { rhumbDestination } from "@turf/rhumb-destination";
import { clone } from "@turf/clone";
import { coordEach } from "@turf/meta";
import { getCoords } from "@turf/invariant";
import { isObject } from "@turf/helpers";
function transformRotate(geojson, angle, options) {
  options = options || {};
  if (!isObject(options)) throw new Error("options is invalid");
  const pivot = options.pivot;
  const mutate = options.mutate;
  if (!geojson) throw new Error("geojson is required");
  if (angle === void 0 || angle === null || isNaN(angle)) throw new Error("angle is required");
  if (angle === 0) return geojson;
  const pivotCoord = pivot != null ? pivot : centroid(geojson);
  if (mutate === false || mutate === void 0) geojson = clone(geojson);
  coordEach(geojson, function (pointCoords) {
    const initialAngle = rhumbBearing(pivotCoord, pointCoords);
    const finalAngle = initialAngle + angle;
    const distance = rhumbDistance(pivotCoord, pointCoords);
    const newCoords = getCoords(rhumbDestination(pivotCoord, distance, finalAngle));
    pointCoords[0] = newCoords[0];
    pointCoords[1] = newCoords[1];
  });
  return geojson;
}
var turf_transform_rotate_default = transformRotate;
export { turf_transform_rotate_default as default, transformRotate };
