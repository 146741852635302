<!-- <dialog-layout header="Users & Teams" (closeDialog)="close()"> -->
<dialog-layout header="Account users" (closeDialog)="close()">
  <div class="content">
    <div class="row space-between top-bar">
      <div class="row action-buttons">
        <button
          mat-button
          class="light-btn"
          [color]="selectedTab === Tab.USERS && !canAddUser ? 'primary' : ''"
          (click)="selectedTab === Tab.USERS ? addUser($event) : addTeam()"
          [disabled]="loading"
        >
          {{ selectedTab === Tab.USERS ? '+ Add user' : '+ Add team' }}
        </button>
        <ng-container *ngIf="selectedTab === Tab.USERS">
          <button mat-button class="light-btn" (click)="updateUsersClick()" [disabled]="loading">
            <mat-icon svgIcon="edit"></mat-icon>
            Update users
          </button>

          <button
            mat-button
            class="light-btn"
            [title]="usersDataSource.filter ? 'Download filtered data as CSV' : 'Download data as CSV'"
            (click)="downloadUsersCSV()"
            [disabled]="loading || !(usersDataSource.filteredData | isDefined)"
            #ref="ref"
            withElementRef
          >
            <mat-icon [svgIcon]="usersDataSource.filter ? 'export-csv-filter' : 'export-csv'"></mat-icon>
            <feature-hint
              [place]="FeatureHintPlace.DownloadUsersAndPermissionsBtn"
              [hintParentElement]="ref.elementRef.nativeElement"
              [dotOffsetX]="6"
              [dotOffsetY]="1"
            >
              <span>Download</span>
            </feature-hint>
          </button>
        </ng-container>
      </div>
      <div class="row search-field">
        <input
          class="field-input-control"
          [formControl]="searchField"
          [placeholder]="selectedTab === Tab.USERS ? 'Search user' : 'Search team'"
          (keydown)="searchFieldKeyEvent($event)"
        />
        <mat-icon class="search-icon" svgIcon="search"></mat-icon>
      </div>
    </div>

    <mat-tab-group (selectedTabChange)="tabChange($event.index)" class="users-and-teams-tabs">
      <mat-tab label="Users">
        <div class="full-tab" *ngIf="loading">Loading...</div>

        <div class="full-tab" *ngIf="!loading && usersDataSource.data.length === 0">No users were added yet</div>

        <mat-table
          [dataSource]="usersDataSource"
          [class.desktop]="isDesktop"
          [class.hidden]="loading || usersDataSource.data.length === 0"
          matSort
          #usersSort="matSort"
          matSortActive="name"
          matSortDirection="asc"
        >
          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <img class="avatar" [src]="element.avatarUrl" />
              <mat-icon
                *ngIf="element.accessLevel === AccessLevelEnum.TENANTADMIN"
                class="admin-icon"
                title="Account admin"
                svgIcon="star-circle"
              ></mat-icon>
              <span [title]="element.name">{{ element.name }}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="email">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Email</mat-header-cell>
            <mat-cell *matCellDef="let element"
              ><span [title]="element.email">{{ element.email }}</span></mat-cell
            >
          </ng-container>

          <ng-container matColumnDef="accessLevel">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Permission Level</mat-header-cell>
            <mat-cell *matCellDef="let element"
              ><span [title]="getAccessLevelLabel(element.accessLevel)">
                <div class="row">
                  <div *ngIf="element.accessLevel === AccessLevelEnum.OPERATORUSER">Operator</div>
                  <ng-container *ngIf="ACCOUNT_USER_ACCESS_LEVELS.includes(element.accessLevel)">
                    {{ getAccessLevelLabel(element.accessLevel) }}
                  </ng-container>
                </div>
              </span></mat-cell
            >
          </ng-container>

          <ng-container matColumnDef="teams">
            <mat-header-cell *matHeaderCellDef>Teams</mat-header-cell>
            <mat-cell *matCellDef="let element"
              ><span [title]="listTitle(element.teams)">{{ listTitle(element.teams) }}</span></mat-cell
            >
          </ng-container>

          <ng-container matColumnDef="sites">
            <mat-header-cell *matHeaderCellDef>Sites</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span *ngIf="element.accessLevel !== AccessLevelEnum.TENANTADMIN; else allSites" [title]="listTitle(element.sites)">
                {{ element.sites.length || '-' }}
              </span>
              <ng-template #allSites> All </ng-template>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="lastSuccessfulLoginTime">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Last Login</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span [title]="element.lastSuccessfulLoginTime | formatDateNumeral : true : true">{{
                element.lastSuccessfulLoginTime | formatDateNumeral : true : true
              }}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="edit">
            <mat-header-cell *matHeaderCellDef class="end-column"></mat-header-cell>
            <mat-cell mat-cell *matCellDef="let element" class="end-column">
              <button
                mat-icon-button
                [title]="isCurrentUser(element) ? 'Cannot edit current user' : 'Edit user'"
                (click)="editUser(element)"
                [disabled]="isCurrentUser(element)"
              >
                <mat-icon>edit</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="delete">
            <mat-header-cell *matHeaderCellDef class="end-column"></mat-header-cell>
            <mat-cell mat-cell *matCellDef="let element" class="end-column">
              <button
                mat-icon-button
                [title]="isCurrentUser(element) ? 'Cannot delete current user' : 'Delete user'"
                (click)="deleteUser(element)"
                [disabled]="isCurrentUser(element)"
              >
                <mat-icon>delete</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="usersCols; sticky: true"></mat-header-row>
          <mat-row *matRowDef="let userRow; columns: usersCols"></mat-row>
        </mat-table>
      </mat-tab>

      <mat-tab label="Teams">
        <div class="full-tab" *ngIf="loading">Loading...</div>
        <div class="full-tab" *ngIf="!loading && teamsDataSource.data.length === 0">No teams were added yet</div>

        <mat-table
          [dataSource]="teamsDataSource"
          [class.hidden]="loading || teamsDataSource.data.length === 0"
          matSort
          #teamsSort="matSort"
          matSortActive="name"
          matSortDirection="asc"
        >
          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
            <mat-cell *matCellDef="let element"
              ><span [title]="element.name">{{ element.name }}</span></mat-cell
            >
          </ng-container>
          <ng-container matColumnDef="description">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Description</mat-header-cell>
            <mat-cell *matCellDef="let element"
              ><span [title]="element.description">{{ element.description }}</span></mat-cell
            >
          </ng-container>
          <ng-container matColumnDef="users">
            <mat-header-cell *matHeaderCellDef>Users</mat-header-cell>
            <mat-cell *matCellDef="let element"
              ><span [title]="listTitle(element.users)">{{ listTitle(element.users) }}</span></mat-cell
            >
          </ng-container>

          <ng-container matColumnDef="edit">
            <mat-header-cell *matHeaderCellDef class="end-column"></mat-header-cell>
            <mat-cell mat-cell *matCellDef="let element" class="end-column">
              <button mat-icon-button title="Edit" (click)="editTeam(element)">
                <mat-icon>edit</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="delete">
            <mat-header-cell *matHeaderCellDef class="end-column"></mat-header-cell>
            <mat-cell mat-cell *matCellDef="let element" class="end-column">
              <button mat-icon-button title="Delete" (click)="deleteTeam(element)">
                <mat-icon>delete</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="teamsCols; sticky: true"></mat-header-row>
          <mat-row *matRowDef="let teamRow; columns: teamsCols"></mat-row>
        </mat-table>
      </mat-tab>
    </mat-tab-group>
  </div>
</dialog-layout>
