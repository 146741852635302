<ngx-file-drop
  (onFileDrop)="onDrop($event)"
  [accept]="accept"
  [showBrowseBtn]="false"
  class="row full-container full-height"
  [dropZoneClassName]="'custom-drop-zone row full-container full-height' + (disabled ? ' disabled-drop' : '')"
  contentClassName="custom-drop-content row full-container full-height justify-center"
  [disabled]="disabled"
>
  <ng-template ngx-file-drop-content-tmp>
    <div class="column empty-container" *ngIf="!disabled && isEmpty">
      <div class="row top-text">
        <a (click)="openFileSelector()" i18n="@@shared.dragAndDropImageControl.topTextUploadOption">Upload a file</a>
        <ng-container i18n="@@shared.dragAndDropImageControl.topTextOrDragOption">or drag and drop</ng-container>
      </div>
      <div class="bottom-text">{{ bottomText }}</div>
    </div>

    <div class="row justify-center image-container" *ngIf="!isEmpty">
      <img [src]="imageUrl" />
      <button class="action-button delete-button" type="button" mat-icon-button title="Remove" i18n-title="@@general.actionButtons.remove" (click)="onDelete()">
        <mat-icon>delete</mat-icon>
      </button>
      <button class="action-button download-button" *ngIf="imageUrl && isValid && !isEmpty" type="button" mat-icon-button title="Download" i18n-title="@@general.actionButtons.download" (click)="onDownload()">
        <mat-icon>download</mat-icon>
      </button>
    </div>
  </ng-template>
</ngx-file-drop>