import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of, tap } from 'rxjs';

import { GetAllAnnouncementsResponse } from '../../../../../generated/nms/model/getAllAnnouncementsResponse';
import { REQUIRED_ACCESS_LEVEL_HEADER } from '../../../../auth/state/auth.utils';
import PERMISSIONS from '../../../../auth/state/permissions';
import { getServiceUrl } from '../../../utils/backend-services';
import { isDefined } from '../../../utils/general';
import { Notification } from '../../notifications-menu/state/notifications.store';
import { AnnouncementsQuery } from './announcements.query';
import { AnnouncementsStore } from './announcements.store';

const ANNOUNCEMENTS_LIMIT = 10;

@Injectable({
  providedIn: 'root'
})
export class AnnouncementsService {
  constructor(private http: HttpClient, private store: AnnouncementsStore, private query: AnnouncementsQuery) {}

  fetchUserAnnouncements() {
    return this.http
      .get(`${getServiceUrl('nms')}/announcements/getAllUserAnnouncements?numberOfUserAnnouncements=${ANNOUNCEMENTS_LIMIT}`)
      .pipe(
        tap((response: GetAllAnnouncementsResponse) => {
          if (isDefined(response?.announcements)) {
            this.store.upsertMany(response.announcements);
          }
        })
      );
  }

  toggleRead(id: string, read: boolean) {
    return this.http
      .put(`${getServiceUrl('nms')}/announcements/${id}/markAsRead?read=${read}`, null, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.announcements.update }
      })
      .pipe(
        tap(() => {
          this.store.update(id, { read, seen: true });
        })
      );
  }

  markSeen() {
    // If all announcements are seen, do nothing
    if (this.query.getUnseenCount() === 0) {
      return of(null);
    }

    return this.http
      .put(`${getServiceUrl('nms')}/announcements/markAsSeen`, null, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.announcements.update }
      })
      .pipe(
        tap(() => {
          this.store.update((entity: Notification) => !entity.seen, { seen: true });
        })
      );
  }

  updateFirstLaunch() {
    this.store.update({ firstLaunch: false });
  }
}
