import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SafeResourceUrl } from '@angular/platform-browser';

export interface IframeDialogData {
  url: SafeResourceUrl;
}

@Component({
  template: `
    <dialog-layout (closeDialog)="close()">
      <iframe [src]="data.url"></iframe>
    </dialog-layout>
  `,
  styleUrls: ['./iframe-dialog.component.scss']
})
export class IframeDialogComponent implements OnInit {
  constructor(private dialog: MatDialogRef<IframeDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: IframeDialogData) {}

  ngOnInit() {}

  close() {
    this.dialog.close();
  }
}
