import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';

import { Group, Layer } from '../../../detailed-site/state/detailed-site-entities/detailed-site-entities.model';
import { DeviceService } from '../../services/device.service';

export interface GroupsLayersManagerData {
  type: 'group' | 'layer';
  values$: Observable<Array<Group | Layer>>;
  onCreate: () => void;
  onEdit: (value: Group | Layer) => void;
  onDelete: (id: string) => void;
}

@Component({
  selector: 'groups-layers-manager',
  templateUrl: './groups-layers-manager.component.html',
  styleUrls: ['./groups-layers-manager.component.scss']
})
export class GroupsLayersManagerComponent implements OnInit {
  currentValue: Group | Layer;
  dialogHeader: string;

  constructor(
    private dialogRef: MatDialogRef<GroupsLayersManagerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GroupsLayersManagerData,
    private device: DeviceService
  ) {}

  ngOnInit() {
    this.dialogHeader =
      this.data.type === 'layer'
        ? $localize`:@@shared.groupsLayersManager.dialogHeaderLayers:Manage layers`
        : $localize`:@@shared.groupsLayersManager.dialogHeaderGroups:Manage groups`;
  }

  close() {
    this.dialogRef.close();
  }

  menuOpened(value) {
    this.currentValue = value;
  }

  menuClosed() {
    this.currentValue = null;
  }

  create() {
    if (this.data.onCreate) {
      this.data.onCreate();
    }
  }

  edit() {
    if (this.data.onEdit && this.currentValue) {
      this.data.onEdit(this.currentValue);
      this.currentValue = null;
    }
  }

  delete() {
    if (this.data.onDelete && this.currentValue) {
      this.data.onDelete(this.currentValue.id);
      this.currentValue = null;
    }
  }

  get isDesktop() {
    return this.device.isDesktop();
  }
}
