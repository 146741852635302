import { Injectable } from '@angular/core';
import { MapsManagerService } from '@datumate/angular-cesium';
import { ImageryLayer } from 'cesium';

import { MapStyle } from '../utils/cesium-common';

@Injectable()
export class MapStyleService {
  constructor(private mapsManager: MapsManagerService) {}

  setMapStyle(mapId: string, style: MapStyle) {
    const map = this.mapsManager.getMap(mapId);
    const viewer = map.getCesiumViewer();
    const layers: ImageryLayer[] = viewer.imageryLayers._layers;

    layers.forEach((layer, layerIdx) => {
      if (Object.keys(MapStyle).includes(`${layerIdx}`)) {
        layer.show = layerIdx === style;
      }
    });
  }
}
