import { Component, Input } from '@angular/core';

import { DeviceService } from '../services/device.service';

@Component({
  selector: 'underline-tooltip',
  templateUrl: './underline-tooltip.component.html',
  styleUrls: ['./underline-tooltip.component.scss']
})
export class UnderlineTooltipComponent {
  @Input() text?: string;

  isTablet = this.device.isTablet();

  constructor(private device: DeviceService) {}
}
