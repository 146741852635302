import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { groupBy } from 'lodash';
import moment from 'moment';
import { combineLatest, EMPTY, forkJoin, merge, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { CreateCustomFieldRequest } from '../../generated/file/model/createCustomFieldRequest';
import { CreateCustomFieldsRequest } from '../../generated/file/model/createCustomFieldsRequest';
import { DeleteCustomFieldsRequest } from '../../generated/file/model/deleteCustomFieldsRequest';
import { GetCustomFieldResponses } from '../../generated/file/model/getCustomFieldResponses';
import { GetPreDefinedFieldResponses } from '../../generated/file/model/getPreDefinedFieldResponses';
import { UpdateCustomFieldRequest } from '../../generated/file/model/updateCustomFieldRequest';
import { UpdateCustomFieldsRequest } from '../../generated/file/model/updateCustomFieldsRequest';
import { CreateClientRequest } from '../../generated/integration/model/createClientRequest';
import { GetAllSiteAssociationsResponse } from '../../generated/integration/model/getAllSiteAssociationsResponse';
import { GetAllTenantIntegrationsResponse } from '../../generated/integration/model/getAllTenantIntegrationsResponse';
import { GetRemoteSitesResponse } from '../../generated/integration/model/getRemoteSitesResponse';
import { GetValidClientResponse } from '../../generated/integration/model/getValidClientResponse';
import { CreateSiteRequest } from '../../generated/tenant/model/createSiteRequest';
import { GetAllCategoriesResponse } from '../../generated/tenant/model/getAllCategoriesResponse';
import { GetAllCoordinateSystemsResponse } from '../../generated/tenant/model/getAllCoordinateSystemsResponse';
import { GetAllSiteGroupsResponse } from '../../generated/tenant/model/getAllSiteGroupsResponse';
import { GetAllSitesResponse } from '../../generated/tenant/model/getAllSitesResponse';
import { GetTenantFeatureFlagNamesResponse } from '../../generated/tenant/model/getTenantFeatureFlagNamesResponse';
import { UpdateSiteRequest } from '../../generated/tenant/model/updateSiteRequest';
import { GetAllUserTitlesListResponse } from '../../generated/ums/model/getAllUserTitlesListResponse';
import { GetUsersListResponse } from '../../generated/ums/model/getUsersListResponse';
import { AuthQuery } from '../auth/state/auth.query';
import { REQUIRED_ACCESS_LEVEL_HEADER } from '../auth/state/auth.utils';
import PERMISSIONS from '../auth/state/permissions';
import {
  CustomPropertyInteractionType,
  DesignCustomProperty
} from '../detailed-site/state/detailed-site-designs/detailed-site-designs.model';
import { AnalyticsService } from '../shared/services/analytics.service';
import { SnackBarService } from '../shared/services/snackbar.service';
import { getServiceUrl, isLocalCS } from '../shared/utils/backend-services';
import { isDefined } from '../shared/utils/general';
import {
  ArtifactEnum,
  CoordinateSystem,
  FeatureFlagEnum,
  IntegrationEnum,
  IntegrationProject,
  Site,
  SiteGroup,
  SiteType,
  TenantConfig,
  TenantIntegration,
  User
} from './tenant.model';
import { TenantQuery } from './tenant.query';
import { TenantStore } from './tenant.store';

@Injectable({ providedIn: 'root' })
export class TenantService {
  constructor(
    private store: TenantStore,
    private query: TenantQuery,
    private authQuery: AuthQuery,
    private http: HttpClient,
    private analyticsService: AnalyticsService,
    private snackbar: SnackBarService
  ) {}

  init() {
    this.store.setLoading(true);
    return forkJoin([this.fetchFeatureFlags(), this.fetchConfig()]).pipe(
      switchMap(() =>
        merge(
          this.fetchTenantSites().pipe(tap(() => this.store.setLoading(false))),
          this.fetchTenantUsers(),
          this.fetchUsersEmployeeTitles()
        )
      )
    );
  }

  private fetchFeatureFlags() {
    return this.http.get(`${getServiceUrl('tenant')}/current/featureFlags`).pipe(
      map((response: GetTenantFeatureFlagNamesResponse) => {
        return response?.featureFlags.reduce((mapping, flag) => {
          mapping[flag] = true;
          return mapping;
        }, {});
      }),
      tap((featureFlags: { [featureFlag in FeatureFlagEnum]?: boolean }) => {
        if (featureFlags) {
          this.store.setFeatureFlags(featureFlags);
        }
      })
    );
  }

  private fetchConfig() {
    return this.http
      .get(`${getServiceUrl('package')}/packageCounter`, { headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.configRead } })
      .pipe(tap((config: TenantConfig) => this.store.upsertTenantConfig(config)));
  }

  private fetchTenantSites() {
    const options = { headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.sites.read } };
    const requests = [
      this.http.get(`${getServiceUrl('tenant')}/sites`, options),
      this.http.get(`${getServiceUrl('integration')}/association/sites`, options)
    ];

    return combineLatest(requests).pipe(
      map(([sitesResponse, siteAssociationsResponse]: [GetAllSitesResponse, GetAllSiteAssociationsResponse]) => ({
        sites: sitesResponse?.sites,
        siteAssociations: siteAssociationsResponse?.siteAssociations
      })),
      tap(({ sites, siteAssociations }) => {
        if (isDefined(sites)) {
          if (isDefined(siteAssociations)) {
            // Map siteId to site association
            const siteAssociationsMap = siteAssociations.reduce((sum, association) => {
              if (!sum[association.siteId]) {
                sum[association.siteId] = [];
              }
              sum[association.siteId].push(association);
              return sum;
            }, {} as Record<string, IntegrationProject[]>);

            // Add associations to each site
            sites = sites.map(site => ({
              ...site,
              associations: isDefined(siteAssociationsMap[site.id]) ? siteAssociationsMap[site.id] : null
            }));
          }

          this.store.setSites(sites);
        }
      })
    );
  }

  fetchSiteTypes() {
    const siteTypes = this.query.getSiteTypes();
    if (siteTypes) {
      return of(siteTypes);
    }

    return this.http
      .get(`${getServiceUrl('tenant')}/sites/categories`, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.sites.read }
      })
      .pipe(
        map((response: GetAllCategoriesResponse) => response?.categories),
        tap((siteTypes: SiteType[]) => {
          this.store.setSiteTypes(siteTypes);
        })
      );
  }

  fetchTenantIntegrations() {
    const tenantIntegrations = this.query.getTenantIntegrations();
    if (isDefined(tenantIntegrations)) {
      return of(tenantIntegrations);
    }

    return this.http.get(`${getServiceUrl('integration')}/integrations`).pipe(
      map((response: GetAllTenantIntegrationsResponse) => response?.integrationsResponseList),
      tap((tenantIntegrations: TenantIntegration[]) => {
        if (isDefined(tenantIntegrations)) {
          this.store.setTenantIntegrations(tenantIntegrations);
        }
      })
    );
  }

  addSite(site: Site) {
    let prerequisiteRequests: Observable<any> = of(null);
    if (site.siteGroup?.unsaved) {
      prerequisiteRequests = this.createSiteGroup(site.siteGroup.name).pipe(
        tap(newSiteGroup => {
          site = { ...site, siteGroup: newSiteGroup };
        })
      );
    }

    return prerequisiteRequests.pipe(
      switchMap(() => {
        const url = `${getServiceUrl('tenant')}/sites`;
        const siteData: CreateSiteRequest = {
          address: site.address,
          categoryId: site.category && site.category.id,
          siteGroupId: site.siteGroup && site.siteGroup.id,
          coordinateSystemId: site.coordinateSystem && site.coordinateSystem.id,
          customCategory: site.customCategory,
          description: site.description,
          latitude: site.latitude,
          longitude: site.longitude,
          name: site.name,
          endDate: moment(site.endDate).locale('en').format() as any, // Turn to string to avoid issues with timezone
          startDate: moment(site.startDate).locale('en').format() as any, // Turn to string to avoid issues with timezone
          units: site.units,
          volumeUnits: site.volumeUnits,
          maxNumberOfFlights: site.maxNumberOfFlights,
          usersIds: site.users && site.users.map(user => user.id)
        };
        return this.http.post(url, siteData, { headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.sites.create } }).pipe(
          map((siteResponse: { id: string }) => ({
            id: siteResponse.id,
            ...site
          })),
          switchMap(newSite =>
            this.addSiteAssociations(newSite, site?.associations).pipe(
              catchError(error => {
                const message = $localize`:@@allSites.siteDialog.ErrorAddingSiteAssociations:Error adding site associations`;
                this.snackbar.openError(message, 'Error adding site associations', error);
                return of(null);
              }),
              map(() => newSite)
            )
          ),
          tap(newSite => {
            this.store.addSite(newSite);
            this.analyticsService.addSite(newSite);
          })
        );
      })
    );
  }

  updateSite(id: string, updatedSite: Partial<Site>, keepAutodeskCurrentVersions?: boolean): Observable<Site> {
    let prerequisiteRequests: Observable<any> = of(null);
    if (updatedSite.siteGroup?.unsaved) {
      prerequisiteRequests = this.createSiteGroup(updatedSite.siteGroup.name).pipe(
        tap(newSiteGroup => {
          updatedSite = { ...updatedSite, siteGroup: newSiteGroup };
        })
      );
    }

    return prerequisiteRequests.pipe(
      switchMap(() => {
        const oldSite = this.query.getSite(id);
        const site: Site = { ...oldSite, ...updatedSite };
        const siteData: UpdateSiteRequest = {
          name: site.name,
          description: site.description,
          address: site.address,
          categoryId: site.category && site.category.id,
          customCategory: site.customCategory,
          coordinateSystemId: site.coordinateSystem && site.coordinateSystem.id,
          siteGroupId: site.siteGroup && site.siteGroup.id,
          endDate: site.endDate,
          startDate: site.startDate,
          latitude: site.latitude,
          longitude: site.longitude,
          units: site.units,
          volumeUnits: site.volumeUnits,
          maxNumberOfFlights: site.maxNumberOfFlights,
          usersIds: site.users?.map(user => user.id)
        };
        const url = `${getServiceUrl('tenant')}/sites/${site.id}`;
        return this.http.put(url, siteData, { headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.sites.update } }).pipe(
          switchMap(() => {
            const oldSiteAssociations = oldSite.associations;
            const oldSiteAssociationIds = oldSiteAssociations?.map(association => association.remoteSiteId);

            const projectsToAdd = site.associations?.filter(association => !oldSiteAssociationIds?.includes(association.remoteSiteId));
            const projectsToDelete = oldSiteAssociations?.filter(
              association => !site.associations?.map(association => association.remoteSiteId)?.includes(association.remoteSiteId)
            );
            const addObservable = this.addSiteAssociations(site, projectsToAdd).pipe(
              catchError(error => {
                const message = $localize`:@@allSites.siteDialog.ErrorAddingSiteAssociations:Error adding site associations`;
                this.snackbar.openError(message, 'Error adding site associations', error);
                return of(null);
              })
            );
            const deleteObservable = this.deleteSiteAssociations(site, projectsToDelete, keepAutodeskCurrentVersions).pipe(
              catchError(error => {
                const message = $localize`:@@allSites.siteDialog.ErrorDeletingSiteAssociations:Error deleting site associations`;
                this.snackbar.openError(message, 'Error deleting site associations', error);
                return of(null);
              })
            );
            return deleteObservable.pipe(
              switchMap(() => addObservable),
              map(() => site)
            );
          }),
          tap(() => {
            this.store.updateSite(site);
            this.analyticsService.editSite(site);
          })
        );
      })
    );
  }

  addSiteAssociations(site: Site, projectsToAdd: IntegrationProject[]) {
    const options = { headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.sites.update } };
    return isDefined(projectsToAdd)
      ? forkJoin(
          projectsToAdd.map(project =>
            this.http
              .post(
                `${getServiceUrl('integration')}/association/sites/${site.id}`,
                { artifact: project.artifact, id: project.remoteSiteId, integration: project.integration, name: project.name },
                options
              )
              .pipe(tap(() => this.analyticsService.updateSiteAssociation(site, project)))
          )
        )
      : of(null);
  }

  deleteSiteAssociations(site: Site, projectsToDelete: IntegrationProject[], keepAutodeskCurrentVersions: boolean) {
    const siteId = site.id;
    const options = { headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.sites.update } };
    return isDefined(projectsToDelete)
      ? forkJoin(
          projectsToDelete.map(project => {
            const integration = project.integration;
            const artifact = project.artifact;
            const keepCurrentVersions = !!(integration === IntegrationEnum.AUTODESK && ArtifactEnum.DESIGN && keepAutodeskCurrentVersions);
            return this.http
              .delete(
                `${getServiceUrl(
                  'integration'
                )}/association/sites/${siteId}/integrations/${integration}/artifacts/${artifact}?keepCurrentVersions=${keepCurrentVersions}`,
                options
              )
              .pipe(tap(() => this.analyticsService.removeSiteAssociation(site, project, keepAutodeskCurrentVersions)));
          })
        )
      : of(null);
  }

  validateIntegrationClientData(clientData: CreateClientRequest): Observable<GetValidClientResponse> {
    const tenantId = this.authQuery.getActiveTenantId();
    const options = { headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.designIntegration } };
    return this.http.post(`${getServiceUrl('integration')}/association/tenants/${tenantId}/validateAndStore`, clientData, options);
  }

  deleteSite(site: Site) {
    const tenantId = this.authQuery.getActiveTenantId();
    const url = `${getServiceUrl('tenant')}/tenants/${tenantId}/sites/${site.id}`;
    return this.http.delete(url, { headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.sites.delete } }).pipe(
      tap(() => {
        this.deleteSiteLocal(site.id);
        this.analyticsService.deleteSite(site);
      })
    );
  }

  deleteSiteLocal(siteId: string) {
    this.store.deleteSite(siteId);
  }

  updateTasksCounter() {
    this.store.updateTasksCounter();
  }

  updateImagesCounter(imagesCount: number) {
    this.store.updateImagesCounter(imagesCount);
  }

  fetchCoordinateSystems() {
    const coordinateSystems = this.query.getCoordinateSystems();
    if (coordinateSystems) {
      return of(coordinateSystems);
    }

    return this.http
      .get(`${getServiceUrl('tenant')}/coordinateSystems`, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.sites.read }
      })
      .pipe(
        map((response: GetAllCoordinateSystemsResponse) => {
          if (response && response.coordinateSystems) {
            return response.coordinateSystems.sort((s1, s2) => s1.code?.localeCompare(s2.code));
          }
        }),
        tap((systems: CoordinateSystem[]) => {
          // Remove local CS coordinate system from list
          const localCSIndex = systems.findIndex(system => isLocalCS(system));
          if (localCSIndex > -1) {
            systems.splice(localCSIndex, 1);
          }

          this.store.setCoordinateSystems(systems);
        })
      );
  }

  fetchIntegrationProjects(integration: string) {
    const integrationProjects = this.query.getIntegrationProjects();
    if (integrationProjects?.[integration]) {
      return of(integrationProjects[integration]);
    }
    return this.http
      .get(`${getServiceUrl('integration')}/association/remoteSites`, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.sites.read }
      })
      .pipe(
        map((response: GetRemoteSitesResponse) =>
          response.remoteSites.map(project => ({
            ...project,
            remoteSiteId: project.id
          }))
        ),
        tap((projects: IntegrationProject[]) => {
          const integrationGroups = groupBy(projects, 'integration');
          Object.entries(integrationGroups).forEach(([integration, projects]) => this.store.setIntegrationProjects(integration, projects));
        }),
        map(() => this.query.getIntegrationProjects()?.[integration])
      );
  }

  fetchSiteGroups() {
    const siteGroups = this.query.getSiteGroups();
    if (siteGroups) {
      return of(siteGroups);
    }

    return this.http
      .get(`${getServiceUrl('tenant')}/siteGroups`, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.siteGroups.read }
      })
      .pipe(
        map((response: GetAllSiteGroupsResponse) => response.siteGroups),
        tap((groups: SiteGroup[]) => this.store.setSiteGroups(groups))
      );
  }

  createSiteGroup(name: string) {
    return this.http
      .post(
        `${getServiceUrl('tenant')}/siteGroups`,
        { name },
        { headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.siteGroups.create } }
      )
      .pipe(
        map((response: { id: string }) => {
          const newSiteGroup: SiteGroup = { name, id: response.id };
          this.store.addSiteGroup(newSiteGroup);
          return newSiteGroup;
        })
      );
  }

  private fetchTenantUsers() {
    return this.http
      .get(`${getServiceUrl('ums')}/users`, { headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.usersReadSimple } })
      .pipe(
        tap((response: GetUsersListResponse) => {
          if (response && response.usersList) {
            this.store.setUsers(response.usersList);
          }
        })
      );
  }

  fetchUsersEmployeeTitles() {
    return this.http.get(`${getServiceUrl('ums')}/users/employeeTitles`).pipe(
      tap((response: GetAllUserTitlesListResponse) => {
        if (response && response.getUserTitleListResponse) {
          const sortedData = response.getUserTitleListResponse.sort((a, b) => {
            const firstTitle = a.title.toLowerCase();
            const secondTitle = b.title.toLowerCase();
            return firstTitle === 'other' ? 1 : secondTitle === 'other' ? -1 : a.title.localeCompare(b.title);
          });
          this.store.setEmployeeTitles(sortedData);
        }
      }),
      catchError(err => {
        console.error(`Failed to fetch UsersEmployeeTitles: ${err.error?.message}`, err);
        return EMPTY;
      })
    );
  }

  addUser(user: User) {
    this.store.addUser(user);
  }

  updateUser(user: User) {
    this.store.updateUser(user);
  }

  deleteUser(user: User) {
    this.store.deleteUser(user);
  }

  fetchCustomProperties(siteId: string) {
    return this.http
      .get(`${getServiceUrl('file')}/featureData/sites/${siteId}/customFields`, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.siteCustomProperties.read }
      })
      .pipe(map((result: GetCustomFieldResponses) => result?.customFieldResponses));
  }

  fetchPreDefinedProperties() {
    return this.http
      .get(`${getServiceUrl('file')}/featureData/preDefinedFields`, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.siteCustomProperties.create }
      })
      .pipe(map((result: GetPreDefinedFieldResponses) => result?.preDefinedFieldResponses));
  }

  createSiteCustomProperties(siteId: string, customProperties: DesignCustomProperty[]) {
    const site = this.query.getSite(siteId);
    const properties: CreateCustomFieldRequest[] = customProperties.map(({ name, valueType }) => ({ name, valueType }));
    const request: CreateCustomFieldsRequest = { requestList: properties };

    this.analyticsService.siteCustomPropertiesInteraction(site, customProperties, CustomPropertyInteractionType.CREATE);

    return this.http
      .post(`${getServiceUrl('file')}/featureData/sites/${siteId}/customFields`, request, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.siteCustomProperties.create }
      })
      .pipe(map((result: GetCustomFieldResponses) => result?.customFieldResponses));
  }

  updateSiteCustomProperties(siteId: string, customProperties: DesignCustomProperty[]) {
    const site = this.query.getSite(siteId);
    const properties: UpdateCustomFieldRequest[] = customProperties.map(p => ({ id: p.id, name: p.name }));
    const request: UpdateCustomFieldsRequest = { requestList: properties };

    this.analyticsService.siteCustomPropertiesInteraction(site, customProperties, CustomPropertyInteractionType.UPDATE);

    return this.http
      .put(`${getServiceUrl('file')}/featureData/sites/${siteId}/customFields`, request, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.siteCustomProperties.update }
      })
      .pipe(map((result: GetCustomFieldResponses) => result?.customFieldResponses));
  }

  deleteCustomProperties(siteId: string, propertiesIds: string[]) {
    const request: DeleteCustomFieldsRequest = { idList: propertiesIds };
    return this.http.delete(`${getServiceUrl('file')}/featureData/sites/${siteId}/customField`, {
      body: request,
      headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.siteCustomProperties.delete }
    });
  }
}
