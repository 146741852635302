import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';

export interface LoadingModalData {
  title: string;
  closeInfo?: {
    text: string;
    buttonCaption?: string;
  };
  disableClose?: boolean;
  loadingProgress$?: Observable<number>;
}

export interface LoadingModalCloseResult {
  userClose?: boolean;
}

@Component({
  selector: 'loading-modal',
  template: `
    <div class="side-line"></div>
    <div class="container">
      <div class="spinner-progress-wrap" *ngIf="data?.loadingProgress$ | async | isDefined; else datuLoader">
        <spinner-progress [progressValue]="data?.loadingProgress$ | async" [spinnerSize]="80"></spinner-progress>
      </div>
      <ng-template #datuLoader>
        <datu-loader></datu-loader>
      </ng-template>
      <label class="title">{{ data.title }}</label>
      <label class="close-info" *ngIf="data.closeInfo?.text | isDefined">{{ data.closeInfo.text }}</label>
      <button mat-flat-button class="dark-btn" (click)="closeModal()" *ngIf="data.closeInfo?.buttonCaption | isDefined">
        {{ data.closeInfo.buttonCaption }}
      </button>
    </div>
  `,
  styleUrls: ['./loading-modal.component.scss']
})
export class LoadingModalComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<LoadingModalComponent, LoadingModalCloseResult>,
    @Inject(MAT_DIALOG_DATA) public data: LoadingModalData
  ) {}

  ngOnInit() {}

  closeModal() {
    this.dialogRef.close({ userClose: true });
  }
}
