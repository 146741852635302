// index.ts
import { cleanCoords } from "@turf/clean-coords";
import { clone } from "@turf/clone";
import { geomEach } from "@turf/meta";
import { isObject } from "@turf/helpers";

// lib/simplify.js
function getSqDist(p1, p2) {
  var dx = p1[0] - p2[0],
    dy = p1[1] - p2[1];
  return dx * dx + dy * dy;
}
function getSqSegDist(p, p1, p2) {
  var x = p1[0],
    y = p1[1],
    dx = p2[0] - x,
    dy = p2[1] - y;
  if (dx !== 0 || dy !== 0) {
    var t = ((p[0] - x) * dx + (p[1] - y) * dy) / (dx * dx + dy * dy);
    if (t > 1) {
      x = p2[0];
      y = p2[1];
    } else if (t > 0) {
      x += dx * t;
      y += dy * t;
    }
  }
  dx = p[0] - x;
  dy = p[1] - y;
  return dx * dx + dy * dy;
}
function simplifyRadialDist(points, sqTolerance) {
  var prevPoint = points[0],
    newPoints = [prevPoint],
    point;
  for (var i = 1, len = points.length; i < len; i++) {
    point = points[i];
    if (getSqDist(point, prevPoint) > sqTolerance) {
      newPoints.push(point);
      prevPoint = point;
    }
  }
  if (prevPoint !== point) newPoints.push(point);
  return newPoints;
}
function simplifyDPStep(points, first, last, sqTolerance, simplified) {
  var maxSqDist = sqTolerance,
    index;
  for (var i = first + 1; i < last; i++) {
    var sqDist = getSqSegDist(points[i], points[first], points[last]);
    if (sqDist > maxSqDist) {
      index = i;
      maxSqDist = sqDist;
    }
  }
  if (maxSqDist > sqTolerance) {
    if (index - first > 1) simplifyDPStep(points, first, index, sqTolerance, simplified);
    simplified.push(points[index]);
    if (last - index > 1) simplifyDPStep(points, index, last, sqTolerance, simplified);
  }
}
function simplifyDouglasPeucker(points, sqTolerance) {
  var last = points.length - 1;
  var simplified = [points[0]];
  simplifyDPStep(points, 0, last, sqTolerance, simplified);
  simplified.push(points[last]);
  return simplified;
}
function simplify(points, tolerance, highestQuality) {
  if (points.length <= 2) return points;
  var sqTolerance = tolerance !== void 0 ? tolerance * tolerance : 1;
  points = highestQuality ? points : simplifyRadialDist(points, sqTolerance);
  points = simplifyDouglasPeucker(points, sqTolerance);
  return points;
}

// index.ts
function simplify2(geojson, options = {}) {
  var _a, _b, _c;
  options = options != null ? options : {};
  if (!isObject(options)) throw new Error("options is invalid");
  const tolerance = (_a = options.tolerance) != null ? _a : 1;
  const highQuality = (_b = options.highQuality) != null ? _b : false;
  const mutate = (_c = options.mutate) != null ? _c : false;
  if (!geojson) throw new Error("geojson is required");
  if (tolerance && tolerance < 0) throw new Error("invalid tolerance");
  if (mutate !== true) geojson = clone(geojson);
  geomEach(geojson, function (geom) {
    simplifyGeom(geom, tolerance, highQuality);
  });
  return geojson;
}
function simplifyGeom(geometry, tolerance, highQuality) {
  const type = geometry.type;
  if (type === "Point" || type === "MultiPoint") return geometry;
  cleanCoords(geometry, {
    mutate: true
  });
  if (type !== "GeometryCollection") {
    switch (type) {
      case "LineString":
        geometry.coordinates = simplify(geometry.coordinates, tolerance, highQuality);
        break;
      case "MultiLineString":
        geometry.coordinates = geometry.coordinates.map(lines => simplify(lines, tolerance, highQuality));
        break;
      case "Polygon":
        geometry.coordinates = simplifyPolygon(geometry.coordinates, tolerance, highQuality);
        break;
      case "MultiPolygon":
        geometry.coordinates = geometry.coordinates.map(rings => simplifyPolygon(rings, tolerance, highQuality));
    }
  }
  return geometry;
}
function simplifyPolygon(coordinates, tolerance, highQuality) {
  return coordinates.map(function (ring) {
    if (ring.length < 4) {
      throw new Error("invalid polygon");
    }
    let ringTolerance = tolerance;
    let simpleRing = simplify(ring, ringTolerance, highQuality);
    while (!checkValidity(simpleRing)) {
      ringTolerance -= ringTolerance * 0.01;
      simpleRing = simplify(ring, ringTolerance, highQuality);
    }
    if (simpleRing[simpleRing.length - 1][0] !== simpleRing[0][0] || simpleRing[simpleRing.length - 1][1] !== simpleRing[0][1]) {
      simpleRing.push(simpleRing[0]);
    }
    return simpleRing;
  });
}
function checkValidity(ring) {
  if (ring.length < 3) return false;
  return !(ring.length === 3 && ring[2][0] === ring[0][0] && ring[2][1] === ring[0][1]);
}
var turf_simplify_default = simplify2;
export { turf_simplify_default as default, simplify2 as simplify };
