import { Pipe, PipeTransform } from '@angular/core';

import { LocaleService } from '../services/locale.service';

@Pipe({
  name: 'formatShortDate'
})
export class FormatShortDatePipe implements PipeTransform {
  constructor(private localeService: LocaleService) {}

  transform(date: Date | string, isUTC = false): string {
    return date ? this.localeService.formatShortDate({ date }, isUTC) : '-';
  }
}
