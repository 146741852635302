import { Component, EventEmitter, Input, Output } from '@angular/core';

import { MapStyle } from '../utils/cesium-common';
import { MapStyleService } from './map-style.service';

@Component({
  selector: 'map-style-button',
  templateUrl: './map-style-button.component.html',
  styleUrls: ['./map-style-button.component.scss'],
  providers: [MapStyleService]
})
export class MapStyleButtonComponent {
  defaultMapStyles = [
    { icon: 'map-style-street', label: 'Street map', style: MapStyle.STREET },
    { icon: 'map-style-satellite', label: 'Satelite map', style: MapStyle.SATELLITE }
  ];
  blankMapStyles = [
    {
      icon: 'map-style-blank-black',
      label: 'Black map',
      style: MapStyle.BLANK_BLACK
    },
    {
      icon: 'map-style-blank-white',
      label: 'White map',
      style: MapStyle.BLANK_WHITE
    }
  ];

  mapStyles = [];
  isOverlayMenuOpen = false;

  MapStyle = MapStyle;
  style: MapStyle = MapStyle.STREET;
  icon: string;
  label: string;

  @Input() set mapStyle(style: MapStyle) {
    this.changeStyle(style);
  }
  @Output() mapStyleChanged = new EventEmitter<MapStyle>();

  @Input() mapId: string;

  @Input()
  public set showBlankLayers(value: Boolean) {
    if (value) {
      this.mapStyles = [...this.defaultMapStyles, ...this.blankMapStyles];
    } else {
      this.mapStyles = [...this.defaultMapStyles];
    }
  }

  constructor(private mapStyleService: MapStyleService) {
    this.mapStyles = [...this.defaultMapStyles];

    this.icon = this.mapStyles[0].icon;
    this.label = this.mapStyles[0].label;
  }

  toggleMenu(forceClose?: boolean) {
    this.isOverlayMenuOpen = !this.isOverlayMenuOpen && !forceClose;
  }

  changeStyle(style: MapStyle, $event?: MouseEvent) {
    $event?.stopPropagation();
    $event?.preventDefault();
    if (style === this.style) {
      return;
    }

    this.style = style;

    const mapStyleInfo = this.mapStyles.find(mapStyle => mapStyle.style === style);
    this.icon = mapStyleInfo?.icon;
    this.label = mapStyleInfo?.label;

    this.mapStyleService.setMapStyle(this.mapId, style);

    this.mapStyleChanged.emit(style);
    this.isOverlayMenuOpen = false;
  }
}
