import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import Grapick from 'grapick';

import { ColorRampStop, DEFAULT_ELEVATION_RAMP_COLORS } from '../../detailed-site/site-map/map-overlays/elevation-contour.service';
import { LocaleService } from '../services/locale.service';
import { MATERIAL_COLORS } from '../utils/formatting';
import { isDefined } from '../utils/general';
import { floatToInteger, roundTo } from '../utils/math';

export const DEFAULT_COLOR = MATERIAL_COLORS[0];

export enum ColorSliderViewModes {
  INLINE = 'inline',
  BLOCK = 'block'
}

export enum ColorSliderRangePosition {
  BOTTOM = 'bottom',
  TOP = 'top'
}

@Component({
  selector: 'color-slider',
  templateUrl: './color-slider.component.html',
  styleUrls: ['./color-slider.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ColorSliderComponent),
      multi: true
    }
  ]
})
export class ColorSliderComponent implements OnInit, ControlValueAccessor {
  @ViewChild('levelInput', { static: true }) levelInput: ElementRef<HTMLInputElement>;
  @ViewChild('gradientEl', { static: true }) gradientEl: ElementRef<HTMLElement>;

  @Input() units: string;
  @Input() colorMode: 'dark' | 'light' = 'dark';
  @Input() colorSliderViewMode = ColorSliderViewModes.BLOCK;
  @Input() editableRange = false;
  @Input() rangePosition = ColorSliderRangePosition.BOTTOM;
  @Input() set colorRamps(colors: ColorRampStop[]) {
    if (!isDefined(colors)) {
      return;
    }
    this.colors = colors;
    this.onChange(colors);
    this.onTouched();
  }
  get colorRamps() {
    return this.colors;
  }
  @Input() set minVal(val: number) {
    this.min = roundTo(val, 3);
  }
  @Input() set maxVal(val: number) {
    this.max = roundTo(val, 3);
  }

  @Input() initialMin: number;
  @Input() initialMax: number;

  @Output() colorRampsChange = new EventEmitter<ColorRampStop[]>();
  @Output() rangeChange = new EventEmitter<{ min: number; max: number }>();

  min: number;
  max: number;

  ColorSliderRangePosition = ColorSliderRangePosition;
  ColorSliderViewModes = ColorSliderViewModes;
  disabled: boolean;
  colors: ColorRampStop[] = DEFAULT_ELEVATION_RAMP_COLORS;
  selectedHandler: ColorRampStop = { color: '', position: 0 };
  gradientPicker;
  colorsPalette = [...MATERIAL_COLORS, ...this.colorRamps.map(e => e.color), 'transparent'];
  levelTooltip = {
    show: false,
    level: 0,
    position: 0
  };
  onChange: any = () => {};
  onTouched: any = () => {};

  get isRemoveHandleDisabled() {
    return this.gradientPicker?.getHandlers()?.length === 2;
  }

  constructor(private localeService: LocaleService, private cd: ChangeDetectorRef, private renderer: Renderer2) {}

  ngOnInit() {
    this.initGradient();
    this.adjustInputWidth();
  }

  writeValue(colors: ColorRampStop[]) {
    this.colors = colors;
    this.initGradient();
  }

  registerOnChange(fn: () => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  initGradient() {
    this.gradientPicker = new Grapick({
      el: this.gradientEl.nativeElement,
      colorEl: '<input type="color" value="#ffffff"/>'
    });
    this.subscribeToGradientEvents();
    this.colorRamps.forEach(e => this.gradientPicker.addHandler(e.position * 100, e.color, e.position === 0.5));
    this.cd.detectChanges();
  }

  subscribeToGradientEvents() {
    //  Add double click event to existing handlers
    this.gradientPicker.getHandlers().forEach(e =>
      e.el.addEventListener('dblclick', () => {
        this.removeHandler();
      })
    );
    // Add Double click to new handler
    this.gradientPicker.on('handler:add', addedHandler => {
      addedHandler.el.addEventListener('dblclick', () => {
        this.removeHandler();
      });
    });

    this.gradientPicker.on('change', () => {
      const handlers = this.gradientPicker.getHandlers();
      const gradientColors = handlers.map(h => ({ color: h.color, position: h.position / 100 }));
      this.colorRampsChange.emit(gradientColors);
      this.colorRamps = gradientColors;

      if (!this.gradientPicker.getSelected()) {
        const lastHandler = this.gradientPicker.getHandlers()?.at(-1);
        lastHandler?.select();
      }
    });

    this.gradientPicker.on('handler:select', selectedHandler => {
      this.setSelectedHandlerValues(selectedHandler.position, selectedHandler.color);
      this.setHandlerBgColor(selectedHandler.el, selectedHandler.color);
      this.cd.detectChanges();
    });

    this.gradientPicker.on('handler:deselect', deselectedHandler => {
      this.setHandlerBgColor(deselectedHandler.el, '#d1d1d1');
      this.cd.detectChanges();
    });

    this.gradientPicker.on('handler:drag', draggedHandler => {
      this.setSelectedHandlerValues(draggedHandler.position);
      this.cd.detectChanges();
    });
  }

  private setSelectedHandlerValues(position: number, color: string = this.selectedHandler.color) {
    this.levelTooltip.level = this.calcLevelValueByPosition(position);
    this.selectedHandler = { color, position: this.levelTooltip.level };
  }

  removeHandler() {
    if (this.isRemoveHandleDisabled) {
      return;
    }
    this.gradientPicker.getSelected().remove();
  }

  addNewHandler() {
    // Create a handler in the middle of the centeral couple
    const handlers = this.gradientPicker.getHandlers();
    const middleIndex = Math.floor(handlers.length / 2);
    const newPosition = Math.round((handlers[middleIndex].position + handlers[middleIndex - 1].position) / 2);
    const color = this.getColorAtPosition(newPosition);
    const newHandler = { color, position: newPosition / 100 };

    // Add new handler in the middle position
    const colorRamps = [...this.colorRamps];
    colorRamps.splice(middleIndex, 0, newHandler);
    this.colorRamps = colorRamps;

    this.colorRampsChange.emit(this.colorRamps);
    this.initGradient();

    // Select new handler
    this.gradientPicker.getHandler(middleIndex).select();
    this.setSelectedHandlerValues(newHandler.position, newHandler.color);
  }

  /**
   * Returns the color at a given position.
   * This is done by creating a canvas with the current gradient and getting the color at the pixel in the given position
   * @param position the position to check
   * @returns color at position as string
   */
  private getColorAtPosition(position: number) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const gradientBounding = this.gradientEl.nativeElement.getBoundingClientRect();
    canvas.width = gradientBounding.width;
    canvas.height = gradientBounding.height;

    const grad = ctx.createLinearGradient(0, 0, canvas.width, canvas.height);
    this.gradientPicker.getHandlers().forEach(h => grad.addColorStop(h.position / 100, h.color));

    ctx.fillStyle = grad;
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    const rgba = canvas
      .getContext('2d')
      .getImageData(gradientBounding.width * (position / 100), gradientBounding.height * (position / 100), 1, 1).data;
    return `rgba(${rgba.join(',')})`;
  }

  setHandlerColor(color: string) {
    const selected = this.gradientPicker.getSelected();
    this.setHandlerBgColor(selected.el, color);
    selected.setColor(color, true);
  }

  setHandlerBgColor(el: Element, color) {
    const dragHandler = el.querySelector('.grp-handler-drag') as HTMLElement;
    dragHandler.style.backgroundColor = color;
  }

  setHandlerPosition() {
    const levelVal = this.selectedHandler.position;

    if (levelVal > this.max || levelVal < this.min) {
      this.setSelectedHandlerValues(this.gradientPicker.getSelected().position);
      return;
    }

    const currLevel = levelVal - this.min;
    const maxLevel = this.max - this.min;
    const percent = (currLevel / maxLevel) * 100;

    this.gradientPicker.getSelected().setPosition(percent);
  }

  setMin(min: number) {
    if (isNaN(min) || min >= this.max) {
      this.minVal = this.max - 1;
    } else {
      this.minVal = min;
    }
    this.rangeChange.emit({ min: this.min, max: this.max });
    this.selectedHandler.position = this.calcLevelValueByPosition(this.gradientPicker?.getSelected()?.position ?? 50);
  }

  setMax(max: number) {
    if (isNaN(max) || max <= this.min) {
      this.maxVal = this.min + 1;
    } else {
      this.maxVal = max;
    }
    this.rangeChange.emit({ min: this.min, max: this.max });
    this.selectedHandler.position = this.calcLevelValueByPosition(this.gradientPicker?.getSelected()?.position ?? 50);
  }

  resetGradientValues() {
    this.colorRamps = DEFAULT_ELEVATION_RAMP_COLORS;
    this.colorRampsChange.emit(this.colorRamps);

    if (this.editableRange) {
      this.min = this.initialMin;
      this.max = this.initialMax;
      this.rangeChange.emit({ min: this.min, max: this.max });
    }

    this.initGradient();
  }

  adjustInputWidth() {
    const levelInput = this.levelInput.nativeElement;
    const ctx = this.renderer.createElement('canvas').getContext('2d');
    const safeVal = 5;
    const value = levelInput.value || this.max.toFixed(3);
    ctx.font = '14px arial';
    levelInput.style.width = (ctx.measureText(value).width + safeVal).toFixed() + 'px';
  }

  calcLevelValueByPosition(position: number) {
    const percent = position / 100;
    const valDiff = this.max - this.min;
    return roundTo(this.min + valDiff * percent, 3);
  }

  formatNumber(n: number, method: 'round' | 'ceil' | 'floor' = 'round') {
    return this.localeService.formatNumber(floatToInteger(n, this.max - this.min < 5 ? 1 : 0, method));
  }

  setLevelTooltipValue(e: MouseEvent) {
    const mouseX = e.clientX;
    const gradientBounding = this.gradientEl.nativeElement.getBoundingClientRect();
    const relativeX = mouseX - Math.floor(gradientBounding.left);
    const relativeXPercent = (relativeX / gradientBounding.width) * 100;
    const level = roundTo((this.max - this.min) * (relativeXPercent / 100) + this.min, 3);

    this.levelTooltip = {
      show: level >= this.min && level <= this.max,
      position: roundTo(relativeX, 0),
      level
    };

    this.cd.detectChanges();
  }

  toggleLevelTooltip(show: boolean) {
    this.levelTooltip.show = show;
    this.cd.detectChanges();
  }
}
