<dialog-layout (closeDialog)="close()" header="Subscription plan">
  <div class="row space-between subheader">
    <div class="row full-container filter">
      <label for="status">Status</label>
      <ng-select
        class="custom field-select-control"
        [items]="statusList"
        [selectOnTab]="true"
        placeholder="All"
        labelForId="status"
        bindValue="value"
        (change)="onChangeStatus($event)"
        [appendTo]="device.isDesktop() ? 'body' : ''"
      >
        <ng-template ng-option-tmp let-item="item">
          <div [title]="item.label">{{ item.label }}</div>
        </ng-template>
      </ng-select>
    </div>
  </div>

  <mat-table [dataSource]="dataSource" matSort [trackBy]="trackByFunc" class="full-container">
    <ng-container matColumnDef="description">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Parameter</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.description }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="max">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Eligibility</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ formatValue(element, 'max') }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="used">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Used</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ formatValue(element, 'used') }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-icon
          [svgIcon]="getStatusIcon(element.status)"
          [ngStyle]="{ color: getStatusIconColor(element.status) }"
        ></mat-icon>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="tableCols; sticky: true"></mat-header-row>
    <mat-row *matRowDef="let userRow; columns: tableCols"></mat-row>
  </mat-table>
</dialog-layout>
