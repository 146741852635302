import { Pipe, PipeTransform } from '@angular/core';

import { formatName } from '../utils/formatting';

@Pipe({
  name: 'formatUserName'
})
export class FormatUserNamePipe implements PipeTransform {
  transform(nameObj: { firstName?: string; lastName?: string }): string {
    return formatName(nameObj);
  }
}
