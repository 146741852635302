/**
 * Measurements Management Service
 * APIs document (version: 2.2.0)
 *
 * OpenAPI spec version: 2.2.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CoordinateBlock } from './coordinateBlock';
import { CoordinateModel } from './coordinateModel';
import { GetAnnotationNoteResponse } from './getAnnotationNoteResponse';
import { GetAttachmentResponse } from './getAttachmentResponse';
import { GetCategoryResponse } from './getCategoryResponse';
import { GetCreatedByResponse } from './getCreatedByResponse';
import { GetDataResponse } from './getDataResponse';
import { GetDrawingResponse } from './getDrawingResponse';
import { GetSourceIntegrationResponses } from './getSourceIntegrationResponses';

export interface GetAnnotationResponse {
  assignee?: string;
  attachments?: Array<GetAttachmentResponse>;
  category?: GetCategoryResponse;
  coordinates?: Array<CoordinateModel>;
  createdBy?: GetCreatedByResponse;
  creationTime?: Date;
  data?: Array<GetDataResponse>;
  date?: Date;
  description?: string;
  drawings?: Array<GetDrawingResponse>;
  groupId?: string;
  hiddenPosition?: boolean;
  id?: string;
  lastModifiedTime?: Date;
  layerId?: string;
  name?: string;
  notes?: Array<GetAnnotationNoteResponse>;
  notifiedUsers?: Array<string>;
  positions?: Array<CoordinateBlock>;
  priority?: GetAnnotationResponse.PriorityEnum;
  sourceIntegrations?: GetSourceIntegrationResponses;
  status?: GetAnnotationResponse.StatusEnum;
}
export namespace GetAnnotationResponse {
  export type PriorityEnum = 'LOW' | 'MEDIUM' | 'HIGH' | 'CRITICAL';
  export const PriorityEnum = {
    LOW: 'LOW' as PriorityEnum,
    MEDIUM: 'MEDIUM' as PriorityEnum,
    HIGH: 'HIGH' as PriorityEnum,
    CRITICAL: 'CRITICAL' as PriorityEnum
  };
  export type StatusEnum = 'OPEN' | 'ON_HOLD' | 'CANCELED' | 'DONE';
  export const StatusEnum = {
    OPEN: 'OPEN' as StatusEnum,
    ONHOLD: 'ON_HOLD' as StatusEnum,
    CANCELED: 'CANCELED' as StatusEnum,
    DONE: 'DONE' as StatusEnum
  };
}
