import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AuthQuery } from '../../../auth/state/auth.query';
import { UserRole } from '../../../auth/state/auth.utils';
import { AnalyticsService } from '../../services/analytics.service';
import { SnackBarService } from '../../services/snackbar.service';
import { ToggleReadItem, ToggleReadItemsMenuComponent } from '../../toggle-read-items-menu/toggle-read-items-menu.component';
import { isDefined } from '../../utils/general';
import {
  AnnouncementDialogCloseType,
  AnnouncementsDialogComponent,
  AnnouncementsDialogData,
  AnnouncementsDialogResult
} from './announcements-dialog/announcements-dialog.component';
import { AnnouncementsQuery } from './state/announcements.query';
import { AnnouncementsService } from './state/announcements.service';
import { Announcement } from './state/announcements.store';

@UntilDestroy()
@Component({
  selector: 'announcement-menu',
  templateUrl: './announcement-menu.component.html',
  styleUrls: ['./announcement-menu.component.scss']
})
export class AnnouncementMenuComponent implements OnInit {
  @ViewChild('itemsMenu', { static: true }) private itemsMenu: ToggleReadItemsMenuComponent;
  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;
  @Input() set announcements(announcements: Announcement[]) {
    this.announcementsItems = announcements.map(announcement => ({
      date: announcement.creationTime,
      id: announcement.id,
      read: announcement.read,
      text: `${announcement.title} - ${announcement.subtitle}`,
      disableClick: false
    }));
  }

  get menu(): MatMenu {
    return this.itemsMenu?.menu;
  }

  announcementsItems: ToggleReadItem[];
  dialogRef: MatDialogRef<AnnouncementsDialogComponent, AnnouncementsDialogResult>;

  constructor(
    private authQuery: AuthQuery,
    private dialog: MatDialog,
    private announcementsService: AnnouncementsService,
    private announcementsQuery: AnnouncementsQuery,
    private snackbar: SnackBarService,
    private analytics: AnalyticsService
  ) {}

  ngOnInit() {
    if (this.announcementsQuery.isFirstLaunch() && this.authQuery.getUserRole() === UserRole.ACCOUNT_USER) {
      this.announcementsService.updateFirstLaunch();

      this.announcementsService
        .fetchUserAnnouncements()
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          const latestAnnouncement = this.announcementsQuery.getLatestAnnouncement();

          if (isDefined(latestAnnouncement) && !latestAnnouncement.seen) {
            this.openDialog({ announcement: latestAnnouncement, showSnoozeBtn: true });
            this.analytics.announcementOpenedBySystem(latestAnnouncement);
          }
        });
    }
  }

  openDialog(data: AnnouncementsDialogData) {
    const { announcement } = data;

    this.dialogRef = this.dialog.open<AnnouncementsDialogComponent, AnnouncementsDialogData>(AnnouncementsDialogComponent, {
      autoFocus: false,
      restoreFocus: false,
      maxWidth: '400px',
      minWidth: '400px',
      data,
      hasBackdrop: false,
      position: {
        right: '15px',
        top: '69px'
      },
      disableClose: true
    });

    this.dialogRef.afterClosed().subscribe(data => {
      if (announcement.read || !data || data.closeType === AnnouncementDialogCloseType.SNOOZE) {
        return;
      }

      this.updateAnnouncementRead(announcement, true).subscribe({
        error: error => {
          console.error('Error marking announcement read', error);
        }
      });
    });
  }

  onClick(announcementId: string) {
    this.dialogRef?.close();
    this.dialogRef = null;
    this.menuTrigger.closeMenu();
    const announcement = this.getAnnouncementById(announcementId);
    this.openDialog({ announcement, showSnoozeBtn: false });
    this.analytics.announcementOpenedFromMenu(announcement);
  }

  toggleRead(announcementId: string) {
    const announcement = this.getAnnouncementById(announcementId);
    this.updateAnnouncementRead(announcement, !announcement.read).subscribe({
      error: error => {
        this.snackbar.openError(
          $localize`:@@shared.announcementMenu.errorMarkingAnnouncementRead:Error marking announcement read`,
          'Error marking announcement read',
          error
        );
      }
    });
    this.analytics.announcementReadStatusChanged(announcement);
  }

  private getAnnouncementById(id: string) {
    return this.announcementsQuery.getEntity(id);
  }

  private updateAnnouncementRead(announcement: Announcement, read: boolean) {
    return this.announcementsService.toggleRead(announcement.id, read);
  }
}
