// index.ts
import { featureCollection, lineString } from "@turf/helpers";
import { getCoords } from "@turf/invariant";
import { flattenEach } from "@turf/meta";
function lineSegment(geojson) {
  if (!geojson) {
    throw new Error("geojson is required");
  }
  const results = [];
  flattenEach(geojson, feature => {
    lineSegmentFeature(feature, results);
  });
  return featureCollection(results);
}
function lineSegmentFeature(geojson, results) {
  let coords = [];
  const geometry = geojson.geometry;
  if (geometry !== null) {
    switch (geometry.type) {
      case "Polygon":
        coords = getCoords(geometry);
        break;
      case "LineString":
        coords = [getCoords(geometry)];
    }
    coords.forEach(coord => {
      const segments = createSegments(coord, geojson.properties);
      segments.forEach(segment => {
        segment.id = results.length;
        results.push(segment);
      });
    });
  }
}
function createSegments(coords, properties) {
  const segments = [];
  coords.reduce((previousCoords, currentCoords) => {
    const segment = lineString([previousCoords, currentCoords], properties);
    segment.bbox = bbox(previousCoords, currentCoords);
    segments.push(segment);
    return currentCoords;
  });
  return segments;
}
function bbox(coords1, coords2) {
  const x1 = coords1[0];
  const y1 = coords1[1];
  const x2 = coords2[0];
  const y2 = coords2[1];
  const west = x1 < x2 ? x1 : x2;
  const south = y1 < y2 ? y1 : y2;
  const east = x1 > x2 ? x1 : x2;
  const north = y1 > y2 ? y1 : y2;
  return [west, south, east, north];
}
var turf_line_segment_default = lineSegment;
export { turf_line_segment_default as default, lineSegment };
