import { Pipe, PipeTransform } from '@angular/core';

import { LocaleService } from '../services/locale.service';

@Pipe({
  name: 'formatDateNumeral'
})
export class FormatDateNumeralPipe implements PipeTransform {
  constructor(private localeService: LocaleService) {}

  transform(date: Date | string, shortYear: boolean = false, withTime: boolean = false): string {
    return date ? this.localeService.formatDateNumeral({ date, shortYear, withTime }) : '-';
  }
}
