import { hasOwnProperty } from "./object.js";

// Computes the bounding box of the specified hash of GeoJSON objects.
export default function (objects) {
  var x0 = Infinity,
    y0 = Infinity,
    x1 = -Infinity,
    y1 = -Infinity;
  function boundGeometry(geometry) {
    if (geometry != null && hasOwnProperty.call(boundGeometryType, geometry.type)) boundGeometryType[geometry.type](geometry);
  }
  var boundGeometryType = {
    GeometryCollection: function (o) {
      o.geometries.forEach(boundGeometry);
    },
    Point: function (o) {
      boundPoint(o.coordinates);
    },
    MultiPoint: function (o) {
      o.coordinates.forEach(boundPoint);
    },
    LineString: function (o) {
      boundLine(o.arcs);
    },
    MultiLineString: function (o) {
      o.arcs.forEach(boundLine);
    },
    Polygon: function (o) {
      o.arcs.forEach(boundLine);
    },
    MultiPolygon: function (o) {
      o.arcs.forEach(boundMultiLine);
    }
  };
  function boundPoint(coordinates) {
    var x = coordinates[0],
      y = coordinates[1];
    if (x < x0) x0 = x;
    if (x > x1) x1 = x;
    if (y < y0) y0 = y;
    if (y > y1) y1 = y;
  }
  function boundLine(coordinates) {
    coordinates.forEach(boundPoint);
  }
  function boundMultiLine(coordinates) {
    coordinates.forEach(boundLine);
  }
  for (var key in objects) {
    boundGeometry(objects[key]);
  }
  return x1 >= x0 && y1 >= y0 ? [x0, y0, x1, y1] : undefined;
}