import { Component, EventEmitter, Input, type OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { isDefined } from '../utils/general';

@UntilDestroy()
@Component({
  selector: 'search-field',
  templateUrl: './search-field.component.html',
  styleUrls: ['./search-field.component.scss']
})
export class SearchFieldComponent implements OnInit {
  @Input() placeholder = $localize`:@@shared.searchField.defaultPlaceholder:Search`;
  @Input() debounceTime = 300;
  @Input() mode: 'dark' | 'light' = 'dark';

  @Output() searchChange = new EventEmitter<string>();

  @Input() set search(searchStr: string) {
    this.searchField.patchValue(searchStr);
  }

  @Input() set disabled(disabled: boolean) {
    if (disabled) {
      this.searchField.disable();
    } else {
      this.searchField.enable();
    }
  }
  get disabled() {
    return this.searchField.disabled;
  }

  protected searchField = new FormControl<string>('');

  ngOnInit() {
    this.searchField.valueChanges
      .pipe(debounceTime(this.debounceTime || 0), distinctUntilChanged(), untilDestroyed(this))
      .subscribe(value => {
        this.searchChange.emit(value);
      });
  }

  clearSearch() {
    this.searchField.patchValue('');
  }

  get isActiveSearch() {
    return isDefined(this.searchField.value);
  }
}
