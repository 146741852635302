import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgSelectComponent } from '@ng-select/ng-select';

import { SurfaceReferenceType } from '../../../../detailed-site/state/detailed-site.model';
import { BaseSurfaceOption, BaseSurfaceType } from '../../../../detailed-site/state/detailed-site-entities/detailed-site-entities.model';
import { DeviceService } from '../../../services/device.service';
import { isDefined } from '../../../utils/general';
import { roundTo } from '../../../utils/math';
import { convertFromMeters, convertToMeters, getUnitSign, UnitsEnum } from '../../../utils/unit-conversion';
import { MapModalSubtitleCustomBaseSurfaceDialogComponent } from './map-modal-subtitle-custom-base-surface-dialog/map-modal-subtitle-custom-base-surface-dialog.component';

function isCustomElevationBaseSurface(baseSurface: BaseSurfaceOption) {
  return baseSurface && baseSurface.type === BaseSurfaceType.CUSTOMELEVATION;
}

interface SurfaceReferenceTypeItem {
  name: SurfaceReferenceType;
  type: SurfaceReferenceType;
}

const TARGET_REFERENCE_TYPE_ITEM = { name: SurfaceReferenceType.TARGET, type: SurfaceReferenceType.TARGET };
const BASE_REFERENCE_TYPE_ITEM = { name: SurfaceReferenceType.BASE, type: SurfaceReferenceType.BASE };

@Component({
  selector: 'map-modal-subtitle-base-surface-field',
  templateUrl: './map-modal-subtitle-base-surface-field.component.html',
  styleUrls: ['./map-modal-subtitle-base-surface-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapModalSubtitleBaseSurfaceFieldComponent implements OnInit, OnChanges {
  @ViewChild(NgSelectComponent, { static: true }) baseSurfaceSelect: NgSelectComponent;

  @Input() baseSurfaceOptions: BaseSurfaceOption[] = [];
  @Input() baseSurface: BaseSurfaceOption;
  @Input() siteUnits: UnitsEnum;
  @Input() disabled: boolean;
  @Input() isReferenceTypeSelectable: boolean;

  @Output() baseSurfaceChange = new EventEmitter<BaseSurfaceOption>();

  unitSign: string;
  elevationDisplayValue: number;
  private previousBaseSurface: BaseSurfaceOption;

  referenceType: SurfaceReferenceTypeItem;

  surfaceReferenceTypeItems: SurfaceReferenceTypeItem[] = [TARGET_REFERENCE_TYPE_ITEM, BASE_REFERENCE_TYPE_ITEM];

  constructor(private dialog: MatDialog, private cd: ChangeDetectorRef, protected device: DeviceService) {}

  ngOnInit() {
    this.unitSign = getUnitSign(this.siteUnits);
  }

  ngOnChanges(changes: SimpleChanges) {
    // Upon first load of base surface input (if any), save it as previous and format elevation display value (for custom elevation)
    if (changes.baseSurface && !changes.baseSurface.previousValue && changes.baseSurface.currentValue) {
      const baseSurface = changes.baseSurface.currentValue;

      this.previousBaseSurface = baseSurface;
      this.elevationDisplayValue =
        isCustomElevationBaseSurface(baseSurface) && isDefined(baseSurface.elevation)
          ? roundTo(convertFromMeters(baseSurface.elevation, this.siteUnits))
          : null;

      if (this.isReferenceTypeSelectable && isDefined(baseSurface.isTarget)) {
        this.referenceType = this.baseSurface.isTarget ? TARGET_REFERENCE_TYPE_ITEM : BASE_REFERENCE_TYPE_ITEM;
      }
    }
  }

  onChangeBaseSurface(baseSurface: BaseSurfaceOption) {
    this.previousBaseSurface = this.baseSurface;
    this.baseSurface = { ...this.previousBaseSurface, ...baseSurface };

    if (isCustomElevationBaseSurface(this.baseSurface)) {
      this.openCustomElevationDialog();
    } else {
      this.emitSurfaceChangesIfValid();
    }
  }

  onChangeSurfaceReferenceType(referenceTypeItem: SurfaceReferenceTypeItem) {
    const isTarget = referenceTypeItem.type === SurfaceReferenceType.TARGET;
    if (this.baseSurface) {
      this.baseSurface = { ...this.baseSurface, isTarget };
    } else {
      this.baseSurface = {
        name: undefined,
        id: undefined,
        type: undefined,
        isTarget
      };
    }
    this.emitSurfaceChangesIfValid();
  }

  onClickElevation() {
    this.openCustomElevationDialog();
  }

  private openCustomElevationDialog() {
    const boundingRect = this.baseSurfaceSelect.element.getBoundingClientRect();
    const dialogRef = this.dialog.open<MapModalSubtitleCustomBaseSurfaceDialogComponent>(MapModalSubtitleCustomBaseSurfaceDialogComponent, {
      restoreFocus: false,
      width: '400px',
      height: '220px',
      position: {
        bottom: '120px',
        left: boundingRect.left + 'px'
      },
      data: { siteUnits: this.siteUnits, elevation: this.elevationDisplayValue }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.elevationDisplayValue = roundTo(result.elevation);
        this.baseSurface = { ...this.baseSurface, elevation: convertToMeters(this.elevationDisplayValue, this.siteUnits) };
        this.previousBaseSurface = this.baseSurface;
        this.emitSurfaceChangesIfValid();
        this.cd.detectChanges();
      } else {
        this.baseSurface = this.previousBaseSurface;
        this.cd.detectChanges();
      }
    });
  }

  private emitSurfaceChangesIfValid() {
    // don't check 'baseSurface.name' because it is undefined for the first open of existed entity
    // (name is not saved to the BE side)
    let isSurfaceValid = isDefined(this.baseSurface?.id) && isDefined(this.baseSurface?.type);

    if (isSurfaceValid) {
      this.baseSurfaceChange.emit(this.baseSurface);
    }
  }
}
