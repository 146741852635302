// index.ts
import { feature } from "@turf/helpers";
import { getCoords, getType } from "@turf/invariant";
function cleanCoords(geojson, options = {}) {
  var mutate = typeof options === "object" ? options.mutate : options;
  if (!geojson) throw new Error("geojson is required");
  var type = getType(geojson);
  var newCoords = [];
  switch (type) {
    case "LineString":
      newCoords = cleanLine(geojson, type);
      break;
    case "MultiLineString":
    case "Polygon":
      getCoords(geojson).forEach(function (line) {
        newCoords.push(cleanLine(line, type));
      });
      break;
    case "MultiPolygon":
      getCoords(geojson).forEach(function (polygons) {
        var polyPoints = [];
        polygons.forEach(function (ring) {
          polyPoints.push(cleanLine(ring, type));
        });
        newCoords.push(polyPoints);
      });
      break;
    case "Point":
      return geojson;
    case "MultiPoint":
      var existing = {};
      getCoords(geojson).forEach(function (coord) {
        var key = coord.join("-");
        if (!Object.prototype.hasOwnProperty.call(existing, key)) {
          newCoords.push(coord);
          existing[key] = true;
        }
      });
      break;
    default:
      throw new Error(type + " geometry not supported");
  }
  if (geojson.coordinates) {
    if (mutate === true) {
      geojson.coordinates = newCoords;
      return geojson;
    }
    return {
      type,
      coordinates: newCoords
    };
  } else {
    if (mutate === true) {
      geojson.geometry.coordinates = newCoords;
      return geojson;
    }
    return feature({
      type,
      coordinates: newCoords
    }, geojson.properties, {
      bbox: geojson.bbox,
      id: geojson.id
    });
  }
}
function cleanLine(line, type) {
  var points = getCoords(line);
  if (points.length === 2 && !equals(points[0], points[1])) return points;
  var newPoints = [];
  var secondToLast = points.length - 1;
  var newPointsLength = newPoints.length;
  newPoints.push(points[0]);
  for (var i = 1; i < secondToLast; i++) {
    var prevAddedPoint = newPoints[newPoints.length - 1];
    if (points[i][0] === prevAddedPoint[0] && points[i][1] === prevAddedPoint[1]) continue;else {
      newPoints.push(points[i]);
      newPointsLength = newPoints.length;
      if (newPointsLength > 2) {
        if (isPointOnLineSegment(newPoints[newPointsLength - 3], newPoints[newPointsLength - 1], newPoints[newPointsLength - 2])) newPoints.splice(newPoints.length - 2, 1);
      }
    }
  }
  newPoints.push(points[points.length - 1]);
  newPointsLength = newPoints.length;
  if ((type === "Polygon" || type === "MultiPolygon") && equals(points[0], points[points.length - 1]) && newPointsLength < 4) {
    throw new Error("invalid polygon");
  }
  if (type === "LineString" && newPointsLength < 3) {
    return newPoints;
  }
  if (isPointOnLineSegment(newPoints[newPointsLength - 3], newPoints[newPointsLength - 1], newPoints[newPointsLength - 2])) newPoints.splice(newPoints.length - 2, 1);
  return newPoints;
}
function equals(pt1, pt2) {
  return pt1[0] === pt2[0] && pt1[1] === pt2[1];
}
function isPointOnLineSegment(start, end, point) {
  var x = point[0],
    y = point[1];
  var startX = start[0],
    startY = start[1];
  var endX = end[0],
    endY = end[1];
  var dxc = x - startX;
  var dyc = y - startY;
  var dxl = endX - startX;
  var dyl = endY - startY;
  var cross = dxc * dyl - dyc * dxl;
  if (cross !== 0) return false;else if (Math.abs(dxl) >= Math.abs(dyl)) return dxl > 0 ? startX <= x && x <= endX : endX <= x && x <= startX;else return dyl > 0 ? startY <= y && y <= endY : endY <= y && y <= startY;
}
var turf_clean_coords_default = cleanCoords;
export { cleanCoords, turf_clean_coords_default as default };
