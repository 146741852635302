import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import {
  ColorsPaletteFieldValue,
  ColorsPaletteValue
} from '../../detailed-site/detailed-site-sidenav/reports/grid-report-dialog/color-ranges-field/color-ranges-field.component';
import { DetailedSiteQuery } from '../../detailed-site/state/detailed-site.query';
import { ColorsPaletteType } from '../../detailed-site/state/detailed-site-reports/detailed-site-reports.model';
import { ColorSliderRangePosition } from '../color-slider/color-slider.component';
import { isDefined, rgbaToHex, waitForElementToExist } from '../utils/general';
import { getUnitSign } from '../utils/unit-conversion';

@Component({
  selector: 'color-palette-select',
  templateUrl: './color-palette-select.component.html',
  styleUrls: ['./color-palette-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ColorPaletteSelectComponent),
      multi: true
    }
  ]
})
export class ColorPaletteSelectComponent implements OnInit, ControlValueAccessor {
  ColorsPaletteType = ColorsPaletteType;
  ColorSliderRangePosition = ColorSliderRangePosition;
  @ViewChild('editGradientDialog', { static: true }) editGradientDialog: TemplateRef<MatDialog>;

  @Input() colorPalettes: ColorsPaletteFieldValue[];
  @Input() disabled: boolean;

  private value: ColorsPaletteFieldValue;

  get colorPalette() {
    return this.value;
  }

  @Input() set colorPalette(value: ColorsPaletteFieldValue) {
    this.value = value;
    this.onChange(value);
    this.onTouched();
  }

  unitsSign = getUnitSign(this.siteQuery.getSiteUnits());

  onChange: any = () => {};
  onTouched: any = () => {};

  constructor(private cd: ChangeDetectorRef, public dialog: MatDialog, private siteQuery: DetailedSiteQuery) {}

  writeValue(value: ColorsPaletteFieldValue) {
    this.colorPalette = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState?(disabled: boolean): void {
    this.disabled = disabled;
  }

  ngOnInit() {}

  openDialog() {
    const dialogRef = this.dialog.open(this.editGradientDialog, {
      autoFocus: false,
      restoreFocus: false,
      width: '500px',
      hasBackdrop: true,
      panelClass: 'custom-gradient-dialog'
    });

    dialogRef.afterClosed().subscribe((data: ColorsPaletteValue) => {
      if (isDefined(data)) {
        const { palette, min, max } = data;
        const updatedPalettes = [...this.colorPalettes];
        const customGradientIndex = this.colorPalettes.findIndex(c => c.colorsPaletteType === ColorsPaletteType.NORMAL);
        updatedPalettes[customGradientIndex] = this.colorPalette = {
          min,
          max,
          palette: palette.map(cp => ({ ...cp, color: rgbaToHex(cp.color) })),
          colorsPaletteType: ColorsPaletteType.NORMAL
        };
        this.colorPalettes = updatedPalettes;
      } else if (!isDefined(this.colorPalette.palette)) {
        this.colorPalette = this.colorPalettes[0];
      }
      this.cd.detectChanges();
    });
  }

  gradientChanged(palette: ColorsPaletteFieldValue) {
    if (palette.colorsPaletteType === ColorsPaletteType.NORMAL && !isDefined(palette.palette)) {
      this.openDialog();
    } else {
      this.colorPalette = palette;
    }
  }

  scrollListToView() {
    waitForElementToExist('.ng-select.color-palette-select .ng-dropdown-panel').then((element: HTMLElement) => {
      element.scrollIntoView({ behavior: 'smooth' });
    });
  }
}
