import { Pipe, PipeTransform } from '@angular/core';

import { BaseSurface } from '../../../../../generated/mms/model/baseSurface';
import { BaseSurfaceType } from '../../../../detailed-site/state/detailed-site-entities/detailed-site-entities.model';

@Pipe({
  name: 'isCustomElevation'
})
export class IsCustomElevationPipe implements PipeTransform {
  transform(baseSurface: BaseSurface): boolean {
    return baseSurface && baseSurface.type === BaseSurfaceType.CUSTOMELEVATION;
  }
}
