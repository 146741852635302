<dialog-layout (closeDialog)="close()" [header]="dialogHeader">
  <form [formGroup]="detailsForm" (ngSubmit)="submit()" class="column full-container details-form">
    <div class="row field-row">
      <label class="field-label"><ng-container i18n="@@shared.groupLayerDetails.nameFieldLabel">Name</ng-container> *</label>
      <input formControlName="name" class="field-input-control" />
      <field-error *ngIf="getFieldErrors(detailsForm, 'name')" [text]="getFieldErrorMessage(detailsForm, 'name')"></field-error>
    </div>
    <div *ngIf="data.type === 'layer'" class="row field-row">
      <label class="field-label"><ng-container i18n="@@shared.groupLayerDetails.colorFieldLabel">Color</ng-container> *</label>
      <color-picker formControlName="color"></color-picker>
    </div>
    <div class="row align-start field-row">
      <label class="field-label" i18n="@@shared.groupLayerDetails.descriptionFieldLabel">Description</label>
      <textarea formControlName="description" class="field-input-control"></textarea>
    </div>
    <div class="row bottom-row">
      <button mat-button type="button" class="light-btn" (click)="close()">
        <ng-container *ngIf="isEditMode; else discardButtonLabel" i18n="@@general.actionButtons.cancel">Cancel</ng-container>
        <ng-template #discardButtonLabel i18n="@@general.actionButtons.discard">Discard</ng-template>
      </button>
      <button mat-flat-button type="submit" [disabled]="!detailsForm.valid" class="dark-btn">
        <ng-container *ngIf="isEditMode; else createButtonLabel" i18n="@@general.actionButtons.save">Save</ng-container>
        <ng-template #createButtonLabel i18n="@@general.actionButtons.create">Create</ng-template>
      </button>
    </div>
  </form>
</dialog-layout>
