// index.ts
import { getCoord, getGeom } from "@turf/invariant";
function planepoint(point, triangle) {
  const coord = getCoord(point);
  const geom = getGeom(triangle);
  const coords = geom.coordinates;
  const outer = coords[0];
  if (outer.length < 4) throw new Error("OuterRing of a Polygon must have 4 or more Positions.");
  const properties = triangle.type === "Feature" && triangle.properties || {};
  const a = properties.a;
  const b = properties.b;
  const c = properties.c;
  const x = coord[0];
  const y = coord[1];
  const x1 = outer[0][0];
  const y1 = outer[0][1];
  const z1 = a !== void 0 ? a : outer[0][2];
  const x2 = outer[1][0];
  const y2 = outer[1][1];
  const z2 = b !== void 0 ? b : outer[1][2];
  const x3 = outer[2][0];
  const y3 = outer[2][1];
  const z3 = c !== void 0 ? c : outer[2][2];
  const z = (z3 * (x - x1) * (y - y2) + z1 * (x - x2) * (y - y3) + z2 * (x - x3) * (y - y1) - z2 * (x - x1) * (y - y3) - z3 * (x - x2) * (y - y1) - z1 * (x - x3) * (y - y2)) / ((x - x1) * (y - y2) + (x - x2) * (y - y3) + (x - x3) * (y - y1) - (x - x1) * (y - y3) - (x - x2) * (y - y1) - (x - x3) * (y - y2));
  return z;
}
var turf_planepoint_default = planepoint;
export { turf_planepoint_default as default, planepoint };
