import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { IssuesDialogComponent, IssuesDialogData } from './issues-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class IssuesDialogService {
  constructor(private dialog: MatDialog) {}

  openDialog(data?: IssuesDialogData) {
    return this.dialog.open<IssuesDialogComponent, IssuesDialogData, boolean>(IssuesDialogComponent, {
      autoFocus: false,
      restoreFocus: false,
      disableClose: data?.disableClose,
      width: '450px',
      data
    });
  }
}
