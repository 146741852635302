import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatMenu } from '@angular/material/menu';

import { DeviceService } from '../services/device.service';
import { LocaleService } from '../services/locale.service';

export interface ToggleReadItem {
  id: string;
  date: Date;
  text: string;
  read: boolean;
  iconName?: string;
  disableClick?: boolean;
  customClass?: string;
}

@Component({
  selector: 'toggle-read-items-menu',
  templateUrl: './toggle-read-items-menu.component.html',
  styleUrls: ['./toggle-read-items-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToggleReadItemsMenuComponent {
  @ViewChild(MatMenu, { static: true }) menu: MatMenu;

  @Output() clickItem = new EventEmitter<string>();
  @Output() clickReadItem = new EventEmitter<string>();

  @Input() items: ToggleReadItem[];
  @Input() panelClass?: string;

  constructor(private localeService: LocaleService, private device: DeviceService) {}

  onClick(item: ToggleReadItem) {
    if (item.disableClick) {
      return;
    }

    this.clickItem.emit(item.id);
  }

  clickRead(item: ToggleReadItem) {
    this.clickReadItem.emit(item.id);
  }

  trackById(index: number, item: ToggleReadItem) {
    return item ? item.id : null;
  }

  formatShortDate(date: Date) {
    return this.localeService.formatShortDate({ date });
  }

  get isDesktop() {
    return this.device.isDesktop();
  }
}
