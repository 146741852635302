import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dialog-hint-message',
  template: `
    <div class="container">
      <ng-content></ng-content>
    </div>
  `,
  styleUrls: ['./dialog-hint-message.component.scss']
})
export class DialogHintMessageComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
