<span
  [matTooltip]="text"
  [matTooltipShowDelay]="isTablet ? undefined : 250"
  [matTooltipHideDelay]="isTablet ? 3000 : undefined"
  [class.content]="text"
  matTooltipClass="underline-tooltip"
  matTooltipPosition="below"
>
  <ng-content></ng-content>
</span>
