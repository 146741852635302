import { Chart, TooltipModel } from 'chart.js';

const VERTICAL_LINE_DEFAULT_COLOR = '#6c7274';

export interface VerticalHoverLinePluginOptions {
  enabled?: boolean;
  lineColor?: string;
  lineWidth?: number;
}

export default {
  id: 'verticalHoverLine',
  events: ['mousemove'],

  beforeTooltipDraw: function (chart: Chart, { tooltip }: { tooltip: TooltipModel<any> }, options: VerticalHoverLinePluginOptions) {
    if (!options.enabled) {
      return;
    }

    const ctx: CanvasRenderingContext2D = chart.ctx;
    ctx.save();

    ctx.strokeStyle = options?.lineColor ?? VERTICAL_LINE_DEFAULT_COLOR;
    ctx.lineWidth = options?.lineWidth ?? 3;
    ctx.beginPath();

    const { top, height } = chart.chartArea;
    ctx.moveTo(tooltip.caretX, top);
    ctx.lineTo(tooltip.caretX, top + height);

    ctx.closePath();
    ctx.stroke();

    ctx.restore();
  }
};
