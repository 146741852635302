import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'toggle-button-group',
  template: `
    <div class="full-height container" [class.disabled]="disabled">
      <ng-content></ng-content>
    </div>
  `,
  styleUrls: ['./toggle-button-group.component.scss']
})
export class ToggleButtonGroupComponent implements OnInit {
  @Output() valueChange = new EventEmitter();

  @Input() disabled: boolean;

  private internalValue: any;
  @Input() set value(val: any) {
    if (this.internalValue !== val) {
      this.internalValue = val;
      this.valueChange.emit(val);
    }
  }
  get value() {
    return this.internalValue;
  }

  constructor() {}

  ngOnInit() {}
}
