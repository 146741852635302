import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { inRange, roundTo } from '../utils/math';

@Component({
  selector: 'opacity-slider',
  template: `
    <mat-slider class="full-container slider" [min]="0" [max]="100" [step]="1" color="primary" [disabled]="disabled">
      <input matSliderThumb [value]="opacity" (valueChange)="opacityChange($event)" />
    </mat-slider>
    <div class="slider-box {{ colorMode }}-mode">
      <input
        type="number"
        class="slider-input"
        [min]="min"
        [max]="max"
        [step]="1"
        (change)="opacityChange($event.target.valueAsNumber)"
        [value]="opacity"
        [disabled]="disabled"
        (focusout)="outOfFocus($event)"
      />
      <span matSuffix class="percentage">%</span>
    </div>
  `,
  styleUrls: ['./opacity-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OpacitySliderComponent),
      multi: true
    }
  ]
})
export class OpacitySliderComponent implements OnInit, ControlValueAccessor {
  value: number;

  @Input() disabled: boolean;
  @Input() min = 0;
  @Input() max = 100;
  @Input() colorMode: 'dark' | 'light' = 'dark';
  @Output() valueChange = new EventEmitter<number>();

  onChange: any = () => {};
  onTouched: any = () => {};

  @Input() set opacity(value: number) {
    this.value = inRange(value, this.min, this.max);
    this.onChange(value);
    this.onTouched();
  }

  get opacity() {
    return this.value;
  }

  constructor() {}

  ngOnInit() {}

  writeValue(value: number) {
    this.value = inRange(value, this.min, this.max);
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  opacityChange(opacity: number) {
    this.opacity = inRange(roundTo(opacity, 0), this.min, this.max);
    this.valueChange.emit(this.opacity);
  }

  outOfFocus(event: FocusEvent) {
    this.opacity = inRange(roundTo(this.opacity, 0), this.min, this.max);
    (event.target as HTMLInputElement).valueAsNumber = this.opacity;
  }
}
