// Given an array of arcs in absolute (but already quantized!) coordinates,
// converts to fixed-point delta encoding.
// This is a destructive operation that modifies the given arcs!
export default function (arcs) {
  var i = -1,
    n = arcs.length;
  while (++i < n) {
    var arc = arcs[i],
      j = 0,
      k = 1,
      m = arc.length,
      point = arc[0],
      x0 = point[0],
      y0 = point[1],
      x1,
      y1;
    while (++j < m) {
      point = arc[j], x1 = point[0], y1 = point[1];
      if (x1 !== x0 || y1 !== y0) arc[k++] = [x1 - x0, y1 - y0], x0 = x1, y0 = y1;
    }
    if (k === 1) arc[k++] = [0, 0]; // Each arc must be an array of two or more positions.

    arc.length = k;
  }
  return arcs;
}