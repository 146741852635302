import { Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { MATERIAL_COLORS } from '../utils/formatting';
import { isDefined } from '../utils/general';

export const DEFAULT_COLOR = MATERIAL_COLORS[0];

// Same transparent background as in color-twitter component
const TRANSPARENT_BACKGROUND =
  'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAADFJREFUOE9jZGBgEGHAD97gk2YcNYBhmIQBgWSAP52AwoAQwJvQRg1gACckQoC2gQgAIF8IscwEtKYAAAAASUVORK5CYII=) left center';

@Component({
  selector: 'color-picker',
  template: `
    <div
      matRipple
      class="color-button"
      [style.background]="isTransparent ? TRANSPARENT_BACKGROUND : color"
      [class.disabled]="disabled"
      (click)="openMenu()"
      *ngIf="isButtonVisible"
    ></div>
    <color-twitter
      *ngIf="isOpen"
      class="color-menu"
      [class.triangle-top-right]="triangleSize === 'small' && triangle === 'top-right'"
      [class.dark-mode]="mode === 'dark'"
      [class.large-triangle]="triangleSize === 'large'"
      [triangle]="triangleSize === 'small' ? triangle : 'hide'"
      [color]="color"
      [colors]="colors"
      (onChangeComplete)="changeColor($event)"
      [delayClickOutsideInit]="true"
      (clickOutside)="close()"
    ></color-twitter>
  `,
  styleUrls: ['./color-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ColorPickerComponent),
      multi: true
    }
  ]
})
export class ColorPickerComponent implements OnInit, ControlValueAccessor {
  TRANSPARENT_BACKGROUND = TRANSPARENT_BACKGROUND;

  @Input() colors = MATERIAL_COLORS;
  @Input() disabled = false;
  @Input() triangle: 'hide' | 'top-right' | 'top-left' = 'top-left';
  @Input() isButtonVisible = true;
  @Output() colorChanged = new EventEmitter<string>();

  @Input() mode: 'dark' | 'light' = 'light';
  @Input() triangleSize: 'small' | 'large' = 'small';

  isOpen = false;
  previousSelectedElement: HTMLElement;
  private internalColor: string;

  onChange: any = () => {};
  onTouched: any = () => {};

  constructor(public elementRef: ElementRef) {}

  ngOnInit() {
    if (!this.color) {
      this.color = DEFAULT_COLOR;
    }
  }

  openMenu() {
    if (this.disabled) {
      return;
    }

    this.isOpen = !this.isOpen;

    // Style selected color
    if (this.isOpen && this.color) {
      this.isOpen = true;

      setTimeout(() => {
        const swatches = Array.from(this.elementRef.nativeElement.querySelectorAll('.swatch'));
        const selectedSwatch = swatches.find((swatch: HTMLElement) => swatch.title === this.color);
        if (selectedSwatch) {
          this.styleSelected(selectedSwatch as HTMLElement);
        }
      }, 0);
    }
  }

  private styleSelected(element: HTMLElement) {
    if (this.previousSelectedElement) {
      this.previousSelectedElement.classList.remove('selected');
    }
    element.classList.add('selected');
    this.previousSelectedElement = element;
  }

  get color() {
    return this.internalColor;
  }

  @Input() set color(color) {
    this.internalColor = color;
    this.onChange(color);
    this.onTouched();
  }

  writeValue(color: string): void {
    if (color) {
      this.color = color;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  changeColor({ color, $event }) {
    this.styleSelected($event.target);

    // Workaround to find transparent color because of a bug in ngx-color that doesn't allow alpha in twitter color component
    const isTransparent = $event.target.classList.contains('grid') || $event.target.style.background === 'transparent';
    this.color = isTransparent ? 'transparent' : color.hex;

    this.colorChanged.emit(this.color);
    this.close();
  }

  close() {
    this.isOpen = false;
  }

  get isTransparent() {
    return isDefined(this.color) && (this.color === 'transparent' || (this.color.length === 9 && this.color.slice(7) === '00'));
  }
}
