import { Injectable } from '@angular/core';
import { TooltipModel } from 'chart.js';

import { LocaleService } from '../services/locale.service';
import { isDefined } from './general';
import { roundTo } from './math';

@Injectable({
  providedIn: 'root'
})
export class ChartCustomTooltipService {
  constructor(private localeService: LocaleService) {}

  customTooltip = ({
    closeButtonChar,
    showTooltipTitle,
    xAxisType
  }: {
    closeButtonChar?: string;
    showTooltipTitle?: boolean;
    xAxisType?: string;
  }) => {
    const formatValue = (value: number) => {
      return this.localeService.formatNumber(roundTo(value), { minimumFractionDigits: 2 });
    };
    const parseNumber = (numberStr: string) => this.localeService.parseNumber(numberStr);
    const needToFormatDate = xAxisType === 'time';
    const formatDateName = this.localeService.formatDateName;

    // Return function instead of arrow to preserve "this" of chart.js callback

    return function (tooltipContext: { tooltip: TooltipModel<any> }) {
      const tooltipModel = tooltipContext.tooltip;

      // Tooltip Element
      let tooltipEl = document.getElementById('chartjs-custom-tooltip');

      // Create element on first render
      if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.id = 'chartjs-custom-tooltip';
        tooltipEl.innerHTML = '<table></table>';
        document.body.appendChild(tooltipEl);
      }

      // Hide if no tooltip
      if (tooltipModel.opacity === 0) {
        tooltipEl.style.opacity = '0';
        return;
      }

      // Set caret Position
      tooltipEl.classList.remove('above', 'below', 'no-transform');
      if (tooltipModel.yAlign) {
        tooltipEl.classList.add(tooltipModel.yAlign);
      } else {
        tooltipEl.classList.add('no-transform');
      }

      // Set Text
      if (tooltipModel.body) {
        let innerHtml = '<thead>';
        if (showTooltipTitle) {
          const titleLines = tooltipModel.title || [];
          titleLines.forEach(title => {
            let formatedTitle = title.replace(closeButtonChar, '');
            if (needToFormatDate) {
              const dateTitle = new Date(title);
              formatedTitle = formatDateName({ date: dateTitle });
            }
            innerHtml += `<tr><th colspan="2">${formatedTitle}</th></tr>`;
          });
        }
        innerHtml += '</thead>';

        innerHtml += '<tbody>';
        const bodyLines = tooltipModel.body.map(bodyItem => bodyItem.lines[0]);
        bodyLines.forEach((body, i) => {
          const [key, value] = body.split(': ');
          const parsedValue = parseNumber(value);
          if (isNaN(parsedValue)) {
            return;
          }
          const dataset = tooltipModel.dataPoints[i].dataset;
          const borderColor = tooltipModel.dataPoints[i].dataset.borderColor;
          innerHtml += `<tr>
          <td>
            <span style="border-bottom: 2px ${dataset.borderDash ? 'dashed' : 'solid'} ${borderColor}">
              ${key}
            </span>
          </td>
          <td align="right">
            ${formatValue(value ? +value.replace(/,/g, '') : 0)}
          </td>
        </tr>`;
        });
        innerHtml += '</tbody>';

        const tableRoot = tooltipEl.querySelector('table');
        tableRoot.innerHTML = innerHtml;
      }

      // `this` will be the overall tooltip
      const chartRect: DOMRect = this.chart.canvas.getBoundingClientRect();

      // Display, position, and set styles for font
      tooltipEl.style.opacity = '1';

      let left = 0;
      left += chartRect.left;
      left += window.scrollX;
      left -= tooltipEl.clientWidth / 2;
      left += tooltipModel.caretX;
      left -= +tooltipModel.options.caretPadding;
      left += 1; // Padding
      tooltipEl.style.left = left + 'px';

      const isInChartVerticalBounds = (value: number) => value >= this.chart.chartArea.top && value <= this.chart.chartArea.bottom;

      let top = 0;
      top += chartRect.top;
      top += window.scrollY;
      top -= tooltipEl.clientHeight;
      // Choose closest to top y-position or calculated caretY
      const allYPos = tooltipModel.dataPoints
        .map(dp => (dp?.element as any)?._model?.y)
        .filter(y => isDefined(y) && isInChartVerticalBounds(y));
      const tooltipYPos = isDefined(allYPos) ? Math.min(...allYPos) : tooltipModel.caretY;
      top += isInChartVerticalBounds(tooltipYPos) ? tooltipYPos : (this.chart.chartArea.bottom - this.chart.chartArea.top) / 2;
      top -= +tooltipModel.options.caretPadding;
      top -= 20; // Padding
      tooltipEl.style.top = top + 'px';
    };
  };

  removeTooltip() {
    const tooltipEl = document.getElementById('chartjs-custom-tooltip');
    if (tooltipEl) {
      document.body.removeChild(tooltipEl);
    }
  }
}
