import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'table-header-info-tooltip',
  template: ` <mat-icon matTooltipClass="table-header-tooltip-info-icon" [matTooltip]="text">help</mat-icon> `,
  styleUrls: ['./table-header-info-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableHeaderInfoTooltipComponent {
  @Input() text: string;

  constructor() {}
}
