import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { environment } from '../../../environments/environment';
import { Task } from '../../detailed-site/state/detailed-site.model';
import { CoordinateSystem } from '../../tenant/tenant.model';
import { TenantQuery } from '../../tenant/tenant.query';
import { TenantService } from '../../tenant/tenant.service';
import { AnalyticsService } from '../services/analytics.service';
import { SnackBarService } from '../services/snackbar.service';
import { LOCAL_COORDINATE_SYSTEM_ID } from '../utils/backend-services';

const SUPPORT_EMAIL_TITLE_COORDINATE_SYSTEM = 'Coordinate system support';
const SUPPORT_EMAIL_TITLE_MODEL_GENERATION = 'Model generation support';

export enum CoordinateSystemDialogOption {
  RECOMMENDED = 'RECOMMENDED',
  MANUAL = 'MANUAL',
  LOCAL = 'LOCAL',
  DELETE_GCPS = 'DELETE_GCPS',
  SUPPORT = 'SUPPORT'
}

const OPTION_CATEGORY_NAMES = Object.freeze({
  SITE_CS: $localize`:@@shared.coordinateSystemModifier.optionCategoryNameSiteCS:Site coordinate system`,
  GCPS: $localize`:@@shared.coordinateSystemModifier.optionCategoryNameGCPs:Existing GCPs`,
  SUPPORT: $localize`:@@shared.coordinateSystemModifier.optionCategoryNameSupport:Contact our support`
});

interface OptionCategory {
  name: string;
  options: CoordinateSystemDialogOption[];
}

export interface CoordinateSystemDialogResult {
  coordinateSystem?: CoordinateSystem;
  selectedOption: CoordinateSystemDialogOption;
}

export interface CoordinateSystemDialogData {
  siteId: string;
  task?: Task;
  recommendedCoordinateSystems: CoordinateSystem[];
  openedFrom: 'IMAGES' | 'GCP' | 'GCP_RESIDUALS' | 'IMPORT_MODEL';
  onContinue?: (result: CoordinateSystemDialogResult) => void;
  canContinue?: (result: CoordinateSystemDialogResult) => boolean;
  onCancel?: () => void;
}

@UntilDestroy()
@Component({
  templateUrl: './coordinate-system-modifier.component.html',
  styleUrls: ['./coordinate-system-modifier.component.scss']
})
export class CoordinateSystemModifierComponent implements OnInit {
  CoordinateSystemDialogOption = CoordinateSystemDialogOption;

  optionCategories: OptionCategory[];
  selectedOption: CoordinateSystemDialogOption;
  selectedRecommended = new FormControl<CoordinateSystem>(null);
  selectedManual = new FormControl<CoordinateSystem>(null);
  siteCoordinateSystem: CoordinateSystem;
  coordinateSystems: CoordinateSystem[];
  isSiteCSLocal: boolean;
  coordinateSystemsLoading: boolean;
  dialogHeader: string;

  constructor(
    private dialogRef: MatDialogRef<CoordinateSystemModifierComponent>,
    private tenantService: TenantService,
    private tenantQuery: TenantQuery,
    private snackbar: SnackBarService,
    private analytics: AnalyticsService,
    @Inject(MAT_DIALOG_DATA) public data: CoordinateSystemDialogData
  ) {}

  ngOnInit() {
    this.dialogHeader =
      this.data.openedFrom === 'GCP_RESIDUALS'
        ? $localize`:@@shared.coordinateSystemModifier.dialogHeaderResiduals:Model cannot be generated`
        : $localize`:@@shared.coordinateSystemModifier.dialogHeaderCoordinateSystem:Modify site coordinate system`;

    const site = this.tenantQuery.getSite(this.data.siteId);
    this.siteCoordinateSystem = site?.coordinateSystem;
    this.isSiteCSLocal = this.siteCoordinateSystem?.id === LOCAL_COORDINATE_SYSTEM_ID;
    this.calcAvailableOptions();

    const siteUnits = site.units;
    this.coordinateSystemsLoading = true;
    this.tenantService
      .fetchCoordinateSystems()
      .pipe(untilDestroyed(this))
      .subscribe(allCoordinateSystems => {
        if (allCoordinateSystems) {
          this.coordinateSystemsLoading = false;

          // Filter coordinate systems by site units
          const coordinateSystems = allCoordinateSystems.filter(cs => cs.units === siteUnits);

          this.coordinateSystems = coordinateSystems.sort(this.sortByCategory);
        }
      });

    // If one coordinate system, save it in `selectedRecommended` for later use
    if (this.recommendedCount === 1) {
      this.selectedRecommended.patchValue(this.data.recommendedCoordinateSystems[0]);
    }

    this.dialogRef.backdropClick().subscribe(() => this.close());
    this.dialogRef.keydownEvents().subscribe((e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        this.close();
      }
    });
  }

  private calcAvailableOptions() {
    this.optionCategories = [];

    if (this.data.openedFrom === 'GCP_RESIDUALS') {
      const coordinateOptions: CoordinateSystemDialogOption[] = [];
      coordinateOptions.push(CoordinateSystemDialogOption.MANUAL);

      if (!this.isSiteCSLocal) {
        coordinateOptions.push(CoordinateSystemDialogOption.LOCAL);
      }

      this.optionCategories.push({ name: OPTION_CATEGORY_NAMES.SITE_CS, options: coordinateOptions });
      this.optionCategories.push({ name: OPTION_CATEGORY_NAMES.GCPS, options: [CoordinateSystemDialogOption.DELETE_GCPS] });
      this.optionCategories.push({ name: OPTION_CATEGORY_NAMES.SUPPORT, options: [CoordinateSystemDialogOption.SUPPORT] });
    } else {
      const coordinateOptions: CoordinateSystemDialogOption[] = [];
      if (this.recommendedCount > 0) {
        coordinateOptions.push(CoordinateSystemDialogOption.RECOMMENDED);
      } else {
        this.optionCategories.push({ name: OPTION_CATEGORY_NAMES.SUPPORT, options: [CoordinateSystemDialogOption.SUPPORT] });
      }

      if (!this.isSiteCSLocal && this.data.openedFrom === 'GCP') {
        coordinateOptions.push(CoordinateSystemDialogOption.LOCAL);
      }

      coordinateOptions.push(CoordinateSystemDialogOption.MANUAL);

      this.optionCategories.push({ name: OPTION_CATEGORY_NAMES.SITE_CS, options: coordinateOptions });
    }

    this.selectedOption = this.optionCategories[0].options[0];
  }

  sortByCategory = (cs1: CoordinateSystem, cs2: CoordinateSystem) => {
    const isRecommended1 = this.data.recommendedCoordinateSystems.find(cs => cs.id === cs1.id);
    const isRecommended2 = this.data.recommendedCoordinateSystems.find(cs => cs.id === cs2.id);
    const isSiteCS1 = this.siteCoordinateSystem && cs1.id === this.siteCoordinateSystem.id;
    const isSiteCS2 = this.siteCoordinateSystem && cs2.id === this.siteCoordinateSystem.id;

    if ((isRecommended1 && !isRecommended2) || isSiteCS1) {
      return -1;
    }

    if ((isRecommended2 && !isRecommended1) || isSiteCS2) {
      return 1;
    }

    if (cs1.code && cs2.code) {
      return cs1.code.localeCompare(cs2.code);
    } else if (!cs1.code && !cs2.code) {
      return cs1.name.localeCompare(cs2.name);
    } else {
      return cs1.code ? -1 : 1;
    }
  };

  get recommendedCount() {
    return this.data.recommendedCoordinateSystems.length;
  }

  get continueDisabled() {
    // Can't continue if selected option is MANUAL or RECOMMENDED and no value is selected
    return (
      [CoordinateSystemDialogOption.MANUAL, CoordinateSystemDialogOption.RECOMMENDED].includes(this.selectedOption) &&
      !this.selectedCoordinateSystem
    );
  }

  get selectedCoordinateSystem() {
    switch (this.selectedOption) {
      case CoordinateSystemDialogOption.MANUAL:
        return this.selectedManual.value;

      case CoordinateSystemDialogOption.RECOMMENDED:
        return this.selectedRecommended.value;

      case CoordinateSystemDialogOption.LOCAL:
        return { id: LOCAL_COORDINATE_SYSTEM_ID };

      default:
        return null;
    }
  }

  continue() {
    if (this.selectedOption === CoordinateSystemDialogOption.SUPPORT) {
      this.analytics.clickFlightWizardCoordinateSystemModifier(this.selectedOption, null, this.data.task);
      const emailTitle =
        this.data.openedFrom === 'GCP_RESIDUALS' ? SUPPORT_EMAIL_TITLE_MODEL_GENERATION : SUPPORT_EMAIL_TITLE_COORDINATE_SYSTEM;
      window.open(`mailto:${environment.whitelabel.supportEmail}?subject=${emailTitle}`);
      return;
    }

    const coordinateSystem = this.selectedCoordinateSystem;
    const canContinue = !this.data.canContinue || this.data.canContinue({ selectedOption: this.selectedOption, coordinateSystem });
    if (canContinue) {
      if (this.selectedOption === CoordinateSystemDialogOption.DELETE_GCPS) {
        this.analytics.clickFlightWizardCoordinateSystemModifier(this.selectedOption, null, this.data.task);
        this.data.onContinue?.({ selectedOption: this.selectedOption, coordinateSystem: null });
      } else {
        this.analytics.clickFlightWizardCoordinateSystemModifier(this.selectedOption, coordinateSystem, this.data.task);
        this.tenantService.updateSite(this.data.siteId, { coordinateSystem }).subscribe({
          error: error => {
            this.snackbar.openError(
              $localize`:@@shared.coordinateSystemModifier.errorUpdaingSiteCS:Error updating site coordinate system`,
              'Error updating site coordinate system',
              error
            );
            this.data.onCancel?.();
          },
          complete: () => {
            this.data.onContinue?.({ selectedOption: this.selectedOption, coordinateSystem });
          }
        });
      }

      this.dialogRef.close();
    }
  }

  close() {
    this.data.onCancel?.();
    this.dialogRef.close();
  }

  groupByFn = (coordinateSystem: CoordinateSystem) => {
    if (this.siteCoordinateSystem && this.siteCoordinateSystem.id === coordinateSystem.id) {
      return $localize`:@@shared.coordinateSystemModifier.coordinateSystemSelectGroupCurrent:Current`;
    }

    if (!this.data.recommendedCoordinateSystems || this.data.recommendedCoordinateSystems.length === 0) {
      return undefined;
    }

    const isRecommended = this.data.recommendedCoordinateSystems.find(cs => cs.id === coordinateSystem.id);
    if (isRecommended) {
      return $localize`:@@shared.coordinateSystemModifier.coordinateSystemSelectGroupRecommended:Recommended`;
    }

    return $localize`:@@shared.coordinateSystemModifier.coordinateSystemSelectGroupOther:Other`;
  };
}
