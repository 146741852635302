<div class="column align-center justify-center loading-process" [style.height.px]="hintMessage ? 240 : 210">
  <datu-loader></datu-loader>
  <div class="title">{{ title }}</div>
  <ng-container *ngIf="showProgress">
    <div class="upload-text files-size">{{ formatFileSize(uploadedSize) + ' / ' + formatFileSize(totalSize) }}</div>
    <mat-progress-bar [value]="(uploadedSize / totalSize) * 100"></mat-progress-bar>
    <div class="row bottom-container" *ngIf="totalFiles && totalFiles > 1" [ngClass]="cancelUpload.observers.length ? 'space-between' : 'justify-center'">
      <div class="upload-text">{{ uploadedFiles + ' / ' + totalFiles + ' were uploaded' }}</div>
      <button mat-button *ngIf="cancelUpload.observers.length" class="light-btn" (click)="cancelUpload.emit()">Cancel Upload</button>
    </div>
  </ng-container>

  <dialog-hint-message *ngIf="hintMessage">
    <mat-icon svgIcon="hand-point"></mat-icon>
    {{ hintMessage }}
  </dialog-hint-message>
</div>
