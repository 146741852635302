<div class="row full-container">
  <div class="select-wrap">
    <ng-select
      class="field-select-control color-palette-select"
      [items]="colorPalettes"
      [selectOnTab]="true"
      [clearable]="false"
      [readonly]="disabled"
      [searchable]="false"
      [(ngModel)]="colorPalette"
      dropdownPosition="bottom"
      (open)="scrollListToView()"
      (change)="gradientChanged($event)"
    >
      <ng-template ng-label-tmp let-item="item">
        <div class="transparent-bg-pattern">
          <color-palette [colors]="item.palette"></color-palette>
        </div>
      </ng-template>
      <ng-template ng-option-tmp let-item="item">
        <label class="custom-label" *ngIf="item.colorsPaletteType === ColorsPaletteType.NORMAL" i18n="@@shared.colorPaletteSelect.custom">Custom</label>
        <div class="transparent-bg-pattern">
          <color-palette [colors]="item.palette"></color-palette>
        </div>
      </ng-template>
      <ng-template ng-option-tmp let-item="item"> </ng-template>
    </ng-select>
    <div class="row space-between heatmap-colors-levels" *ngIf="(colorPalette?.min | isDefined) && (colorPalette.palette | isDefined)">
      <div>{{ colorPalette.min }}</div>
      <div>{{ colorPalette.max }}</div>
    </div>
  </div>

  <button
    mat-button
    class="light-btn edit-button"
    (click)="openDialog()"
    *ngIf="colorPalette?.colorsPaletteType === ColorsPaletteType.NORMAL && !disabled"
  >
    <mat-icon svgIcon="edit"></mat-icon>
    <span i18n="@@general.actionButtons.edit">Edit</span>
  </button>
</div>

<ng-template #editGradientDialog let-dialogRef="dialogRef">
  <dialog-layout i18n-header="@@shared.colorPaletteSelect.editColorsDialogHeader" header="Edit heatmap colors" (closeDialog)="dialogRef.close()">
    <div class="dialog-content-wrap">
      <color-slider
        #colorSlider
        colorMode="light"
        [minVal]="colorPalette.min"
        [maxVal]="colorPalette.max"
        [initialMin]="colorPalette.min"
        [initialMax]="colorPalette.max"
        [units]="unitsSign"
        [editableRange]="true"
        [rangePosition]="ColorSliderRangePosition.TOP"
        [colorRamps]="colorPalette.palette"
      ></color-slider>
    </div>
    <div class="row dialog-footer">
      <button mat-button type="button" class="light-btn" (click)="dialogRef.close()" i18n="@@general.actionButtons.cancel">Cancel</button>
      <button
        i18n="@@general.actionButtons.save"
        mat-flat-button
        type="button"
        class="dark-btn"
        (click)="dialogRef.close({ palette: colorSlider.colorRamps, max: colorSlider.max, min: colorSlider.min })"
      >Save</button>
    </div>
  </dialog-layout>
</ng-template>
