import { Pipe, PipeTransform } from '@angular/core';

import { LocaleService } from '../services/locale.service';

@Pipe({
  name: 'formatDateName'
})
export class FormatDateNamePipe implements PipeTransform {
  constructor(private localeService: LocaleService) {}

  transform(date: Date | string, withTime: boolean = false): string {
    return date ? this.localeService.formatDateName({ date, withTime }) : '-';
  }
}
