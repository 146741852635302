<ng-content></ng-content>

<div *ngIf="showDeltaButtons" class="row show-row">
  <label [style.width.px]="labelWidthPx">{{ labelText }}</label>
  <toggle-button-group [value]="showMode" (valueChange)="showModeChange($event)" [disabled]="disableDeltaButtons">
    <toggle-button [value]="ShowModeEnum.THIS_INSTANCE">
      {{ currentInstanceName }}
    </toggle-button>
    <toggle-button [value]="ShowModeEnum.ALL_INSTANCES">
      <datu-loader size="10" borderWidth="2" *ngIf="loadingDelta"></datu-loader>
      <span style="padding: 0 5px">{{ allInstancesName }}</span>
    </toggle-button>
  </toggle-button-group>
</div>

<div *ngIf="showDeltaButtons" class="row view-row" [class.hidden]="showMode !== ShowModeEnum.ALL_INSTANCES">
  <label>View</label>
  <toggle-button-group [value]="ViewModeEnum.TABLE" (valueChange)="viewModeChange($event)" [disabled]="disableDeltaButtons">
    <toggle-button [value]="ViewModeEnum.TABLE">
      <mat-icon svgIcon="table"></mat-icon>
    </toggle-button>
    <toggle-button [value]="ViewModeEnum.CHART">
      <mat-icon svgIcon="chart"></mat-icon>
    </toggle-button>
  </toggle-button-group>
</div>
