<ng-template #editableRangeInputs>
  <div class="row full-container space-between {{ colorMode }}-mode">
    <div class="row">
      <label i18n="@@shared.colorSlider.minLevel" *ngIf="rangePosition === ColorSliderRangePosition.TOP">Min. level</label>
      <div class="level-input-wrap small {{ colorMode }}-mode">
        <input type="number" class="level-input {{ colorMode }}-mode" [(ngModel)]="min" (change)="setMin($event.target.valueAsNumber)" step="0.01" />
        <span matSuffix class="units">{{ units }}</span>
      </div>
    </div>
    <div class="row">
      <label i18n="@@shared.colorSlider.maxLevel" *ngIf="rangePosition === ColorSliderRangePosition.TOP">Max. level</label>
      <div class="level-input-wrap small {{ colorMode }}-mode">
        <input type="number" class="level-input {{ colorMode }}-mode" [(ngModel)]="max" (change)="setMax($event.target.valueAsNumber)" step="0.01" />
        <span matSuffix class="units">{{ units }}</span>
      </div>
    </div>
  </div>
</ng-template>

<div class="editable-range-top-position" *ngIf="editableRange && rangePosition === ColorSliderRangePosition.TOP">
  <ng-container *ngTemplateOutlet="editableRangeInputs"></ng-container>
</div>

<div class="gradient-picker-container {{colorSliderViewMode === ColorSliderViewModes.INLINE ? 'row inline-mode' : 'column'}}">
  <div class="column">
    <div class="level-tooltip" *ngIf="levelTooltip.show" [style.left.px]="levelTooltip.position">
      {{levelTooltip.level}}
    </div>
    <div
      #gradientEl
      class="gradient-wrap {{ colorMode }}-mode"
      (mousemove)="setLevelTooltipValue($event)"
      (mouseenter)="toggleLevelTooltip(true)"
      (mouseleave)="toggleLevelTooltip(false)"
    ></div>
    <div class="row full-container scale-wrap {{ colorMode }}-mode">
      <div class="scale-tick" [ngClass]="{ 'step-ten': (i + 1) % 10 === 0, 'step-five': (i + 1) % 5 === 0 }" *ngFor="let a of [].constructor(20); let i = index"></div>
    </div>
    <div *ngIf="editableRange && rangePosition === ColorSliderRangePosition.BOTTOM">
      <ng-container *ngTemplateOutlet="editableRangeInputs"></ng-container>
    </div>
    <div class="row full-container space-between" *ngIf="!editableRange">
      <div class="column">
        {{ formatNumber(min, 'floor') }}
      </div>
      <div class="column align-end">
        {{ formatNumber(max, 'ceil') }}
      </div>
    </div>
  </div>

  <div class="row space-between handler-actions">
    <div class="{{colorSliderViewMode === ColorSliderViewModes.INLINE ? 'column' : 'row'}} align-center">
      <label *ngIf="editableRange && colorSliderViewMode === ColorSliderViewModes.INLINE">Selected color stop</label>
      <div class="level-input-wrap {{ colorMode }}-mode">
        <input
          #levelInput
          type="number"
          class="level-input {{ colorMode }}-mode"
          [(ngModel)]="selectedHandler.position"
          step="0.01"
          [max]="max"
          [min]="min"
          (change)="setHandlerPosition()"
          [disabled]="!gradientPicker.getSelected()"
        />
        <span matSuffix class="units">{{ units }}</span>
      </div>
      <div class="row">
        <button mat-icon-button class="icon-button {{ colorMode }}-mode" (click)="addNewHandler()" type="button" title="Add color stop">
          <mat-icon>add</mat-icon>
        </button>
        <button mat-icon-button class="icon-button {{ colorMode }}-mode" (click)="colorPicker.openMenu()" type="button" title="Change color">
          <mat-icon>format_color_fill</mat-icon>
        </button>
        <color-picker
          #colorPicker
          [color]="selectedHandler.color"
          [colors]="colorsPalette"
          triangle="top-right"
          (colorChanged)="setHandlerColor($event)"
          [disabled]="!gradientPicker.getSelected()"
          [isButtonVisible]="false"
        ></color-picker>
        <button mat-icon-button class="icon-button {{ colorMode }}-mode" (click)="removeHandler()" type="button" [title]="isRemoveHandleDisabled ? 'Min. 2 color stops' : 'Remove color stop'" [disabled]="isRemoveHandleDisabled">
          <mat-icon>delete</mat-icon>
        </button>
        <button mat-icon-button class="icon-button {{ colorMode }}-mode" (click)="resetGradientValues()" type="button" title="Reset to default" *ngIf="colorSliderViewMode === ColorSliderViewModes.INLINE">
          <mat-icon>autorenew</mat-icon>
        </button>
      </div>

    </div>
    <div class="row align-center" *ngIf="colorSliderViewMode !== ColorSliderViewModes.INLINE">
      <button mat-icon-button class="icon-button {{ colorMode }}-mode" (click)="resetGradientValues()" type="button" title="Reset to default">
        <mat-icon>autorenew</mat-icon>
      </button>
    </div>
  </div>
</div>
