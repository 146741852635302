import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  ActiveElement,
  Chart,
  ChartArea,
  ChartConfiguration,
  ChartDataset,
  ChartEvent,
  ChartType,
  ScaleTypeRegistry,
  ScatterDataPoint,
  TimeUnit
} from 'chart.js';
import { getRelativePosition } from 'chart.js/helpers';
import ChartAnnotationPlugin, { AnnotationOptions } from 'chartjs-plugin-annotation';
import ChartZoomPlugin from 'chartjs-plugin-zoom';
import { saveAs } from 'file-saver';
import { merge } from 'lodash';
import { BaseChartDirective } from 'ng2-charts';

import { ChartInterpolatePlugin, ChartInterpolatePluginOptions } from '../chart-plugins/chartjs-interpolate-plugin';
import VerticalHoverLinePlugin, { VerticalHoverLinePluginOptions } from '../chart-plugins/chartjs-plugin-vertical-hover-line';
import { ZOOM_PLUGIN_DEFAULT_OPTIONS } from '../chart-plugins/chartjs-zoom-plugin.utils';
import { ChartCustomTooltipService } from '../utils/chart-custom-tooltip.service';
import { LabelClickEvent } from './measurment-chart.model';
import { labelsXClickPlugin } from './measurment-chart.plugins';

interface DateDataPoint {
  x: Date;
  y: number;
}
export interface GraphData {
  label: string;
  color: string;
  dashed?: boolean;
  labels?: string[]; // only for category
  graphData: (Date | number | ScatterDataPoint | DateDataPoint)[]; // corresponds to the labels array
  id?: string;
  tooltip?: string;
}

const CHART_GRID_COLOR = '#d8d8d8';
const CHART_TICK_FONT_COLOR = '#384144';

const HIDE_IN_DOWNLOAD = 'hide-in-download';

const X_AXES_TICK_STYLE = {
  padding: 7,
  fontColor: CHART_TICK_FONT_COLOR,
  fontSize: 12,
  fontFamily: 'Roboto'
};

const Y_AXES_TICK_STYLE = {
  padding: 5,
  fontColor: CHART_TICK_FONT_COLOR,
  fontSize: 12,
  fontFamily: 'Roboto',
  fontStyle: 'bold'
};

const CHART_PLUGINS = [ChartAnnotationPlugin, ChartZoomPlugin, labelsXClickPlugin, VerticalHoverLinePlugin, ChartInterpolatePlugin];

@Component({
  selector: 'measurement-chart',
  templateUrl: './measurement-chart.component.html',
  styleUrls: ['./measurement-chart.component.scss']
})
export class MeasurementChartComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild(BaseChartDirective) private ngChart: BaseChartDirective;

  @Output() chartClicked = new EventEmitter<any>();
  @Output() chartLabelClicked = new EventEmitter<LabelClickEvent>();
  @Output() chartDownloadClicked = new EventEmitter<void>();
  @Output() chartLeave = new EventEmitter();
  @Output() chartHoveredX = new EventEmitter<any>();
  @Output() chartReverse = new EventEmitter<boolean>();

  @Input() graphsData: GraphData[];
  @Input() showXScaleRemoveButton = false;
  @Input() unitsX = 'm';
  @Input() unitsY = 'm';
  @Input() hideXAxes = false;
  @Input() padding: ChartArea;
  @Input() lineChartType: ChartType = 'line';
  @Input() gridLinesX = false;
  @Input() gridLinesY = false;
  @Input() allowZoom = false;
  @Input() showPoint = true;
  @Input() showLegend = true;
  @Input() showTooltipTitle = true;
  @Input() allowChartGaps = false;
  @Input() xAxisType: keyof ScaleTypeRegistry = 'category';
  @Input() xTimeAxisMinUnit: TimeUnit = 'day';
  @Input() tension: number = undefined;
  @Input() interpolate = false;
  @Input() closeButtonChar: string;
  @Input() showReverseButton = false;
  @Input() showDownloadButton = false;
  @Input() exportImageName?: string = $localize`:@@shared.measurementChart.exportImageNameDefault:chart`;
  @Input() showExtraData = false;
  @Input() annotations: AnnotationOptions[];
  @Input() maxY: number;
  @Input() maxX: any;

  @Input() set isReversed(reverse: boolean) {
    this.lineChartOptions = {
      ...merge(this.lineChartOptions, {
        scales: { x: { reverse } }
      })
    };
  }

  public lineChartData: ChartDataset<'line'>[] = [];
  public lineChartLabels: string[];
  public lineChartPlugins = CHART_PLUGINS;
  public lineChartOptions: ChartConfiguration['options'] & { pan?: any; zoom?: any } = {
    responsive: true,
    maintainAspectRatio: false
  };

  protected downloadingImage = false;
  protected showResetZoomButton = false;

  hideInDownloadClass = HIDE_IN_DOWNLOAD;
  constructor(
    private chartCustomTooltipService: ChartCustomTooltipService,
    private elementRef: ElementRef,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    const defaultOptions: ChartConfiguration['options'] & {
      plugins: {
        zoom: any;
        labelsXClickPlugin: any;
        tooltip: any;
        annotation: any;
        verticalHoverLine: VerticalHoverLinePluginOptions;
        interpolate: ChartInterpolatePluginOptions;
      };
    } = {
      devicePixelRatio: 3,
      scales: {
        x: {
          suggestedMax: this.maxX,
          type: this.xAxisType,
          grid: {
            drawOnChartArea: this.gridLinesX,
            drawTicks: false,
            lineWidth: 2,
            color: CHART_GRID_COLOR
          },
          time: { minUnit: this.xTimeAxisMinUnit },
          reverse: false,
          ticks: {
            ...X_AXES_TICK_STYLE,
            display: !this.hideXAxes
          }
        },
        y: {
          suggestedMax: this.maxY,
          grid: {
            drawOnChartArea: this.gridLinesY,
            drawTicks: false,
            lineWidth: 2,
            color: CHART_GRID_COLOR
          },
          ticks: Y_AXES_TICK_STYLE
        }
      },
      onHover: this.chartHover(),
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          ...this.padding
        }
      },
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          enabled: false,
          mode: this.interpolate ? 'interpolate' : 'x',
          position: this.interpolate ? undefined : 'nearest',
          intersect: false,
          external: this.chartCustomTooltipService.customTooltip({
            closeButtonChar: this.closeButtonChar,
            showTooltipTitle: this.showTooltipTitle,
            xAxisType: this.xAxisType
          }),
          callbacks: {
            label: function (d) {
              return d.dataset.label + ': ' + (d.dataset.interpolatedValue || d.parsed?.y)?.toFixed(2);
            }
          }
        } as any,
        verticalHoverLine: {
          enabled: this.interpolate
        },
        interpolate: {
          enabled: this.interpolate
        },
        zoom: this.allowZoom && {
          ...ZOOM_PLUGIN_DEFAULT_OPTIONS,
          zoom: {
            ...ZOOM_PLUGIN_DEFAULT_OPTIONS.zoom,
            onZoom: () => {
              this.showResetZoomButton = true;
            }
          }
        },
        labelsXClickPlugin: {
          chartLabelClicked: this.chartLabelClicked
        },
        annotation: {
          annotations: this.annotations
        }
      }
    };

    this.lineChartOptions = merge(this.lineChartOptions, defaultOptions);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.graphsData) {
      if (this.showXScaleRemoveButton) {
        changes.graphsData.currentValue.forEach(cv => {
          cv.labels = cv.labels.length > 1 ? cv.labels.map(l => l + this.closeButtonChar) : cv.labels;
        });
      }
      this.parseChartData(changes.graphsData.currentValue);
    }

    if (changes.showReverseButton?.currentValue && changes.showDownloadButton?.currentValue) {
      // Show error when developer wants to show both button, since they are at the same place in the canvas
      throw new Error('ERROR: Cannot show both download and reverse buttons in measurement chart');
    }
  }

  ngOnDestroy() {
    this.chartCustomTooltipService.removeTooltip();
  }

  private parseChartData(data: GraphData[]) {
    // Handle empty data
    data = data || [];

    if (data[0]?.labels) {
      this.lineChartLabels = data[0].labels;
    }
    this.lineChartData = data.map(graphInfo => {
      const result = {
        interpolate: this.interpolate,
        data: graphInfo.graphData,
        label: graphInfo.label,
        tooltip: graphInfo.tooltip,
        fill: false,
        spanGaps: this.allowChartGaps,
        tension: 0,
        borderColor: graphInfo.color,
        pointBackgroundColor: 'white',
        pointHoverBackgroundColor: 'white',
        pointHoverBorderColor: graphInfo.color,
        pointBorderColor: graphInfo.color,
        pointBorderWidth: 2.5,
        pointHoverBorderWidth: 3.5,
        pointRadius: this.showPoint ? 4 : 0,
        pointHoverRadius: this.showPoint ? 6 : 0,
        pointHitRadius: this.interpolate ? 12 : 0,
        borderJoinStyle: 'round',
        borderCapStyle: 'round'
      } as ChartDataset<'line'>;

      return graphInfo.dashed ? { ...result, borderDash: [16, 8], borderWidth: 3 } : result;
    });
  }

  checkboxChange(data: ChartDataset) {
    this.lineChartData = this.lineChartData.map(seriesData => {
      if (seriesData.label === data.label) {
        return { ...seriesData, hidden: !seriesData.hidden };
      }
      return seriesData;
    });
  }

  private chartHover = () => {
    const chartHoveredX = this.chartHoveredX;

    // Return function instead of arrow to preserve "this" of chart.js callback
    return function (event: ChartEvent, activeElements: ActiveElement[], chart: Chart) {
      if (activeElements && activeElements.length > 0) {
        const index = activeElements?.[0]?.datasetIndex;
        const relativePosition = getRelativePosition(event, this.chart);
        const x = chart.scales.x.getValueForPixel(relativePosition.x);
        if (x >= 0) {
          chartHoveredX.emit({ index, x });
        } else {
          chartHoveredX.emit(null);
        }
      } else {
        chartHoveredX.emit(null);
      }
    };
  };

  get hasXAxisName() {
    return this.unitsX && this.unitsX.length > 0;
  }

  get hasYAxisName() {
    return this.unitsY && this.unitsY.length > 0;
  }

  async downloadChartAsImage() {
    this.chartDownloadClicked.emit();

    this.downloadingImage = true;
    const html2canvas = (await import('../../../../node_modules/html2canvas')).default;
    const canvas = await html2canvas(this.elementRef.nativeElement, {
      scale: 3,
      backgroundColor: null,
      ignoreElements: el => {
        return el.classList.contains(HIDE_IN_DOWNLOAD);
      }
    });
    saveAs(canvas.toDataURL(), this.exportImageName);

    this.downloadingImage = false;
    this.cd.detectChanges();
  }

  onResetZoom() {
    this.ngChart.chart.resetZoom();
    this.showResetZoomButton = false;
  }

  onToggleReverseChart() {
    this.showResetZoomButton = false;
    this.chartReverse.emit(!this.lineChartOptions.scales.x.reverse);
  }
}
