// Given a hash of GeoJSON objects, returns a hash of GeoJSON geometry objects.
// Any null input geometry objects are represented as {type: null} in the output.
// Any feature.{id,properties,bbox} are transferred to the output geometry object.
// Each output geometry object is a shallow copy of the input (e.g., properties, coordinates)!
export default function (inputs) {
  var outputs = {},
    key;
  for (key in inputs) outputs[key] = geomifyObject(inputs[key]);
  return outputs;
}
function geomifyObject(input) {
  return input == null ? {
    type: null
  } : (input.type === "FeatureCollection" ? geomifyFeatureCollection : input.type === "Feature" ? geomifyFeature : geomifyGeometry)(input);
}
function geomifyFeatureCollection(input) {
  var output = {
    type: "GeometryCollection",
    geometries: input.features.map(geomifyFeature)
  };
  if (input.bbox != null) output.bbox = input.bbox;
  return output;
}
function geomifyFeature(input) {
  var output = geomifyGeometry(input.geometry),
    key; // eslint-disable-line no-unused-vars
  if (input.id != null) output.id = input.id;
  if (input.bbox != null) output.bbox = input.bbox;
  for (key in input.properties) {
    output.properties = input.properties;
    break;
  }
  return output;
}
function geomifyGeometry(input) {
  if (input == null) return {
    type: null
  };
  var output = input.type === "GeometryCollection" ? {
    type: "GeometryCollection",
    geometries: input.geometries.map(geomifyGeometry)
  } : input.type === "Point" || input.type === "MultiPoint" ? {
    type: input.type,
    coordinates: input.coordinates
  } : {
    type: input.type,
    arcs: input.coordinates
  }; // TODO Check for unknown types?
  if (input.bbox != null) output.bbox = input.bbox;
  return output;
}