// index.ts
import { coordEach } from "@turf/meta";
import { isObject } from "@turf/helpers";
function truncate(geojson, options) {
  options = options != null ? options : {};
  if (!isObject(options)) throw new Error("options is invalid");
  var precision = options.precision;
  var coordinates = options.coordinates;
  var mutate = options.mutate;
  precision = precision === void 0 || precision === null || isNaN(precision) ? 6 : precision;
  coordinates = coordinates === void 0 || coordinates === null || isNaN(coordinates) ? 3 : coordinates;
  if (!geojson) throw new Error("<geojson> is required");
  if (typeof precision !== "number") throw new Error("<precision> must be a number");
  if (typeof coordinates !== "number") throw new Error("<coordinates> must be a number");
  if (mutate === false || mutate === void 0) geojson = JSON.parse(JSON.stringify(geojson));
  var factor = Math.pow(10, precision);
  coordEach(geojson, function (coords) {
    truncateCoords(coords, factor, coordinates);
  });
  return geojson;
}
function truncateCoords(coords, factor, coordinates) {
  if (coords.length > coordinates) coords.splice(coordinates, coords.length);
  for (var i = 0; i < coords.length; i++) {
    coords[i] = Math.round(coords[i] * factor) / factor;
  }
  return coords;
}
var turf_truncate_default = truncate;
export { turf_truncate_default as default, truncate };
