import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export enum ReferenceSurfaceTipType {
  VOLUME = 'VOLUME',
  ELEVATION = 'ELEVATION'
}

export interface ReferenceSurfaceTipData {
  type: ReferenceSurfaceTipType;
  referenceSurfaceName?: string;
  flightName?: string;
}

@Component({
  selector: 'reference-surface-tip-dialog',
  templateUrl: './reference-surface-tip-dialog.component.html',
  styleUrls: ['./reference-surface-tip-dialog.component.scss']
})
export class ReferenceSurfaceTipDialogComponent {
  type: ReferenceSurfaceTipType;
  referenceSurfaceName: string;
  flightName: string;
  constructor(@Inject(MAT_DIALOG_DATA) data: ReferenceSurfaceTipData) {
    this.type = data.type;
    this.referenceSurfaceName = data.referenceSurfaceName;
    this.flightName = data.flightName;
  }
}
