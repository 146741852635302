import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

import { IframeDialogComponent, IframeDialogData } from './iframe-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class IframeDialogService {
  constructor(private dialog: MatDialog, private sanitizer: DomSanitizer) {}

  openDialog(url: string, dialogConfig?: MatDialogConfig) {
    const safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    return this.dialog.open<IframeDialogComponent, IframeDialogData, boolean>(IframeDialogComponent, {
      autoFocus: false,
      restoreFocus: false,
      width: '90%',
      height: '90%',
      ...dialogConfig,
      data: {
        url: safeUrl
      }
    });
  }
}
