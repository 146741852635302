/**
 * Measurements Management Service
 * APIs document (version: 2.2.0)
 *
 * OpenAPI spec version: 2.2.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { MarkBlockModel } from './markBlockModel';

export interface GcpBlockResponse {
  estimatedX?: number;
  estimatedY?: number;
  estimatedZ?: number;
  gcpType?: GcpBlockResponse.GcpTypeEnum;
  id?: string;
  latitude?: number;
  longitude?: number;
  marks?: Array<MarkBlockModel>;
  name?: string;
  redFlag?: boolean;
  sigmaX?: number;
  sigmaY?: number;
  sigmaZ?: number;
  x?: number;
  y?: number;
  z?: number;
}
export namespace GcpBlockResponse {
  export type GcpTypeEnum = 'GCP' | 'CHECKPOINT';
  export const GcpTypeEnum = {
    GCP: 'GCP' as GcpTypeEnum,
    CHECKPOINT: 'CHECKPOINT' as GcpTypeEnum
  };
}
