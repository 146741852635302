import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export enum DetailsBoxMode {
  SIMPLE,
  TABLE,
  CHART
}

export enum ShowModeEnum {
  THIS_INSTANCE,
  ALL_INSTANCES
}

enum ViewModeEnum {
  TABLE,
  CHART
}

@Component({
  selector: 'map-modal-subtitle',
  templateUrl: './map-modal-subtitle.component.html',
  styleUrls: ['./map-modal-subtitle.component.scss']
})
export class MapModalSubtitleComponent implements OnInit {
  ShowModeEnum = ShowModeEnum;
  ViewModeEnum = ViewModeEnum;

  @Output() changeMode = new EventEmitter<DetailsBoxMode>();

  @Input() showDeltaButtons = true;
  @Input() disableDeltaButtons: boolean;
  @Input() loadingDelta: boolean;
  @Input() currentInstanceName: string;
  @Input() allInstancesName = $localize`:@@shared.mapModalSubtitle.allInstancesButtonName:All mapping instances`;
  @Input() labelText = $localize`:@@shared.mapModalSubtitle.deltaLabelText:Show`;
  @Input() labelWidthPx = 30;

  showMode = ShowModeEnum.THIS_INSTANCE;
  viewMode = ViewModeEnum.TABLE;

  @Input() set displayMode(mode: DetailsBoxMode) {
    switch (mode) {
      case DetailsBoxMode.SIMPLE: {
        this.showMode = ShowModeEnum.THIS_INSTANCE;
        break;
      }
      case DetailsBoxMode.TABLE: {
        this.showMode = ShowModeEnum.ALL_INSTANCES;
        this.viewMode = ViewModeEnum.TABLE;
        break;
      }
      case DetailsBoxMode.CHART: {
        this.showMode = ShowModeEnum.ALL_INSTANCES;
        this.viewMode = ViewModeEnum.CHART;
        break;
      }
    }
  }

  constructor() {}

  ngOnInit() {}

  showModeChange(mode: ShowModeEnum) {
    this.showMode = mode;
    this.emitChange();
  }

  viewModeChange(mode: ViewModeEnum) {
    this.viewMode = mode;
    this.emitChange();
  }

  emitChange() {
    let mode: DetailsBoxMode;
    if (this.showMode === ShowModeEnum.THIS_INSTANCE) {
      mode = DetailsBoxMode.SIMPLE;
    } else if (this.viewMode === ViewModeEnum.TABLE) {
      mode = DetailsBoxMode.TABLE;
    } else {
      mode = DetailsBoxMode.CHART;
    }

    this.changeMode.emit(mode);
  }
}
