import {
  AnalyticType,
  AnnotationType,
  DEFAULT_LAYER_COLOR,
  MeasurementType,
  ModelEditType
} from '../../../state/detailed-site-entities/detailed-site-entities.model';

export const MAX_MULTI_POINT_COUNT = 100;

export const NORMAL_PRIORITY = 10;
export const EDITOR_PRIORITY = 1000;

const PINNED_MEASUREMENT_OPACITY = 0.4;
const ACTIVE_MEASUREMENT_OPACITY = 0.6;

export const MEASUREMENT_SECONDARY_COLOR = Cesium.Color.WHITE.withAlpha(ACTIVE_MEASUREMENT_OPACITY);

export function getMeasurementColor({
  color = DEFAULT_LAYER_COLOR,
  type = 'active'
}: {
  color?: string;
  type?: 'active' | 'pinned';
} = {}) {
  return Cesium.Color.fromCssColorString(color).withAlpha(type === 'active' ? ACTIVE_MEASUREMENT_OPACITY : PINNED_MEASUREMENT_OPACITY);
}

export const ENTITY_DEFAULT_NAMES = {
  [AnnotationType.ANNOTATION]: $localize`:@@detailedSite.entityDefaultNameAnnotation:New annotation`,
  [MeasurementType.ANGLE]: $localize`:@@detailedSite.entityDefaultNameAngle:New angle`,
  [MeasurementType.AREA]: $localize`:@@detailedSite.entityDefaultNameArea:New area`,
  [MeasurementType.DISTANCE]: $localize`:@@detailedSite.entityDefaultNameDistance:New distance`,
  [MeasurementType.CROSS_SECTION]: $localize`:@@detailedSite.entityDefaultNameCrossSection:New cross section`,
  [MeasurementType.POINT]: $localize`:@@detailedSite.entityDefaultNamePoint:New point`,
  [MeasurementType.VOLUME]: $localize`:@@detailedSite.entityDefaultNameVolume:New volume`,
  [AnalyticType.CROSS_SECTION_PROGRESS]: $localize`:@@detailedSite.entityDefaultNameCrossSectionProgress:New cross section progress`,
  [AnalyticType.POINT_DELTA_ELEVATION]: $localize`:@@detailedSite.entityDefaultNamePointDeltaElevation:New point delta elevation`,
  [AnalyticType.POLYGON_DELTA_ELEVATION]: $localize`:@@detailedSite.entityDefaultNamePolygonDeltaElevation:New delta elevation`,
  [AnalyticType.POLYGON_DELTA_VOLUME]: $localize`:@@detailedSite.entityDefaultNamePolygonDeltaVolume:New delta volume`,
  [ModelEditType.FILTER]: $localize`:@@detailedSite.entityDefaultNameFilter:New filtered area`
};

export const ENTITY_TYPE_NAMES = {
  [AnnotationType.ANNOTATION]: $localize`:@@detailedSite.entityTypeNameAnnotation:Annotation`,
  [MeasurementType.ANGLE]: $localize`:@@detailedSite.entityTypeNameAngle:Angle`,
  [MeasurementType.AREA]: $localize`:@@detailedSite.entityTypeNameArea:Area`,
  [MeasurementType.DISTANCE]: $localize`:@@detailedSite.entityTypeNameDistance:Distance`,
  [MeasurementType.CROSS_SECTION]: $localize`:@@detailedSite.entityTypeNameCrossSection:Cross Section`,
  [MeasurementType.POINT]: $localize`:@@detailedSite.entityTypeNamePoint:Point`,
  [MeasurementType.VOLUME]: $localize`:@@detailedSite.entityTypeNameVolume:Volume`,
  [AnalyticType.CROSS_SECTION_PROGRESS]: $localize`:@@detailedSite.entityTypeNameCrossSectionProgress:Cross Section Progress`,
  [AnalyticType.POINT_DELTA_ELEVATION]: $localize`:@@detailedSite.entityTypeNamePointDeltaElevation:Point Delta Elevation`,
  [AnalyticType.POLYGON_DELTA_ELEVATION]: $localize`:@@detailedSite.entityTypeNamePolygonDeltaElevation:Delta Elevation`,
  [AnalyticType.POLYGON_DELTA_VOLUME]: $localize`:@@detailedSite.entityTypeNamePolygonDeltaVolume:Delta Volume`,
  [ModelEditType.FILTER]: $localize`:@@detailedSite.entityTypeNameFilter:Filtered Area`
};

export const ACTIVITY_MEASUREMENT_COLOR = {
  PLANNED: '#3d405b',
  ACTUAL: '#2a9d8f',
  FORECAST: '#e07a5f',
  GOOD: 'rgba(3, 176, 50, 0.20)',
  ERROR: 'rgba(179, 68, 59, 0.15)',
  DIFFERENCE: '#000000',
  TRANSPARENT: '#00000000'
};
