import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { ColorsPaletteModel } from '../../../../generated/file/model/colorsPaletteModel';
import { isDefined } from '../../utils/general';

const TRANSPARENT_BG_STR = 'var(--transparent-bg)';

@Component({
  selector: 'color-palette',
  template: `<div class="palette" [style.background]="background"></div>`,
  styleUrls: ['./color-palette.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColorPaletteComponent implements OnInit {
  @Input() colors: ColorsPaletteModel[];

  isEqualRanges = false;

  constructor() {}

  ngOnInit() {
    this.isEqualRanges = this.colors.every(cp => cp.position === 0);
  }

  get background() {
    if (!isDefined(this.colors)) {
      return TRANSPARENT_BG_STR;
    }

    // Solid color
    if (this.colors.length === 1) {
      if (isDefined(this.colors[0]?.color)) {
        return this.colors[0].color;
      }
      return TRANSPARENT_BG_STR;
    }

    // Gradient
    const gradientString = this.colors.map(({ color, position }) => `${color} ${this.formatGradientPosition(position)}`).join(', ');
    return `linear-gradient(to right, ${gradientString})`;
  }

  private formatGradientPosition(position: number) {
    if (isDefined(position) && !this.isEqualRanges) {
      return `${position * 100}%`;
    }
    return '';
  }
}
