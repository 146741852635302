import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { GetAnnouncementResponse } from '../../../../../generated/nms/model/getAnnouncementResponse';
import { AnalyticsService } from '../../../services/analytics.service';
import { isDefined } from '../../../utils/general';
import { Announcement } from '../state/announcements.store';

export enum AnnouncementDialogCloseType {
  SNOOZE = 'SNOOZE',
  CLOSE = 'CLOSE'
}

export interface AnnouncementsDialogResult {
  closeType: AnnouncementDialogCloseType;
}

export interface AnnouncementsDialogData {
  announcement: Announcement;
  showSnoozeBtn: boolean;
}

@Component({
  selector: 'app-announcements-dialog',
  templateUrl: './announcements-dialog.component.html',
  styleUrls: ['./announcements-dialog.component.scss']
})
export class AnnouncementsDialogComponent implements OnInit {
  AnnouncementMediaTypes = GetAnnouncementResponse.MediaTypeEnum;

  showMediaFullscreen = false;
  fullscreenOrigin: string;
  announcement: Announcement;

  constructor(
    public dialogRef: MatDialogRef<AnnouncementsDialogComponent, AnnouncementsDialogResult>,
    @Inject(MAT_DIALOG_DATA) public dialogData: AnnouncementsDialogData,
    private analytics: AnalyticsService
  ) {
    this.announcement = this.dialogData.announcement;
  }

  ngOnInit() {}

  actionButtonClick() {
    if (isDefined(this.announcement.buttonLink)) {
      window.open(this.announcement.buttonLink, '_blank');
    }
    this.dialogRef.close({
      closeType: AnnouncementDialogCloseType.CLOSE
    });
    this.analytics.announcementLinkBtnClicked(this.announcement);
  }

  openMediaFullscreen() {
    document.addEventListener(
      'keydown',
      e => {
        if (e.key === 'Escape') {
          this.showMediaFullscreen = false;
          e.preventDefault();
        }
      },
      { once: true }
    );
    this.showMediaFullscreen = true;
    this.analytics.announcementImgEnlarged(this.announcement);
  }

  closeFullscreen() {
    this.showMediaFullscreen = false;
  }

  dialogCloseClick() {
    this.dialogRef.close({
      closeType: AnnouncementDialogCloseType.CLOSE
    });
    this.analytics.announcementDialogCloseBtnClick(this.announcement);
  }

  dialogSnoozeClick() {
    this.dialogRef.close({
      closeType: AnnouncementDialogCloseType.SNOOZE
    });
    this.analytics.announcementDialogSnoozeBtnClick(this.announcement);
  }
}
