import { Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DropdownPosition, NgSelectComponent } from '@ng-select/ng-select';
import { Observable } from 'rxjs';

import { AccessLevelEnum } from '../../../auth/state/auth.utils';
import {
  DEFAULT_GROUP_LAYER_ID,
  Group,
  LATEST_GROUP_SELECTED_STORAGE_KEY,
  LATEST_LAYER_SELECTED_STORAGE_KEY,
  Layer
} from '../../../detailed-site/state/detailed-site-entities/detailed-site-entities.model';
import { DetailedSiteEntitiesQuery } from '../../../detailed-site/state/detailed-site-entities/detailed-site-entities.query';
import { DetailedSiteEntitiesService } from '../../../detailed-site/state/detailed-site-entities/detailed-site-entities.service';
import { SnackBarService } from '../../services/snackbar.service';
import { GroupLayerDetailsService } from '../group-layer-details/group-layer-details.service';
import { GroupsLayersManagerService } from '../groups-layers-manager/groups-layers-manager.service';

@Component({
  selector: 'groups-layers-select',
  template: `
    <ng-select
      class="field-select-control groups-layers-select"
      [items]="values$ | async"
      [readonly]="disabled"
      [selectOnTab]="true"
      bindLabel="name"
      bindValue="id"
      [placeholder]="'Select ' + type"
      [(ngModel)]="groupLayerId"
      [appendTo]="appendToBody ? 'body' : ''"
      [dropdownPosition]="dropdownPosition"
    >
      <ng-template ng-option-tmp let-item="item">
        <div class="row" [title]="item.name">
          <div *ngIf="type === 'layer'" class="layer-circle" [style.backgroundColor]="item.color"></div>
          {{ item.name }}
        </div>
      </ng-template>
      <ng-template ng-label-tmp let-item="item">
        <div class="row" [title]="item.name">
          <div *ngIf="type === 'layer'" class="layer-circle" [style.backgroundColor]="item.color"></div>
          {{ item.name }}
        </div>
      </ng-template>
      <ng-template ng-footer-tmp>
        <div class="select-actions column">
          <a (click)="createNew()">Create new {{ type }}</a>
          <a (click)="manage()">Manage {{ type }}s</a>
        </div>
      </ng-template>
    </ng-select>
  `,
  styleUrls: ['./groups-layers-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GroupsLayersSelectComponent),
      multi: true
    }
  ]
})
export class GroupsLayersSelectComponent implements OnInit, ControlValueAccessor {
  @ViewChild(NgSelectComponent, { static: true }) selectElement: NgSelectComponent;

  @Output() changeValue = new EventEmitter();

  @Input() type: 'group' | 'layer';
  @Input() accessLevel: AccessLevelEnum;
  @Input() values$: Observable<Array<Group | Layer>>;
  @Input() disabled: boolean;
  @Input() appendToBody: boolean;
  @Input() dropdownPosition: DropdownPosition = 'auto';

  value: string;
  get groupLayerId() {
    return this.value;
  }
  @Input() set groupLayerId(value: string) {
    this.value = value;
    localStorage.setItem(
      this.type === 'group' ? LATEST_GROUP_SELECTED_STORAGE_KEY : LATEST_LAYER_SELECTED_STORAGE_KEY,
      value || DEFAULT_GROUP_LAYER_ID
    );

    this.onChange(value);
    this.onTouched();
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  constructor(
    private siteEntitiesService: DetailedSiteEntitiesService,
    private siteEntitiesQuery: DetailedSiteEntitiesQuery,
    private groupLayersDetails: GroupLayerDetailsService,
    private groupLayersManager: GroupsLayersManagerService,
    private snackbar: SnackBarService
  ) {}

  ngOnInit() {}

  writeValue(value: string) {
    if (value) {
      this.value = value;
    }
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  valueChange(id: string) {
    this.groupLayerId = id;
    this.changeValue.emit(id);
  }

  createNew() {
    this.selectElement.close();

    this.groupLayersDetails.openDialog({
      type: this.type,
      onSave: (data: Group | Layer) => this.createNewGroupLayer(data)
    });
  }

  private createNewGroupLayer(data: Group | Layer) {
    const siteId = this.siteEntitiesQuery.getSiteId();

    if (this.type === 'layer') {
      this.siteEntitiesService.createNewLayer(siteId, data).subscribe({
        next: (response: { id: string }) => this.valueChange(response.id),
        error: response => {
          this.snackbar.openError('Error creating layer', response);
        }
      });
    } else {
      this.siteEntitiesService.createNewGroup(siteId, data).subscribe({
        next: (response: { id: string }) => this.valueChange(response.id),
        error: response => {
          this.snackbar.openError('Error creating group', response);
        }
      });
    }
  }

  manage() {
    this.selectElement.close();

    this.groupLayersManager.openDialog({
      type: this.type,
      values$: this.values$,
      onCreate: () => this.createNew(),
      onEdit: data => this.edit(data),
      onDelete: (id: string) => this.delete(id)
    });
  }

  private delete(id: string) {
    this.deleteGroupLayer(id);

    this.valueChange('');
  }

  private deleteGroupLayer(id: string) {
    const siteId = this.siteEntitiesQuery.getSiteId();

    if (this.type === 'layer') {
      this.siteEntitiesService.deleteLayer(siteId, id).subscribe({
        error: response => {
          this.snackbar.openError('Error deleting layer', response);
        }
      });
    } else {
      this.siteEntitiesService.deleteGroup(siteId, id).subscribe({
        error: response => {
          this.snackbar.openError('Error deleting group', response);
        }
      });
    }
  }

  private edit(data: Group | Layer) {
    this.groupLayersDetails.openDialog({
      values: data,
      type: this.type,
      onSave: (data: Group | Layer) => this.editGroupLayer(data)
    });
  }

  private editGroupLayer(data: Group | Layer) {
    const siteId = this.siteEntitiesQuery.getSiteId();

    if (this.type === 'layer') {
      this.siteEntitiesService.updateLayer(siteId, data).subscribe({
        error: response => {
          this.snackbar.openError('Error updating layer', response);
        }
      });
    } else {
      this.siteEntitiesService.updateGroup(siteId, data).subscribe({
        error: response => {
          this.snackbar.openError('Error updating group', response);
        }
      });
    }
  }
}
