// index.ts
import { booleanPointInPolygon } from "@turf/boolean-point-in-polygon";
import { lineIntersect } from "@turf/line-intersect";
import { flattenEach } from "@turf/meta";
import { polygonToLine } from "@turf/polygon-to-line";
function booleanDisjoint(feature1, feature2, {
  ignoreSelfIntersections = true
} = {
  ignoreSelfIntersections: true
}) {
  let bool = true;
  flattenEach(feature1, flatten1 => {
    flattenEach(feature2, flatten2 => {
      if (bool === false) {
        return false;
      }
      bool = disjoint(flatten1.geometry, flatten2.geometry, ignoreSelfIntersections);
    });
  });
  return bool;
}
function disjoint(geom1, geom2, ignoreSelfIntersections) {
  switch (geom1.type) {
    case "Point":
      switch (geom2.type) {
        case "Point":
          return !compareCoords(geom1.coordinates, geom2.coordinates);
        case "LineString":
          return !isPointOnLine(geom2, geom1);
        case "Polygon":
          return !booleanPointInPolygon(geom1, geom2);
      }
      break;
    case "LineString":
      switch (geom2.type) {
        case "Point":
          return !isPointOnLine(geom1, geom2);
        case "LineString":
          return !isLineOnLine(geom1, geom2, ignoreSelfIntersections);
        case "Polygon":
          return !isLineInPoly(geom2, geom1, ignoreSelfIntersections);
      }
      break;
    case "Polygon":
      switch (geom2.type) {
        case "Point":
          return !booleanPointInPolygon(geom2, geom1);
        case "LineString":
          return !isLineInPoly(geom1, geom2, ignoreSelfIntersections);
        case "Polygon":
          return !isPolyInPoly(geom2, geom1, ignoreSelfIntersections);
      }
  }
  return false;
}
function isPointOnLine(lineString, pt) {
  for (let i = 0; i < lineString.coordinates.length - 1; i++) {
    if (isPointOnLineSegment(lineString.coordinates[i], lineString.coordinates[i + 1], pt.coordinates)) {
      return true;
    }
  }
  return false;
}
function isLineOnLine(lineString1, lineString2, ignoreSelfIntersections) {
  const doLinesIntersect = lineIntersect(lineString1, lineString2, {
    ignoreSelfIntersections
  });
  if (doLinesIntersect.features.length > 0) {
    return true;
  }
  return false;
}
function isLineInPoly(polygon, lineString, ignoreSelfIntersections) {
  for (const coord of lineString.coordinates) {
    if (booleanPointInPolygon(coord, polygon)) {
      return true;
    }
  }
  const doLinesIntersect = lineIntersect(lineString, polygonToLine(polygon), {
    ignoreSelfIntersections
  });
  if (doLinesIntersect.features.length > 0) {
    return true;
  }
  return false;
}
function isPolyInPoly(feature1, feature2, ignoreSelfIntersections) {
  for (const coord1 of feature1.coordinates[0]) {
    if (booleanPointInPolygon(coord1, feature2)) {
      return true;
    }
  }
  for (const coord2 of feature2.coordinates[0]) {
    if (booleanPointInPolygon(coord2, feature1)) {
      return true;
    }
  }
  const doLinesIntersect = lineIntersect(polygonToLine(feature1), polygonToLine(feature2), {
    ignoreSelfIntersections
  });
  if (doLinesIntersect.features.length > 0) {
    return true;
  }
  return false;
}
function isPointOnLineSegment(lineSegmentStart, lineSegmentEnd, pt) {
  const dxc = pt[0] - lineSegmentStart[0];
  const dyc = pt[1] - lineSegmentStart[1];
  const dxl = lineSegmentEnd[0] - lineSegmentStart[0];
  const dyl = lineSegmentEnd[1] - lineSegmentStart[1];
  const cross = dxc * dyl - dyc * dxl;
  if (cross !== 0) {
    return false;
  }
  if (Math.abs(dxl) >= Math.abs(dyl)) {
    if (dxl > 0) {
      return lineSegmentStart[0] <= pt[0] && pt[0] <= lineSegmentEnd[0];
    } else {
      return lineSegmentEnd[0] <= pt[0] && pt[0] <= lineSegmentStart[0];
    }
  } else if (dyl > 0) {
    return lineSegmentStart[1] <= pt[1] && pt[1] <= lineSegmentEnd[1];
  } else {
    return lineSegmentEnd[1] <= pt[1] && pt[1] <= lineSegmentStart[1];
  }
}
function compareCoords(pair1, pair2) {
  return pair1[0] === pair2[0] && pair1[1] === pair2[1];
}
var turf_boolean_disjoint_default = booleanDisjoint;
export { booleanDisjoint, turf_boolean_disjoint_default as default };
