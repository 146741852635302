import { Injectable } from '@angular/core';
import { Exifr } from '@ryanccn/exifr';

import { Cartographic } from '../utils/geo';

export interface ImageExifData {
  date: string;
  location: Cartographic;
  resolution: {
    width?: number;
    height?: number;
  };
  thumbnail: Blob;
  camModel?: string;
  shutterType: string;
  rtk: {
    flag: number;
    lon: number;
    lat: number;
    height: number;
  };
}

const EXIFR_OPTIONS = {
  tiff: false,
  ifd0: { pick: ['Make', 'Model'] },
  exif: { pick: ['CreateDate', 'DateTimeOriginal', 'ExifImageWidth', 'ExifImageHeight'] },
  gps: { pick: ['GPSAltitude', 'GPSLatitude', 'GPSLatitudeRef', 'GPSLongitude', 'GPSLongitudeRef'] }, // The ref fields are needed to parse coordinates properly
  xmp: { pick: ['RtkFlag', 'RtkStdLon', 'RtkStdLat', 'RtkStdHgt', 'GPSXYAccuracy', 'GPSZAccuracy', 'ShutterType'] }
};

const EXIFR_SIMPLE_OPTIONS = {
  tiff: false,
  ifd0: { pick: ['Make', 'Model'] },
  exif: { pick: ['CreateDate', 'DateTimeOriginal'] },
  gps: { pick: ['GPSAltitude', 'GPSLatitude', 'GPSLongitude'] }
};

@Injectable({ providedIn: 'root' })
export class ExifService {
  async parse(file: File, simple = false) {
    const parser = new Exifr(simple ? EXIFR_SIMPLE_OPTIONS : EXIFR_OPTIONS);
    try {
      await parser.read(file);
    } catch (e) {
      console.error('Parser file reading error.', e);
    }

    let tags;
    let thumbnailBuffer;
    try {
      [tags, thumbnailBuffer] = await Promise.all([parser.parse(), parser.extractThumbnail()]);
    } catch (e) {
      console.error('File tags parsing and / or thumbnail extracting error.', e);
    }
    if (!tags) {
      return null;
    }

    const date = tags.DateTimeOriginal || tags.CreateDate;
    const result: ImageExifData = {
      date: date && date instanceof Date ? date.toISOString() : date,
      location:
        tags.longitude && tags.latitude
          ? {
              longitude: tags.longitude,
              latitude: tags.latitude,
              height: tags.GPSAltitude
            }
          : null,
      thumbnail: thumbnailBuffer && new Blob([thumbnailBuffer], { type: 'image/jpeg' }),
      shutterType: tags.ShutterType,
      camModel: [tags.Make, tags.Model]
        .map(model => (model || '').replace(/\u0000/g, '').trim())
        .filter(model => !!model)
        .join(' '),
      resolution: { width: tags.ExifImageWidth, height: tags.ExifImageHeight },
      rtk: {
        flag: tags.RtkFlag,
        lon: tags.RtkStdLon ?? tags.GPSXYAccuracy,
        lat: tags.RtkStdLat ?? tags.GPSXYAccuracy,
        height: tags.RtkStdHgt ?? tags.GPSZAccuracy
      }
    };

    return result;
  }
}
