// index.ts
import { coordEach } from "@turf/meta";
import { isNumber } from "@turf/helpers";
import { clone } from "@turf/clone";
function toMercator(geojson, options = {}) {
  return convert(geojson, "mercator", options);
}
function toWgs84(geojson, options = {}) {
  return convert(geojson, "wgs84", options);
}
function convert(geojson, projection, options = {}) {
  options = options || {};
  var mutate = options.mutate;
  if (!geojson) throw new Error("geojson is required");
  if (Array.isArray(geojson) && isNumber(geojson[0])) geojson = projection === "mercator" ? convertToMercator(geojson) : convertToWgs84(geojson);else {
    if (mutate !== true) geojson = clone(geojson);
    coordEach(geojson, function (coord) {
      var newCoord = projection === "mercator" ? convertToMercator(coord) : convertToWgs84(coord);
      coord[0] = newCoord[0];
      coord[1] = newCoord[1];
    });
  }
  return geojson;
}
function convertToMercator(lonLat) {
  var D2R = Math.PI / 180,
    A = 6378137,
    MAXEXTENT = 20037508342789244e-9;
  var adjusted = Math.abs(lonLat[0]) <= 180 ? lonLat[0] : lonLat[0] - sign(lonLat[0]) * 360;
  var xy = [A * adjusted * D2R, A * Math.log(Math.tan(Math.PI * 0.25 + 0.5 * lonLat[1] * D2R))];
  if (xy[0] > MAXEXTENT) xy[0] = MAXEXTENT;
  if (xy[0] < -MAXEXTENT) xy[0] = -MAXEXTENT;
  if (xy[1] > MAXEXTENT) xy[1] = MAXEXTENT;
  if (xy[1] < -MAXEXTENT) xy[1] = -MAXEXTENT;
  return xy;
}
function convertToWgs84(xy) {
  var R2D = 180 / Math.PI;
  var A = 6378137;
  return [xy[0] * R2D / A, (Math.PI * 0.5 - 2 * Math.atan(Math.exp(-xy[1] / A))) * R2D];
}
function sign(x) {
  return x < 0 ? -1 : x > 0 ? 1 : 0;
}
export { toMercator, toWgs84 };
