import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';

import { IssueBannerType } from '../issue-banner/issue-banner.component';
import { isDefined } from '../utils/general';

export type IssueType = IssueBannerType;

export interface Issue {
  type: IssueType;
  caption: string;
  count?: number;
}

const ANIMATION_TIME_MS = 150;

@Component({
  selector: 'issues-summary',
  templateUrl: './issues-summary.component.html',
  styleUrls: ['./issues-summary.component.scss'],
  animations: [
    trigger('translate', [
      transition(':enter', [
        style({ transform: 'translateY(100%)', opacity: 0 }),
        animate(ANIMATION_TIME_MS, style({ transform: 'translateY(0)', opacity: 1 }))
      ]),
      transition(':leave', [
        style({ transform: 'translateY(0)', opacity: 1 }),
        animate(ANIMATION_TIME_MS, style({ transform: 'translateY(100%)', opacity: 0 }))
      ])
    ])
  ]
})
export class IssuesSummaryComponent implements OnInit {
  @Input() issues: Issue[];
  @Input() isOpen = false;

  constructor() {}

  ngOnInit() {}

  get summaryIssuesText() {
    const issuesCount = this.issues.length;
    if (issuesCount === 1) {
      return $localize`:@@shared.issuesSummary.summaryCaptionSingle:${issuesCount} type of issue has been detected`;
    } else {
      return $localize`:@@shared.issuesSummary.summaryCaptionPlural:${issuesCount} types of issues have been detected`;
    }
  }

  get hasIssues() {
    return isDefined(this.issues);
  }

  get hasWarnings() {
    return this.hasIssues && this.issues.some(issue => issue.type === 'warning');
  }

  get hasErrors() {
    return this.hasIssues && this.issues.some(issue => issue.type === 'error');
  }
}
