<dialog-layout [header]="announcement.title" (closeDialog)="dialogCloseClick()">
  <div class="announcement content">
    <div class="media-wrap">
      <img
        class="announcement-image"
        [src]="announcement.mediaLink"
        (click)="openMediaFullscreen()"
        *ngIf="announcement.mediaType === AnnouncementMediaTypes.IMAGE"
      />
      <ng-container *ngIf="announcement.mediaType === AnnouncementMediaTypes.VIDEO">
        <img
          class="announcement-image"
          [src]="announcement.videoPlaceholderLink"
          (click)="openMediaFullscreen()"
          *ngIf="announcement.videoPlaceholderLink | isDefined; else video"
        />
        <ng-template #video>
          <video class="announcement-video" controls muted autoplay loop>
            <source [src]="announcement.mediaLink" />
            <ng-container i18n="@@shared.notSupportedInBrowser">Not supported in browser</ng-container>
          </video>
        </ng-template>
      </ng-container>
    </div>
    <div class="header">{{ announcement.subtitle }}</div>
    <div class="description" [innerHTML]="announcement.description"></div>
    <div class="row actions">
      <button mat-button *ngIf="dialogData.showSnoozeBtn" class="light-btn" (click)="dialogSnoozeClick()">
        <span i18n="@@shared.announcementDialog.snooze">Snooze</span>
      </button>
      <button mat-flat-button type="submit" class="dark-btn action-btn" (click)="actionButtonClick()">{{ announcement.buttonText }}</button>
    </div>
  </div>
</dialog-layout>

<div class="fullscreen-media" (click)="closeFullscreen()" *ngIf="showMediaFullscreen">
  <button mat-icon-button class="close-fullscreen" title="Close" i18n-title="@@general.actionButtons.close">
    <mat-icon>close</mat-icon>
  </button>
  <img class="announcement-image" [src]="announcement.mediaLink" *ngIf="announcement.mediaType === AnnouncementMediaTypes.IMAGE" />
  <video class="announcement-video" controls muted autoplay loop *ngIf="announcement.mediaType === AnnouncementMediaTypes.VIDEO">
    <source [src]="announcement.mediaLink" />
    <ng-container i18n="@@shared.notSupportedInBrowser">Not supported in browser</ng-container>
  </video>
</div>
