<div class="resizeable-container" #resizeableContainerRef>
  <div class="image-wrapper">
    <div *ngIf="!scrolled && !hideHint && loaded" class="hint" i18n="@@shared.zoomImage.hint">Ctrl + scroll to zoom</div>
    <img
      #imageRef
      [src]="src"
      [alt]="alt"
      (mousedown)="onImageMouseDown($event)"
      (mouseup)="onImageMouseUp($event)"
      (wheel)="onImageWheel($event)"
      (mousemove)="onImageMouseMove($event)"
      (mouseleave)="onImageMouseLeave($event)"
    />
  </div>
</div>
