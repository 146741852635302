<ngx-file-drop
  (onFileDrop)="onDrop($event)"
  [multiple]="true"
  [accept]="accept"
  [showBrowseBtn]="false"
  class="row full-container full-height drag-and-drop-container-parent"
  [dropZoneClassName]="'custom-drop-zone row full-container full-height' + (disabled ? ' disabled-drop' : '')"
  contentClassName="custom-drop-content row full-container full-height justify-center"
  [disabled]="disabled"
>
  <ng-template ngx-file-drop-content-tmp>
    <div *ngIf="!isLoading && !showCustomContent" class="column full-height drop-container">
      <div *ngIf="isEmpty && !disabled" class="column full-container full-height justify-center align-center empty-drop-container">
        <label class="drop-text">Drag and Drop<br/> {{ contentType }}s here<br/> or</label>
        <button mat-flat-button class="dark-btn select-btn" type="button" (click)="openFileSelector()">
          Select {{ contentType }}s
        </button>
        <ng-content select=".additional-import-option"></ng-content>
      </div>

      <div *ngIf="!isEmpty" class="column full-container full-height space-between filled-drop-container">
        <div *ngIf="uploadedCount" class="column justify-center align-center uploaded-count">
          <span> {{disabled ? 'Processing' : 'Uploading' }} {{ filteredCount === uploadedCount ? filteredCount : filteredCount + ' / ' + uploadedCount }} {{ contentType }}{{filteredCount > 1 ? 's' : ''}} </span>
        </div>
        <ng-content></ng-content>
        <div class="row space-between bottom-buttons-line" [class.hidden]="hideAddDeleteButtons || disabled">
          <button mat-button class="light-btn" (click)="openFileSelector()" title="Add More {{ contentType }}s">Add More {{ contentType }}s</button>
          <ng-content select="[custom-action-buttons]"></ng-content>
          <button mat-button class="light-btn" (click)="onDeleteAll()" title="Delete All">Delete All</button>
        </div>
      </div>

      <div *ngIf="isEmpty && disabled" class="column full-container full-height space-between filled-drop-container">
        <div *ngIf="uploadedCount === 0" class="column justify-center align-center uploaded-count">
          <span>No {{ contentType }}s</span>
        </div>
      </div>
    </div>

    <ng-content select="[custom-content]" *ngIf="!isLoading && showCustomContent"></ng-content>

    <div *ngIf="isLoading" class="column full-container align-center loading">
      <label>Loading...</label>
      <datu-loader></datu-loader>
    </div>
  </ng-template>
</ngx-file-drop>
