import { Injectable } from '@angular/core';
import { QueryConfig, QueryEntity } from '@datorama/akita';
import moment from 'moment';

import { Announcement, AnnouncementsState, AnnouncementsStore } from './announcements.store';

const announcementSort = (n1: Announcement, n2: Announcement) => (moment(n1.creationTime).isAfter(n2.creationTime) ? -1 : 1);

@QueryConfig({ sortBy: announcementSort })
@Injectable({ providedIn: 'root' })
export class AnnouncementsQuery extends QueryEntity<AnnouncementsState> {
  announcements$ = this.selectAll();
  unseenCount$ = this.selectCount(entity => !entity['seen']);

  constructor(protected store: AnnouncementsStore) {
    super(store);
  }

  getUnseenCount() {
    return this.getCount(entity => !entity['seen']);
  }

  isFirstLaunch() {
    return this.getValue().firstLaunch;
  }

  getAllAnnouncements() {
    return this.getAll();
  }

  getLatestAnnouncement() {
    return this.getAllAnnouncements()?.[0];
  }
}
