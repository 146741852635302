<div class="container row">
    <map-tool-btn
        [icon]="icon"
        type="MENU"
        [tooltip]="label"
        tooltipPosition="above"
        [isMenuOpen]="isOverlayMenuOpen"
        [selected]="isOverlayMenuOpen"
        (buttonClick)="toggleMenu()"
        (clickOutside)="toggleMenu(true)"
        cdkOverlayOrigin
        #triggerStyleMenu="cdkOverlayOrigin"
    >
        <ng-template
            cdkConnectedOverlay
            [cdkConnectedOverlayOpen]="isOverlayMenuOpen"
            [cdkConnectedOverlayOrigin]="triggerStyleMenu"
        >
            <div class="column overlays menu">
                <div
                    *ngFor="let mapStyle of mapStyles"
                    class="row item"
                    [class.selected]="mapStyle.style === style"
                    (click)="changeStyle(mapStyle.style, $event)"
                >
                    <mat-icon [svgIcon]="mapStyle.icon"></mat-icon>
                    <div class="label">{{ mapStyle.label }}</div>
                </div>
            </div>
        </ng-template>
    </map-tool-btn>
</div>