import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import moment from 'moment';

import { CONFIG_UNLIMITED_PLACEHOLDER } from '../../../tenant/tenant.model';
import { TenantQuery } from '../../../tenant/tenant.query';
import { DeviceService } from '../../services/device.service';
import { LocaleService } from '../../services/locale.service';
import { formatFileSize } from '../../utils/formatting';

enum Status {
  VALID = 'Valid',
  WARNING = 'Warning'
}

interface Parameter {
  name: string;
  description: string;
  max?: number | Date;
  used?: number;
  status?: Status;
}

const PARAMS_BY_ORDER = [
  {
    name: 'images',
    description: 'Images'
  },
  {
    name: 'sites',
    description: 'Sites'
  },
  {
    name: 'analyticUsers',
    description: 'Analytics users'
  },
  {
    name: 'basicUsers',
    description: 'Basic users'
  },
  {
    name: 'viewOnlyUsers',
    description: 'View only users'
  },
  {
    name: 'operatorUsers',
    description: 'Operator users'
  },
  {
    name: 'totalFlights',
    description: 'Flights'
  },
  {
    name: 'storageCapacity',
    description: 'Imported models size'
  }
] as Parameter[];

@UntilDestroy()
@Component({
  templateUrl: './account-dialog.component.html',
  styleUrls: ['./account-dialog.component.scss']
})
export class AccountDialogComponent implements OnInit {
  Status = Status;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  tableCols = ['description', 'max', 'used', 'status'];

  dataSource = new MatTableDataSource<Parameter>();
  statusList: { label: string; value: Status }[];

  constructor(
    private dialogRef: MatDialogRef<AccountDialogComponent>,
    private tenantQuery: TenantQuery,
    private localeService: LocaleService,
    public device: DeviceService
  ) {
    this.statusList = Object.values(Status).map(value => ({ label: value, value }));
  }

  ngOnInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.filterPredicate = this.tableFilter;

    this.tenantQuery.config$.pipe(untilDestroyed(this)).subscribe(config => {
      const tableData: Parameter[] = PARAMS_BY_ORDER.map(param => {
        const max = config.max[param.name];
        const used = config.counter[param.name];

        const status = max === CONFIG_UNLIMITED_PLACEHOLDER || used === 0 || max > used ? Status.VALID : Status.WARNING;
        return { ...param, max, used, status };
      });

      // Add expiry date
      if (config.endPackage) {
        const closeToExpiryDate = moment().isAfter(moment(config.endPackage).subtract(30, 'days'));
        tableData.push({
          name: 'endPackage',
          description: 'Subscription expiry',
          max: config.endPackage,
          status: closeToExpiryDate ? Status.WARNING : Status.VALID
        });
      }

      this.dataSource.data = tableData;
    });
  }

  private tableFilter = (param: Parameter, filter: string) => {
    return param.status === filter;
  };

  onChangeStatus(selected: { label: string; value: Status }) {
    this.dataSource.filter = selected?.value;
  }

  getStatusIcon(status: Status) {
    switch (status) {
      case Status.VALID:
        return 'do';

      case Status.WARNING:
      default:
        return 'task-failure';
    }
  }

  getStatusIconColor(status: Status) {
    switch (status) {
      case Status.WARNING:
        return 'var(--warning)';

      case Status.VALID:
      default:
        return 'var(--icon-gray)';
    }
  }

  trackByFunc(index: number, entity: Parameter) {
    return entity ? entity.name : null;
  }

  formatValue(param: Parameter, field: 'max' | 'used') {
    if (param[field] === undefined || param.max === null) {
      return '';
    }

    if (param[field] === CONFIG_UNLIMITED_PLACEHOLDER) {
      return 'Unlimited';
    } else {
      switch (param.name) {
        case 'endPackage':
          return this.localeService.formatDateName({ date: '' + param[field] });
        case 'storageCapacity':
          return formatFileSize(+param[field]);
        default:
          return this.localeService.formatNumber(param[field] as number);
      }
    }
  }

  close() {
    this.dialogRef.close();
  }
}
