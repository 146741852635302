import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { GridColorsModel } from '../../../generated/file/model/gridColorsModel';

const DEFAULT_LABEL_COLOR = '#1E67BB';
const DEFAULT_TEXT_COLOR = '#000000';

export type GridValueTextColors = GridColorsModel;
export enum GridValueType {
  CELL_NAME = 'cellName',
  DIFF = 'surfaceDiff',
  COMP_SURFACE = 'comparisonSurfaceElevation',
  BASE_SURFACE = 'baseSurfaceElevation',
  CUT = 'cut',
  FILL = 'fill'
}

@Component({
  selector: 'grid-report-cell-legend',
  templateUrl: './grid-report-cell-legend.component.html',
  styleUrls: ['./grid-report-cell-legend.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridReportCellLegendComponent {
  ValueType = GridValueType;

  @Input() textColors?: GridValueTextColors;
  @Input() labelColor = DEFAULT_LABEL_COLOR;
  @Input() hideVolume?: boolean;

  constructor() {}

  getTextColor(type: GridValueType) {
    return this.textColors?.[type] || DEFAULT_TEXT_COLOR;
  }

  getLabelColor() {
    return this.labelColor || DEFAULT_LABEL_COLOR;
  }
}
