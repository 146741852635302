import { Directive, ElementRef, HostListener, Input, OnInit, Renderer2 } from '@angular/core';
import { NgControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { isDefined } from '../utils/general';

@UntilDestroy()
@Directive({
  selector: '[maxLengthCounter]'
})
export class MaxLengthCounterDirective implements OnInit {
  @Input() maxLengthCounter: number;
  counterElement: HTMLDivElement;

  constructor(private el: ElementRef, private renderer: Renderer2, private control: NgControl) {}

  ngOnInit() {
    this.counterElement = this.renderer.createElement('div');
    this.counterElement.classList.add('length-counter');
    this.setCounterText();
    this.setDefaultStyle();

    const element: HTMLInputElement = this.el.nativeElement;
    const value = element.value || this.control?.value;
    if (isDefined(value)) {
      this.onValueChange(value);
    }

    const parentElement = element.parentElement;
    parentElement.style.position = parentElement.style.position === 'absolute' ? 'absolute' : 'relative';
    this.renderer.insertBefore(parentElement, this.counterElement, element.nextSibling);

    this.control?.valueChanges.pipe(untilDestroyed(this)).subscribe(value => this.onValueChange(value));
  }

  @HostListener('input', ['$event']) onInput($event: any) {
    // For regular user input changes
    const text: string = $event.target.value || '';
    this.onValueChange(text);
  }

  private onValueChange(text: string) {
    this.setCounterText(text.length);

    if (text.length > this.maxLengthCounter) {
      this.setErrorStyle();
    } else {
      this.setValidStyle();
    }
  }

  private setCounterText(count: number = 0) {
    this.counterElement.innerHTML = `${count}/${this.maxLengthCounter}`;
  }

  private setDefaultStyle() {
    this.counterElement.style.fontSize = '12px';
    this.counterElement.style.marginTop = '3px';
    this.counterElement.style.position = 'absolute';
    this.counterElement.style.top = '100%';
    this.counterElement.style.right = '0px';

    this.setValidStyle();
  }

  private setValidStyle() {
    this.counterElement.style.color = 'var(--text-dark)';
    this.counterElement.style.fontWeight = 'normal';
  }

  private setErrorStyle() {
    this.counterElement.style.color = 'var(--error)';
    this.counterElement.style.fontWeight = 'bold';
  }
}
