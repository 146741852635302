import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { environment } from '../../../environments/environment';
import { LoginResponse } from '../../../generated/ums/model/loginResponse';
import { AuthQuery } from '../../auth/state/auth.query';
import { AuthService } from '../../auth/state/auth.service';
import { AccessLevelEnum, getTenantStateErrorMessage, LogInStateEnum, UserRole } from '../../auth/state/auth.utils';
import PERMISSIONS from '../../auth/state/permissions';
import { FeatureHintPlace } from '../feature-hint/state/feature-hints.store';
import { IframeDialogService } from '../iframe-dialog/iframe-dialog.service';
import { AnalyticsService } from '../services/analytics.service';
import { SnackBarService } from '../services/snackbar.service';
import { UsersAndTeamsDialogComponent } from '../users-and-teams-dialog/users-and-teams-dialog.component';
import { AccountDialogComponent } from './account-dialog/account-dialog.component';
import { AnnouncementsQuery } from './announcement-menu/state/announcements.query';
import { AnnouncementsService } from './announcement-menu/state/announcements.service';
import { NotificationsQuery } from './notifications-menu/state/notifications.query';
import { NotificationsService } from './notifications-menu/state/notifications.service';
import { ProcessingHistoryComponent } from './processing-history/processing-history.component';

enum MenuItemType {
  BUTTON = 'BUTTON',
  SUBMENU = 'SUBMENU',
  LINK = 'LINK',
  EXTERNAL_LINK = 'EXTERNAL_LINK',
  DIVIDER = 'DIVIDER'
}

interface MenuItem {
  key: string;
  caption: string;
  icon: string;
  type: MenuItemType;
  url?: string;
  subitems?: SubmenuItem[];
  disabled?: boolean;
  hidden?: boolean;
  accessLevel?: AccessLevelEnum;
}

interface SubmenuItem {
  caption: string;
  title?: string;
  disabled?: boolean;
  selected?: boolean;
  data?: { [key: string]: any };
}

const MENU_ITEMS: { [key: string]: MenuItem } = {
  DIVIDER: {
    key: 'DIVIDER',
    type: MenuItemType.DIVIDER,
    icon: null,
    caption: null
  },
  HOME: {
    key: 'HOME',
    caption: $localize`:@@shared.navbar.menuItemCaptionAllSites:All sites`,
    icon: 'home',
    type: MenuItemType.LINK,
    url: '/'
  },
  PROCESSING_HISTORY: {
    key: 'PROCESSING_HISTORY',
    caption: $localize`:@@shared.navbar.menuItemCaptionProcessingHistory:Processing history`,
    icon: 'processing-task',
    type: MenuItemType.BUTTON
  },
  USERS_AND_TEAMS: {
    key: 'USERS_AND_TEAMS',
    // TODO replace
    // caption: $localize`:@@shared.navbar.menuItemCaptionUsers:Users & teams`,
    caption: $localize`:@@shared.navbar.menuItemCaptionUsers:Users & permissions`,
    icon: 'users',
    accessLevel: PERMISSIONS.users.read,
    type: MenuItemType.BUTTON
  },
  SUPPORT: {
    key: 'SUPPORT',
    caption: $localize`:@@shared.navbar.menuItemCaptionSupport:Support`,
    icon: 'support',
    type: MenuItemType.EXTERNAL_LINK,
    url: environment.whitelabel.supportUrl
  },
  VIDEO_TUTORIALS: {
    key: 'VIDEO_TUTORIALS',
    caption: $localize`:@@shared.navbar.menuItemCaptionVideos:Quick video help`,
    icon: 'tutorial-videos',
    type: MenuItemType.EXTERNAL_LINK,
    url: environment.whitelabel.tutorialVideosUrl
  },
  TERMS_AND_CONDITIONS: {
    key: 'TERMS_AND_CONDITIONS',
    caption: $localize`:@@shared.navbar.menuItemCaptionTerms:Terms & conditions`,
    icon: 'terms-and-conditions',
    hidden: !environment.whitelabel.termsOfServiceUrl,
    type: MenuItemType.BUTTON
  },
  ABOUT: {
    key: 'ABOUT',
    caption: $localize`:@@shared.navbar.menuItemCaptionAbout:About`,
    icon: 'about',
    type: MenuItemType.EXTERNAL_LINK,
    url: environment.whitelabel.aboutUrl
  },
  PLAN: {
    key: 'PLAN',
    caption: $localize`:@@shared.navbar.menuItemCaptionSubscriptionPlan:Subscription plan`,
    icon: 'account',
    accessLevel: AccessLevelEnum.TENANTADMIN,
    type: MenuItemType.BUTTON
  },
  SWITCH_ACCOUNT: {
    key: 'SWITCH_ACCOUNT',
    caption: $localize`:@@shared.navbar.menuItemCaptionSwitchAccount:Switch account`,
    icon: 'signpost',
    type: MenuItemType.SUBMENU,
    subitems: []
  },
  LOGOFF: {
    key: 'LOGOFF',
    caption: $localize`:@@shared.navbar.signOutLabel:Sign out`,
    icon: 'logoff',
    type: MenuItemType.BUTTON
  }
};

const MENU_ITEMS_FEATURE_HINT_PLACES = {
  [MENU_ITEMS.PROCESSING_HISTORY.key]: FeatureHintPlace.ProcessingHistoryBtn,
  [MENU_ITEMS.USERS_AND_TEAMS.key]: FeatureHintPlace.UsersAndPermissionsBtn
};

const MENU_ITEMS_LIST = {
  [UserRole.OPERATOR]: [MENU_ITEMS.TERMS_AND_CONDITIONS, MENU_ITEMS.ABOUT, MENU_ITEMS.LOGOFF],
  [UserRole.ACCOUNT_USER]: [
    MENU_ITEMS.SWITCH_ACCOUNT,
    MENU_ITEMS.PLAN,
    MENU_ITEMS.DIVIDER,
    MENU_ITEMS.HOME,
    MENU_ITEMS.PROCESSING_HISTORY,
    MENU_ITEMS.USERS_AND_TEAMS,
    MENU_ITEMS.DIVIDER,
    MENU_ITEMS.SUPPORT,
    MENU_ITEMS.VIDEO_TUTORIALS,
    MENU_ITEMS.TERMS_AND_CONDITIONS,
    MENU_ITEMS.ABOUT,
    MENU_ITEMS.DIVIDER,
    MENU_ITEMS.LOGOFF
  ]
};

@Component({
  selector: 'navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  MenuItemType = MenuItemType;
  FeatureHintPlace = FeatureHintPlace;

  MENU_ITEMS_FEATURE_HINT_PLACES = MENU_ITEMS_FEATURE_HINT_PLACES;

  navbarLogo = environment.whitelabel.navbarLogo;
  menuItems: MenuItem[];
  UserRole = UserRole;
  userRole: UserRole;

  @Input() showNotificationDivider = false;
  @Input() isSimpleMode = false;
  @Input() title: string;
  @Input() showBackButton: boolean;

  @Output() backButtonClicked = new EventEmitter();

  menuOpen = false;
  notificationsOpen = false;
  announcementsOpen = false;

  constructor(
    private authService: AuthService,
    private authQuery: AuthQuery,
    private dialog: MatDialog,
    private snackbar: SnackBarService,
    private analyticsService: AnalyticsService,
    private iframeDialog: IframeDialogService,
    private notificationsService: NotificationsService,
    public notificationsQuery: NotificationsQuery,
    public announcementsService: AnnouncementsService,
    public announcementsQuery: AnnouncementsQuery
  ) {}

  ngOnInit() {
    this.userRole = this.authQuery.getUserRole();

    this.generateMenuItems();
  }

  private generateMenuItems() {
    const menuItems = MENU_ITEMS_LIST[this.userRole].map(item => {
      // Add items to switch account submenu, if exists
      if (item === MENU_ITEMS.SWITCH_ACCOUNT) {
        const tenants = this.authQuery.getLoginResponses();
        if (tenants.length > 1) {
          item.subitems = this.generateSwitchAccountSubmenuItems(tenants);
        } else {
          item.hidden = true;
        }
      }

      // Hide items that user doesn't have access to
      if (item.accessLevel && !this.authQuery.hasAccessLevel(item.accessLevel)) {
        item.hidden = true;
      }

      return item;
    });

    const visibleMenuItems = menuItems.filter(item => !item.hidden);

    // Remove adjacent dividers and dividers at edges
    this.menuItems = visibleMenuItems.filter((item, i) => {
      if (item === MENU_ITEMS.DIVIDER) {
        return (
          visibleMenuItems[i - 1] &&
          visibleMenuItems[i - 1] !== MENU_ITEMS.DIVIDER &&
          visibleMenuItems[i + 1] &&
          visibleMenuItems[i + 1] !== MENU_ITEMS.DIVIDER
        );
      }

      return true;
    });
  }

  private generateSwitchAccountSubmenuItems(tenants: LoginResponse[]) {
    const activeTenantId = this.authQuery.getActiveTenantId();
    return tenants
      .sort((tenant1, tenant2) => tenant1.tenantName.localeCompare(tenant2.tenantName))
      .map(
        tenant =>
          ({
            caption: tenant.tenantName,
            disabled: tenant.logInState !== LogInStateEnum.SUCCESS,
            selected: tenant.tenantId === activeTenantId,
            title: this.getTenantSubmenuTitle(tenant, activeTenantId),
            data: { tenantId: tenant.tenantId }
          } as SubmenuItem)
      );
  }

  private getTenantSubmenuTitle(tenant: LoginResponse, activeTenantId: string) {
    if (tenant.logInState !== LogInStateEnum.SUCCESS) {
      return getTenantStateErrorMessage(tenant.logInState, tenant.tenantName);
    }

    const tenantName = tenant.tenantName;
    return tenant.tenantId === activeTenantId
      ? $localize`:@@shared.navbar.switchAccountTitleCurrentAccount:Current account`
      : $localize`:@@shared.navbar.switchAccountTitleOtherAccount:Switch to ${tenantName}`;
  }

  menuItemClick(item: MenuItem) {
    switch (item.key) {
      case MENU_ITEMS.PROCESSING_HISTORY.key:
        this.openProcessingHistory();
        break;

      case MENU_ITEMS.TERMS_AND_CONDITIONS.key:
        this.openIframeDialog(environment.whitelabel.termsOfServiceUrl);
        break;

      case MENU_ITEMS.USERS_AND_TEAMS.key:
        this.openUsersAndTeamsDialog();
        break;

      case MENU_ITEMS.PLAN.key:
        this.openAccountPlanDialog();
        break;

      case MENU_ITEMS.LOGOFF.key: {
        this.onLogoff();
        break;
      }

      default:
        break;
    }
  }

  onLogoff() {
    this.authService.logout();
  }

  submenuItemClick(item: MenuItem, subitem: SubmenuItem) {
    if (!subitem || subitem.disabled) {
      return;
    }

    if (item.key === 'SWITCH_ACCOUNT') {
      const activeTenantId = this.authQuery.getActiveTenantId();
      if (!subitem.data?.tenantId || subitem.data.tenantId === activeTenantId) {
        return;
      }

      const tenant = this.authQuery.getTenantById(subitem.data.tenantId);
      this.analyticsService.switchAccount(tenant);
      this.authService.setActiveTenant(subitem.data.tenantId);
      window.open('/', '_self');
    }
  }

  private openUsersAndTeamsDialog() {
    this.dialog.open(UsersAndTeamsDialogComponent, {
      autoFocus: false,
      restoreFocus: false,
      width: '80vw',
      height: '70%',
      minWidth: '800px',
      minHeight: '500px',
      maxWidth: '1200px'
    });
  }

  private openProcessingHistory() {
    this.dialog.open(ProcessingHistoryComponent, {
      autoFocus: false,
      restoreFocus: false,
      width: '70vw',
      minWidth: '600px',
      minHeight: '500px',
      maxWidth: '1200px'
    });
  }

  private openAccountPlanDialog() {
    this.dialog.open(AccountDialogComponent, {
      autoFocus: false,
      restoreFocus: false,
      width: '50%',
      height: '60%',
      minWidth: '850px',
      minHeight: '500px'
    });
  }

  private openIframeDialog(url: string) {
    this.iframeDialog.openDialog(url);
  }

  notificationsMenuOpened() {
    this.notificationsOpen = true;

    this.notificationsService.markSeen().subscribe({
      error: error => {
        this.snackbar.openError(
          $localize`:@@shared.navbar.errorMarkNotificationSeen:Error marking notifications as seen`,
          'Error marking notifications as seen',
          error
        );
      }
    });
  }

  menuButtonCountTitle(notificationCount: number, menuName: 'announcements' | 'notifications') {
    const entityTypes =
      menuName === 'announcements'
        ? notificationCount === 1
          ? $localize`:@@shared.navbar.menuCountTitleAnnouncementsSingle:announcement`
          : $localize`:@@shared.navbar.menuCountTitleAnnouncementsPlural:announcements`
        : notificationCount === 1
        ? $localize`:@@shared.navbar.menuCountTitleNotificationsSingle:notification`
        : $localize`:@@shared.navbar.menuCountTitleNotificationsPlural:notifications`;
    return notificationCount > 0
      ? $localize`:@@shared.navbar.menuCountTitleNotEmpty:${notificationCount} new ${entityTypes}`
      : $localize`:@@shared.navbar.menuCountTitleEmpty:No new ${entityTypes}`;
  }

  announcementsMenuOpened() {
    this.announcementsOpen = true;

    this.analyticsService.announcementMenuOpened();

    this.announcementsService.markSeen().subscribe({
      error: error => {
        this.snackbar.openError(
          $localize`:@@shared.navbar.errorMarkAnnouncementSeen:Error marking announcement as seen`,
          'Error marking announcement as seen',
          error
        );
      }
    });
  }
}
