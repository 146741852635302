import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { SnackBarService } from '../../services/snackbar.service';
import { Team, User } from '../state/users-and-teams.model';
import { UsersAndTeamsQuery } from '../state/users-and-teams.query';
import { UsersAndTeamsService } from '../state/users-and-teams.service';
import { UserDialogComponent } from '../user-dialog/user-dialog.component';

export interface TeamDialogData {
  team?: Team;
  openAddUserDialog?: () => MatDialogRef<UserDialogComponent, User>;
}

@Component({
  templateUrl: './team-dialog.component.html',
  styleUrls: ['./team-dialog.component.scss']
})
export class TeamDialogComponent implements OnInit {
  teamForm: UntypedFormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: TeamDialogData,
    private dialogRef: MatDialogRef<TeamDialogComponent, Team>,
    private teamsService: UsersAndTeamsService,
    public teamsQuery: UsersAndTeamsQuery,
    private snackbar: SnackBarService
  ) {}

  ngOnInit() {
    this.teamForm = new UntypedFormGroup({
      name: new UntypedFormControl(this.team?.name ?? '', Validators.required),
      description: new UntypedFormControl(this.team?.description ?? ''),
      users: new UntypedFormControl(this.team?.users ?? [])
    });
  }

  get team() {
    return this.data?.team;
  }

  submit() {
    if (this.teamForm.valid) {
      const formValue = this.teamForm.value;
      const currentTeam = this.team ?? {};
      const newTeam = {
        ...currentTeam,
        name: formValue.name?.trim(),
        description: formValue.description,
        users: formValue.users
      };
      const method = this.team ? this.teamsService.updateTeam(newTeam) : this.teamsService.createTeam(newTeam);
      method.subscribe({
        next: updatedTeam => this.close(updatedTeam),
        error: error => {
          const message = this.team ? 'Error updating team' : 'Error creating team';
          this.snackbar.openError(message, error);
          this.close();
        }
      });
    }
  }

  close(team?: Team) {
    this.dialogRef.close(team);
  }

  addNewUser() {
    const dialogRef = this.data.openAddUserDialog?.();
    if (!dialogRef) {
      return;
    }

    dialogRef
      .afterClosed()
      .pipe(switchMap(newUser => (newUser ? this.teamsService.createUser(newUser) : of(null))))
      .subscribe({
        next: updatedUser => {
          const usersField = this.teamForm.get('users');
          usersField.patchValue([...usersField.value, updatedUser]);
        },
        error: error => {
          let message = 'Error creating user';
          if (error instanceof HttpErrorResponse && error.error.code === 'USER_ALREADY_EXIST') {
            message = error.error.message;
          }
          this.snackbar.openError(message, error);
        }
      });
  }

  get allowAddUser() {
    return !!this.data.openAddUserDialog;
  }
}
