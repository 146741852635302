import { Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { Order, Query, QueryConfig, QueryEntity } from '@datorama/akita';

import { isDefined } from '../../../shared/utils/general';
import { UploadWizardQuery } from '../../state/upload-wizard.query';
import { GCPItem, GCPsOffset, GCPType, Image, MIN_IMAGE_MARKS, MIN_IMAGE_MARKS_LOCAL_CS } from './gcp.model';
import { GcpState, GcpStore } from './gcp.store';

@QueryConfig({
  sortBy: 'name',
  sortByOrder: Order.ASC
})
@Injectable({ providedIn: 'root' })
export class GcpQuery extends Query<GcpState> {
  gcpItemsQuery = new QueryEntity(this.store.gcps);
  gcp$ = this.gcpItemsQuery.selectAll();

  imagesQuery = new QueryEntity(this.store.images);
  images$ = this.imagesQuery.selectAll();
  imagesMapping$ = this.imagesQuery.select(state => state.entities);

  processState$ = this.select(state => state.ui.processState);
  loadingModalType$ = this.select(state => state.ui.loadingModalType);

  mapActiveGcpId$ = this.select(state => state.ui.gcpMap.activeGcpId);
  gcpTableSorting$ = this.select(state => state.ui.gcpTableSorting);
  showImages$ = this.select(state => state.ui.gcpMap.showImages);
  showGcpName$ = this.select(state => state.ui.gcpMap.showGcpName);
  showImageName$ = this.select(state => state.ui.gcpMap.showImageName);
  showGcpWithMarking$ = this.select(state => state.ui.gcpMap.showGcpWithMarking);
  showGcpWithoutMarking$ = this.select(state => state.ui.gcpMap.showGcpWithoutMarking);
  showCheckpointsWithMarking$ = this.select(state => state.ui.gcpMap.showCheckpointsWithMarking);
  showCheckpointsWithoutMarking$ = this.select(state => state.ui.gcpMap.showCheckpointsWithoutMarking);
  showRedFlag$ = this.select(state => state.ui.gcpMap.showRedFlag);
  showHintCount$ = this.select(state => state.ui.gcpMap.showHintCount);
  mapOrthoTaskId$ = this.select(state => state.ui.mapOrthoTaskId);
  mapStyle$ = this.select(state => state.ui.gcpMap.mapStyle);
  isGCPsDirty$ = this.select(state => state.isGCPsDirty);
  processCounters$ = this.select(['checkAccuracyCounter', 'autoImproveCounter']);
  isGCPsImagesOverlapping$ = this.select(state => state.isGCPsImagesOverlapping);
  isMarkModalOpen$ = this.select(state => state.ui.isMarkModalOpen);
  isGCPSaving$ = this.select(state => state.ui.isGCPSaving);

  gcpsMovingSettings$ = this.select(state => state.ui.gcpsMovingSettings);

  constructor(protected store: GcpStore, private wizardQuery: UploadWizardQuery) {
    super(store);
  }

  isEmpty() {
    return this.gcpItemsQuery.getCount() === 0;
  }

  isLoading() {
    return this.getValue().loading;
  }

  getIsLocalCS() {
    return this.wizardQuery.getIsLocalCS();
  }

  getMinImageMarks() {
    return this.getIsLocalCS() ? MIN_IMAGE_MARKS_LOCAL_CS : MIN_IMAGE_MARKS;
  }

  private isGCPMarked = (item: GCPItem) => {
    const minImageMarks = this.getMinImageMarks();
    return item?.marks && Object.keys(item.marks).length >= minImageMarks;
  };

  getIsGCPMarked(id: string) {
    const gcp = this.getGCP(id);
    return this.isGCPMarked(gcp);
  }

  getMapActiveGcpId() {
    return this.getValue().ui.gcpMap.activeGcpId;
  }

  private isNotCheckpointGCPMarked = (item: GCPItem) => {
    return item.type !== GCPType.CHECKPOINT && this.isGCPMarked(item);
  };

  get markedGCPs$() {
    return this.gcpItemsQuery.selectAll({ filterBy: this.isGCPMarked });
  }

  get unmarkedGCPs$() {
    return this.gcpItemsQuery.selectAll({ filterBy: item => !this.isGCPMarked(item) });
  }

  get currentMarkedGCPs$() {
    return this.getIsLocalCS() ? this.gcpItemsQuery.selectAll({ filterBy: this.isNotCheckpointGCPMarked }) : this.markedGCPs$;
  }

  getAllGCPByTableSorting() {
    const sorting = this.getValue().ui.gcpTableSorting;
    const gcps = this.gcpItemsQuery.getAll();
    return gcps.sort(this.gcpSorting(sorting));
  }

  private gcpSorting = (sorting: Sort) => (gcp1: GCPItem, gcp2: GCPItem) => {
    const isGCP1Marked = this.isGCPMarked(gcp1);
    const isGCP2Marked = this.isGCPMarked(gcp2);

    if (isGCP1Marked && !isGCP2Marked) {
      return -1;
    } else if (!isGCP1Marked && isGCP2Marked) {
      return 1;
    }

    if (!isDefined(sorting?.direction)) {
      return gcp1.name.localeCompare(gcp2.name);
    }

    let result = 0;
    switch (sorting.active) {
      case 'name': {
        result = gcp1.name.localeCompare(gcp2.name);
        break;
      }
      case 'imagesCount': {
        const imagesCount1 = isDefined(gcp1.hints) ? Object.keys(gcp1.hints).length : 0;
        const imagesCount2 = isDefined(gcp2.hints) ? Object.keys(gcp2.hints).length : 0;
        result = imagesCount1 - imagesCount2;
        break;
      }
      case 'marksCount': {
        const marksCount1 = isDefined(gcp1.marks) ? Object.keys(gcp1.marks).length : 0;
        const marksCount2 = isDefined(gcp2.marks) ? Object.keys(gcp2.marks).length : 0;
        result = marksCount1 - marksCount2;
        break;
      }
      case 'diff': {
        result = gcp1.diff - gcp2.diff;
        break;
      }
      case 'checkpoint': {
        const isCheckpoint1 = gcp1.type === GCPType.CHECKPOINT;
        const isCheckpoint2 = gcp2.type === GCPType.CHECKPOINT;
        result = isCheckpoint1 && !isCheckpoint2 ? -1 : !isCheckpoint1 && isCheckpoint2 ? 1 : 0;
        break;
      }
    }

    return sorting.direction === 'asc' ? result : -1 * result;
  };

  getNextGCPIdByTableSorting(gcpId: string) {
    const gcps = this.getAllGCPByTableSorting();
    const gcpIndex = gcps.findIndex(gcp => gcp.id === gcpId);
    if (gcpIndex === -1) {
      return null;
    }

    if (gcpIndex === gcps.length - 1) {
      return gcps[0].id;
    }

    return gcps[gcpIndex + 1].id;
  }

  getPrevGCPIdByTableSorting(gcpId: string) {
    const gcps = this.getAllGCPByTableSorting();
    const gcpIndex = gcps.findIndex(gcp => gcp.id === gcpId);
    if (gcpIndex === -1) {
      return null;
    }

    if (gcpIndex === 0) {
      return gcps.at(-1).id;
    }

    return gcps[gcpIndex - 1].id;
  }

  getIsMarkModalOpen() {
    return this.getValue().ui.isMarkModalOpen;
  }

  getIsGCPSaving() {
    return this.getValue().ui.isGCPSaving;
  }

  getGCP(id: string) {
    return this.gcpItemsQuery.getEntity(id);
  }

  getAllGCPs() {
    return this.gcpItemsQuery.getAll();
  }

  getGCPsLonLat() {
    return this.getAllGCPs().map(gcp => ({ longitude: gcp.longitude, latitude: gcp.latitude }));
  }
  getGCPsLonLatOri() {
    return this.getAllGCPs().map(gcp => ({ longitude: gcp.longitudeOri, latitude: gcp.latitudeOri }));
  }

  getGCPsOffset(): GCPsOffset {
    return this.getValue().gcpsOffset;
  }

  getProcessState() {
    return this.getValue().ui.processState;
  }

  getAllImages(filterBy?: (image: Image) => boolean) {
    return this.imagesQuery.getAll({ filterBy });
  }

  getImage(imageId: string) {
    return this.imagesQuery.getEntity(imageId);
  }

  getImagesLonLat() {
    return this.getAllImages().map(image => ({ longitude: image.longitude, latitude: image.latitude }));
  }

  getImageHint(gcpId: string, imageId: string) {
    const hints = this.getGCP(gcpId)?.hints;
    return imageId ? hints?.[imageId] : null;
  }

  getImageMark(gcpId: string, imageId: string) {
    const marks = this.getGCP(gcpId).marks;
    return imageId ? marks[imageId] : null;
  }

  getGcpMapOptions() {
    return this.getValue().ui.gcpMap;
  }

  getMapOrthoTaskId() {
    return this.getValue().ui.mapOrthoTaskId;
  }

  getMapStyle() {
    return this.getValue().ui.gcpMap.mapStyle;
  }

  getIsGCPsImagesOverlapping() {
    return this.getValue().isGCPsImagesOverlapping;
  }

  getLastUpdatedByUserId() {
    return this.getValue().lastUpdatedByUserId;
  }
}
