import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';

const ANIMATION_TIME_MS = 150;

@UntilDestroy()
@Component({
  selector: 'details-box',
  templateUrl: './details-box.component.html',
  styleUrls: ['./details-box.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        // :enter is alias to 'void => *'
        style({ transform: 'scale(0) translateY(100px)', opacity: 0 }),
        animate(ANIMATION_TIME_MS, style({ transform: 'scale(1)', opacity: 1 }))
      ]),
      transition(':leave', [
        style({ transform: 'scale(1)', opacity: 1 }),
        animate(ANIMATION_TIME_MS, style({ transform: 'scale(0) translateY(100px)', opacity: 0 }))
      ])
    ]),
    trigger('fadeExpandCollapse', [
      state('visible', style({ transform: 'scale(1)', opacity: 1 })),
      state('hidden', style({ transform: 'scale(0) translateY(100px)', opacity: 0 })),
      transition('visible <=> hidden', animate(ANIMATION_TIME_MS))
    ])
  ]
})
export class DetailsBoxComponent implements OnInit {
  @Input() isHidden: boolean;
  @Input() disableExpanding$: Observable<boolean>;
  @Output() expand = new EventEmitter<boolean>();

  @Input() isBoxExpanded = true;
  isPrevBoxExpanded = true;
  disableExpanding = false;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.disableExpanding$?.pipe(untilDestroyed(this)).subscribe(disableExpanding => {
      this.disableExpanding = disableExpanding;

      if (disableExpanding) {
        this.isBoxExpanded = false;
      } else {
        this.isBoxExpanded = this.isPrevBoxExpanded;
      }
      this.expand.emit(this.isBoxExpanded);

      this.cd.detectChanges();
    });
  }

  toggleExpanded() {
    if (!this.disableExpanding) {
      this.isBoxExpanded = !this.isBoxExpanded;
      this.isPrevBoxExpanded = this.isBoxExpanded;
      this.expand.emit(this.isBoxExpanded);
    }
  }
}
