/**
 * Flow Management Service
 * APIs document (version: 2.6.9)
 *
 * OpenAPI spec version: 2.6.9
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { PageTokenModel } from './pageTokenModel';

export interface CreateTaskLogPaginationRequest {
  eventType?: CreateTaskLogPaginationRequest.EventTypeEnum;
  pageToken?: PageTokenModel;
  siteId?: string;
  taskId?: string;
}
export namespace CreateTaskLogPaginationRequest {
  export type EventTypeEnum =
    | 'CREATED'
    | 'IMPORTED_LAS_AND_TIFF'
    | 'FINISHED_PRE_PROCESSING'
    | 'START_GENERATING_MODEL'
    | 'FINISHED_GENERATING_MODEL'
    | 'ERROR'
    | 'DELETED'
    | 'CREATED_BY_OPERATOR'
    | 'APPROVED_BY_USER'
    | 'IMPORTED_LAS'
    | 'NONE';
  export const EventTypeEnum = {
    CREATED: 'CREATED' as EventTypeEnum,
    IMPORTEDLASANDTIFF: 'IMPORTED_LAS_AND_TIFF' as EventTypeEnum,
    FINISHEDPREPROCESSING: 'FINISHED_PRE_PROCESSING' as EventTypeEnum,
    STARTGENERATINGMODEL: 'START_GENERATING_MODEL' as EventTypeEnum,
    FINISHEDGENERATINGMODEL: 'FINISHED_GENERATING_MODEL' as EventTypeEnum,
    ERROR: 'ERROR' as EventTypeEnum,
    DELETED: 'DELETED' as EventTypeEnum,
    CREATEDBYOPERATOR: 'CREATED_BY_OPERATOR' as EventTypeEnum,
    APPROVEDBYUSER: 'APPROVED_BY_USER' as EventTypeEnum,
    IMPORTEDLAS: 'IMPORTED_LAS' as EventTypeEnum,
    NONE: 'NONE' as EventTypeEnum
  };
}
