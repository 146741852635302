import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatMenu } from '@angular/material/menu';
import moment from 'moment';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthQuery } from '../../../auth/state/auth.query';
import { DeviceService } from '../../services/device.service';
import { LocaleService } from '../../services/locale.service';
import { SnackBarService } from '../../services/snackbar.service';
import { ToggleReadItem, ToggleReadItemsMenuComponent } from '../../toggle-read-items-menu/toggle-read-items-menu.component';
import { NotificationsQuery } from './state/notifications.query';
import { NotificationsService } from './state/notifications.service';
import { Notification, NotificationSeverity } from './state/notifications.store';

@Component({
  selector: 'notifications-menu',
  templateUrl: './notifications-menu.component.html',
  styleUrls: ['./notifications-menu.component.scss']
})
export class NotificationsMenuComponent implements OnInit {
  NotificationSeverity = NotificationSeverity;

  @ViewChild('itemsMenu', { static: true }) private itemsMenu: ToggleReadItemsMenuComponent;

  notificationItems: ToggleReadItem[];
  @Input() set notifications(notifications: Notification[]) {
    this.notificationItems = notifications.map(notification => ({
      date: moment.utc(notification.creationTime).toDate(),
      id: notification.id,
      read: notification.read,
      text: notification.message,
      disableClick: !notification.siteId,
      iconName: notification.severity === NotificationSeverity.INFO ? 'info' : 'task-failure',
      customClass: notification.severity?.toLowerCase()
    }));
  }

  constructor(
    private authQuery: AuthQuery,
    private localeService: LocaleService,
    private notificationsService: NotificationsService,
    private notificationsQuery: NotificationsQuery,
    private snackbar: SnackBarService,
    private device: DeviceService
  ) {}

  ngOnInit() {}

  get menu(): MatMenu {
    return this.itemsMenu?.menu;
  }

  onClick(notificationId: string) {
    const notification = this.notificationsQuery.getEntity(notificationId);
    this.updateNotificationRead(notification, true).subscribe(() => {
      if (notification.siteId) {
        const activeTenantId = this.authQuery.getActiveTenantId();
        let url = `${activeTenantId}/sites/${notification.siteId}`;
        if (notification.taskId) {
          url += `/tasks/${notification.taskId}`;
        }

        // Using `window.open` instead of `router.navigate` because window needs to refresh when changing site
        window.open(url, '_self');
      }
    });
  }

  toggleRead(notificationId: string) {
    const notification = this.notificationsQuery.getEntity(notificationId);
    this.updateNotificationRead(notification, !notification.read).subscribe();
  }

  private updateNotificationRead(notification: Notification, read: boolean) {
    return this.notificationsService.toggleRead(notification.id, read).pipe(
      catchError(error => {
        this.snackbar.openError('Error marking notification read', error);
        return throwError(() => error);
      })
    );
  }

  trackById(index: number, entity: Notification) {
    return entity ? entity.id : null;
  }

  formatShortDate(date: Date) {
    return this.localeService.formatShortDate({ date });
  }

  get isDesktop() {
    return this.device.isDesktop();
  }
}
